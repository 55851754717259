import axios from 'axios';
import { HH_MM, USER_TOKEN } from '@Utils'
export const BUILD_TYPE_LIVE = 1;
export const BUILD_TYPE_LIVE_DEMO = 2;
export const BUILD_TYPE_STAGING = 3;
export const BUILD_TYPE_LOCAL = 4;
export const BUILD_TYPE = BUILD_TYPE_LIVE;
export const SERVER =
  BUILD_TYPE === BUILD_TYPE_LIVE
    ? 'https://tmsprimary.quantaedat.com'
    : BUILD_TYPE === BUILD_TYPE_LIVE_DEMO
      ? 'live_local'
      : BUILD_TYPE === BUILD_TYPE_LOCAL
        ? 'https://23ed-2402-3a80-53d-cd23-c692-4177-b1ee-55b8.ngrok-free.app/'
        : BUILD_TYPE === BUILD_TYPE_STAGING
          ? 'http://103.118.188.135:3006'
          : 'http://localhost:8000'


export const axiosJangoApi = ' https://convolutionxprimary.leorainfotech.in'
export const axiosNodeUrl = 'https://convolutionxprimary.leorainfotech.in'
// export const axiosNodeUrl = 'https://convolutionxanalyticsprimary.leorainfotech.in'
// export const axiosJangoApi = 'http://192.168.1.31:8009'
// export const axiosNodeUrl = 'http://192.168.1.31:8009'
// export const axiosJangoApi = 'https://249d-2409-40f4-24-c2a0-4f2b-acd8-318e-ad85.ngrok-free.app'
// export const axiosNodeUrl = 'https://249d-2409-40f4-24-c2a0-4f2b-acd8-318e-ad85.ngrok-free.app'


const axiosApi = axios.create({
  baseURL: axiosJangoApi,
});

const axiosApiNode = axios.create({
  baseURL: axiosNodeUrl,
});


axios.interceptors.request.use(function (config) {
  return config;
});

const getHeaders = async () => {
  try {

    const value = await localStorage.getItem(USER_TOKEN);

    if (value) {
      return { Authorization: 'Token ' + value };
    } else {
      return {};
    }
  } catch {
    return {};
  }
};

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error),
);

export async function get(url, config) {
  return await axiosApi
    .get(url, {
      ...config,
      headers: await getHeaders(),
    })
    .then(response => response.data);
}

export async function post(url, data, config, api_type = 1) {
  let headers = { ...(await getHeaders()) };

  if (api_type === 1) {
    return await axiosApi
      .post(url, data, {
        ...config,
        headers: headers,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
      });
  } else {
    return await axiosApiNode
      .post(url, data, {
        ...config,
        headers: headers,
      })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        console.log(error);
      });
  }


}

export async function postHeader(url, data, config) {
  let headers = { ...(await getHeaders()) };
  return await axiosApi
    .post(url, data, {
      ...config,
      headers: headers,
    })
    .then(response => {
      return response;
    });
}