import { icons } from '@Assets'
import { AddCampaign, AddClient, AddUser, Call, CampaignsList, ClientsList, Dashboard, EmployeesList, Login, Otp, Setting, Splash, AddData, CallList,Landing, PrivacyPolicy, TermsAndConditions, ReturnAndRefund,ClientDetails } from '@Modules';
export const HOME_PATH = {

}

export const ROUTES = {
  'auth-module': {
    login: '/login',
    otp: '/otp',
    splash: '/splash',
    landing: '/',
    register: '/register',
    privacy: '/privacy-policy',
    TermsAndConditions: '/terms-and-condition',
    ReturnAndRefund: '/return-and-refund'
  },

  'Dashboard': {
    'dashboard': '/dashboard',
    'add-data': '/add-data',
    'settings': '/setting',
    'Call': '/call',
    'addEmployee': '/add-employee',
    'employeesList': '/employees-list',
    'addClient': '/add-client',
    'clientsList': '/clients-list',
    'addCampaign': '/add-campaign',
    'campaignsList': '/campaigns-list',
    'direct-call-modal': '/direct-call',
    'call-list': '/call-list',
    'clientDetails': '/client-details',

  }

}


export const AUTH_ROUTES = [
  

  {
    key: 1,
    path: ROUTES['auth-module'].landing,
    component: <Landing />
  },


  {
    key: 2,
    path: ROUTES['auth-module'].login,
    component: <Login />
  },
  {
    key: 2,
    path: ROUTES['auth-module'].otp,
    component: <Otp />
  },
  {
    key: 3,
    path: ROUTES['auth-module'].splash,
    component: <Splash />
  },
  {
    key: 5,
    path: ROUTES['auth-module'].privacy,
    component: <PrivacyPolicy />
  },
  {
    key: 6,
    path: ROUTES['auth-module'].TermsAndConditions,
    component: <TermsAndConditions />
  },
  {
    key: 7,
    path: ROUTES['auth-module'].ReturnAndRefund,
    component: <ReturnAndRefund />
  },


];

export const INTERNAL_ROUTES = [
  {
    path: ROUTES['Dashboard'].addEmployee,
    name: 'Add employee',
    icon: icons.task,
    layout: "",
    component: <AddUser />
  },
  {
    path: ROUTES['Dashboard'].addClient,
    name: 'Add Client',
    icon: icons.task,
    layout: "",
    component: <AddClient />
  },
  {
    path: ROUTES['Dashboard'].addCampaign,
    name: 'Add Campaign',
    icon: icons.task,
    layout: "",
    component: <AddCampaign />
  },
  {
    path: ROUTES['Dashboard'].clientsList,
    name: 'Clients portfolio',
    icon: icons.task,
    layout: "",
    component: <ClientsList />
  },
  {
    path: ROUTES['Dashboard'].campaignsList,
    name: 'Campaigns',
    icon: icons.task,
    layout: "",
    component: <CampaignsList />
  },
  {
    key: 1,
    path: ROUTES['Dashboard'].dashboard,
    component: <Dashboard />
  },
  {   
      key: 1,
      path: ROUTES['Dashboard'].clientDetails,
      component: <ClientDetails/>
    },
]


export const HOME_ROUTES = [

  // {
  //   path: ROUTES['Dashboard']["dashboard"],
  //   name: 'Dashboard',
  //   icon: icons.task,
  //   layout: "",
  //   component: <Dashboard />
  // },
  // {
  //   path: ROUTES['Dashboard']['add-data'],
  //   name: 'Instant Call',
  //   icon: icons.task,
  //   layout: "",
  //   component: <AddData />
  // },
  // {
  //   path: ROUTES['Dashboard']["Call"],
  //   name: 'Call',
  //   icon: icons.task,
  //   layout: "",
  //   component: <Call />
  // },


  {
    path: ROUTES['Dashboard']['call-list'],
    name: 'Direct Calls',
    icon: "bi bi-telephone",
    layout: "",
    component: <CallList />
  },
  // {
  //   collapse: true,
  //   state: "campaignCollapse",
  //   name: "Campaigns",
  //   icon: "bi bi-megaphone",
  //   parentNav: 'Campaigns',
  //   views: [

  //   ],
  // },
  {
    path: ROUTES['Dashboard']['clientsList'],
    name: 'Create Campaigns',
    icon: "bi bi-megaphone",
    layout: "",
    component: <ClientsList />
  },

  {
    path: ROUTES['Dashboard'].employeesList,
    name: 'Agents',
    icon: "bi bi-person",
    layout: "",
    component: <EmployeesList />
  },

  // {
  //   path: ROUTES['Dashboard']['ClientDetails'],
  //   name: 'Create Campaigns',
  //   icon: "bi bi-megaphone",
  //   layout: "",
  //   component: <ClientDetails/>
  // },
  // {
  //   path: ROUTES['Dashboard']["settings"],
  //   name: 'Setting',
  //   icon: icons.task,
  //   layout: "",
  //   component: <Setting />
  // },

  // [{
  //   collapse: true,
  //   state: "campaignCollapse",
  //   name: "Campaigns",
  //   icon: "bi bi-clipboard2-check",
  //   parentNav: 'QC',
  //   views: [

  //   ],
  // }]
]



export * from "./RequireAuth";
export * from "./RequireHome";
