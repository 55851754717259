import { convertToUpperCase, getImageUrl, getImageUrlJango } from '@Utils';
import React from 'react'
// import ReactAudioPlayer from 'react-h5-audio-player';
import ReactAudioPlayer from 'react-audio-player';
import { useSelector } from 'react-redux';
import './audio.css'
import { Card, NoDataFound } from '@Components';
import { Col } from 'reactstrap';
import { icons } from '@Assets';

function Transcription() {

    const { chatConversation } = useSelector((state: any) => state.AuthReducer);
    const conditions = [null, 'null', 'none', '', '-', 'n/a'];


    return (
        <>
            {chatConversation?.audioConversion && chatConversation?.audioConversion.length > 0 ? (
                <>
                    <div className='d-flex justify-content-between mt--5'>

                        {chatConversation?.audioConversion && chatConversation?.audioConversion.length > 0 && (
                            <>
                                <ReactAudioPlayer
                                    style={{zoom:'117%'}}
                                    src={getImageUrlJango(chatConversation?.recordingUrl)}
                                    controls
                                />
                                <div className='row overflow-auto overflow-hide'>

                                    <Card className={'col-auto mx-3'}>
                                        <div className="row align-items-center">
                                            <Col className="col-auto">

                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={icons.customerService}
                                                    height={45}
                                                    width={45}
                                                />
                                            </Col>
                                            <div className="col ml--2">
                                                <h4 className={`mb-0 `}>
                                                    {'Agent'}
                                                </h4>
                                                <p className="text-sm text-muted mb-0">{convertToUpperCase(chatConversation?.personDetails?.person1)}</p>
                                            </div>

                                        </div>
                                    </Card>


                                    <Card className={'col-auto'}>
                                        <div className="row align-items-center">
                                            <Col className="col-auto">

                                                <img
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={icons.userProfile}
                                                    height={50}
                                                    width={50}
                                                />
                                            </Col>
                                            <div className="col ml--2">
                                                <h4 className={`mb-0 `}>
                                                    {'Prospect'}
                                                </h4>
                                                <p className="text-sm text-muted mb-0">{convertToUpperCase(chatConversation?.personDetails?.person2)}</p>
                                            </div>

                                        </div>
                                    </Card>
                                </div>
                            </>
                        )}

                    </div>
                    <div className='mt-3'>
                        {chatConversation?.audioConversion && chatConversation?.audioConversion.length > 0 &&
                            chatConversation?.audioConversion?.map((item, index) => {
                                const convertedToString = item?.message + ''

                                return (
                                    <>

                                        <div className='py-2'>
                                            {!conditions.includes(convertedToString?.toLowerCase()) && (

                                                item?.user === chatConversation?.personDetails?.person1 ?
                                                    <div className='mr-5 py-4 bg-white font-weight-500 font-family-Arial text-darkGrey text-wrap' style={{ borderRadius: 8 }}>
                                                        <React.Fragment>
                                                            <div style={{ marginLeft: 30 }}>
                                                                {` ${item?.message}`}
                                                            </div>
                                                        </React.Fragment>
                                                    </div> : <div className='d-flex justify-content-end py-4 bg-white font-weight-500 text-black ml-5 text-wrap mt-3' style={{ borderRadius: 8, fontStyle: 'italic' }}>
                                                        <React.Fragment >
                                                            <div className='' style={{ marginLeft: 30, marginRight: 30 }}>
                                                                {`${item?.message}`}
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                            )}

                                        </div>

                                        {/* {(chatConversation?.audioConversion?.length - 1 > index) &&
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <i className="bi bi-three-dots-vertical"></i>
                                    </div>
                                } */}
                                    </>
                                )
                            })
                        }
                    </div>
                </>
            ) : <NoDataFound />}
        </>
    )
}

export { Transcription }