
export const image = {
    render: require('./brand/8x render.png'),
    cup: require('./brand/cupimage.png'),
    blue: require('./brand/image 1.jpg'),
    white: require('./brand/image 2.jpg'),
    parking: require('./brand/parking.png'),
    QuantaGataLogo: require('./brand/QuantaGateLogo.jpg'),
    QuantaLogo: require('./brand/QuantaLogo.jpg'),
    image1: require('./brand/main image.png'),
    QrcodeMachine: require('./brand/QrcodeMachine.png'),
    Feature: require('./brand/feature.png'),
    Security: require('./brand/security.png'),
    Benefits: require('./brand/benefits.png'),
    EaseOfUse: require('./brand/Ease of use.png'),
    Rev: require('./brand/revolution image.png'),

    TMS_Logo: require('./brand/TMS_Logo.png'),
    TMS_Img1: require('./brand/TMS_Img1.png'),
    TMS_Img2: require('./brand/TMS_Img2.png'),
    TMS_Img3: require('./brand/TMS_Img3.png'),
    TMS_Img4: require('./brand/TMS_Img4.png'),
    TMS_Img5: require('./brand/TMS_Img5.png'),
    TMS_Img6: require('./brand/TMS_Img6.png'),
    TMS_Img7: require('./brand/TMS_Img7.png'),
    TMS_Img8: require('./brand/TMS_Img8.png'),
    TMS_Img9: require('./brand/TMS_Img9.png'),


    RED_DOT: require('./theme/full-stop.png'),
    analytic:require('./theme/analytic.jpg')


}