import { Button, showToast } from '@Components';
import React, { useRef, useState } from 'react';
import { RsButtonProps, Color, ButtonVariants } from '@Components'

type fileUploadProps = {
    // onFileUpload: (file: Blob) => void,
    onFileUpload: any,
    variant?: ButtonVariants,
    className?: string
    icon?: string
    type?: 'whatsApp' | 'audio' | 'email'
}

const FileUpload = ({ variant, icon, className, onFileUpload, type }: fileUploadProps) => {
    // const fileInputRef = useRef<any>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        // const file = event.target.files[0];
        const reader = new FileReader();


        if (file && formatChecker(file, type)) {
            // const reader = new FileReader();
            reader.onload = function (e) {
                const fileContent = e?.target?.result;
                const base64Content = typeof fileContent === 'string' ? fileContent.split(',')[1] : '';
                onFileUpload(base64Content); 
                // const blob = new Blob([reader.result as ArrayBuffer], { type: file.type });
                // onFileUpload(blob);
            };
            reader.readAsDataURL(file);
            // reader.readAsArrayBuffer(file);
        }

    };

    const handleButtonClick = () => {
        fileInputRef?.current?.click();
    };

    const formatChecker = (file: any, type: any) => {
        // const formatChecker=(file: File | null, type: 'audio' | undefined):boolean =>{
        const fileFormat = getFileFormat(file?.name);

        if (type === 'audio') {

            if (fileFormat !== 'wav' && fileFormat !== 'm4a') {
                alert('Only wav/m4a file is allowed');
                return false;
            }
        }

        if (type === 'whatsApp') {
            if (fileFormat !== 'txt') {
                alert('Only txt file is allowed');
                return false;
            }
        }

        if (type === 'email') {
            if (fileFormat !== 'eml') {
                alert('Only eml file is allowed');
                return false;
            }
        }

        return true;
    };

    const getFileFormat = (fileName: any) => {
        const lastDotIndex = fileName?.lastIndexOf('.');
        if (lastDotIndex !== -1) {
            return fileName?.substring(lastDotIndex + 1);
        }
        return '';
    };



    return (
        <div>
            <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
            <Button color={'white'} variant={variant} icon={icon} className={className} onClick={handleButtonClick} />
        </div>
    );
};

export { FileUpload };
