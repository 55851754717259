/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react'
import { NextStepCardProps } from './interface'
import { Card, CommonTable, P, ProgressCircleChart } from '@Components'
import { Progress } from 'reactstrap'
import { removePercentege, toCheckTheFirstIndexIsNumber } from '@Utils'
import { useDynamicHeight } from '@Hooks'
import { icons } from '@Assets'

function PersonalizedCard({ title, reason, progress, progressColor = 'primary', data, progressBarData, overAllPercentage, isShowTable = false, perceptionTableData, personsData }: NextStepCardProps) {


    const [nextStepPersonalisedData, setNextStepPersonalisedData] = useState<any>(perceptionTableData)

    console.log("perceptionTableData", perceptionTableData);


    const getNameFormattedText = (text, personsData) => {

        const sentence = text
        if (text) {
            const updatedSentence = sentence?.replace(/prospect/g, personsData.person2).replace(/agent/g, personsData.person1).replace(/Prospect/g, personsData.person2).replace(/Agent/g, personsData.person1);
            return updatedSentence
        }
        else {
            return text
        }
    };

    return (
        <>
            <Card className={'mr--1 '}
                onClick={() => {
                    const updatedData = [...nextStepPersonalisedData]
                    updatedData[0].isShow = !updatedData[0].isShow
                    setNextStepPersonalisedData(updatedData)
                }}
            >

                {nextStepPersonalisedData && nextStepPersonalisedData.length > 0 && (
                    <>
                        <div className='mb--2'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <h2 className=' font-weight-500'>{nextStepPersonalisedData[0]?.areaOfInterest}</h2>
                                </div>
                                <div className='row mt-1' style={{ marginRight: '-6px' }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        const updatedData = [...nextStepPersonalisedData]
                                        updatedData[0].isShow = !updatedData[0].isShow
                                        setNextStepPersonalisedData(updatedData)
                                    }}>
                                    <h5 className='mr-3 text-primary font-weight-900'>{'Max : ' + nextStepPersonalisedData[0]?.maximum}</h5>
                                    {nextStepPersonalisedData[0]?.isShow ? <i className="bi bi-chevron-up" style={{ marginTop: '-2px' }}></i> : <i className="bi bi-chevron-down" style={{ marginTop: '-2px' }}></i>}
                                </div>
                            </div>
                            {nextStepPersonalisedData[0]?.isShow && (
                                <p className='font-weight-500'> {getNameFormattedText(nextStepPersonalisedData[0]?.reason, { person1: personsData?.agent, person2: personsData?.prospect })} </p>
                            )}

                        </div>
                        {nextStepPersonalisedData[0]?.isShow && (

                            <div>{nextStepPersonalisedData[0]?.reference.map((el, index) => {
                                return (
                                    <div>{toCheckTheFirstIndexIsNumber(el) ? el : `${index + 1}. ${el}`}</div>
                                )
                            })}</div>
                        )}

                    </>
                )}

                {nextStepPersonalisedData[0]?.isShow && (
                    <div className=''>
                        <div className='mt-3'>
                            <p className='text-black font-weight-500'>{'APPROACHES'}
                                <span className='ml-2'>
                                    <img

                                        src={icons.approaches}
                                        alt='...'
                                        height={20}
                                        width={20}
                                        style={{
                                            filter: 'brightness(0%)'
                                        }}
                                    />
                                </span>
                            </p>
                        </div>

                        {nextStepPersonalisedData && nextStepPersonalisedData?.length > 0 && nextStepPersonalisedData?.map((el, index) => {


                            return el?.approaches?.map((item, index2) => {

                                return (
                                    <>

                                        <div className='d-flex justify-content-between pointer'
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                const updatedData = [...nextStepPersonalisedData]
                                                const approaches = updatedData[index].approaches
                                                approaches[index2].isShow = !approaches[index2].isShow

                                                setNextStepPersonalisedData(updatedData)
                                            }}
                                        >
                                            <div>
                                                <p className='text-primary font-weight-500'>{item?.approach_overview}</p>
                                            </div>
                                            <div className='row mt-1' style={{ marginRight: '-6px' }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    const updatedData = [...nextStepPersonalisedData]
                                                    const approaches = updatedData[index].approaches
                                                    approaches[index2].isShow = !approaches[index2].isShow

                                                    setNextStepPersonalisedData(updatedData)
                                                }}>
                                                <h5 className='mr-3 text-primary font-weight-900'> {`+ ${removePercentege(item?.increasedPercent)} %`}</h5>
                                                {item.isShow ? <i className="bi bi-chevron-up"></i> : <i className="bi bi-chevron-down"></i>}
                                            </div>
                                        </div>

                                        {item?.isShow && (
                                            <div className='col-xl-12 col-md-12 text-black mb-3 mx--3'>

                                                <div className='mt-3'>
                                                    <h3 className=''>{'Steps'} <i className="bi bi-bar-chart-steps ml-2 text-black"></i></h3>
                                                    <div>{item?.steps?.length > 1 ? item?.steps.map((el, index) => {
                                                        return (
                                                            <div>{toCheckTheFirstIndexIsNumber(el) ? el : `${index + 1}. ${el}`}</div>
                                                        )
                                                    }) : toCheckTheFirstIndexIsNumber(item?.steps) ? item.steps : `${1}. ${el}`}</div>

                                                </div>

                                                <div className='mt-2'>
                                                    <h3 className=''>{`Reason`}
                                                        <span className='ml-2'>
                                                            <img

                                                                src={icons.agentTraitDefault}
                                                                alt='...'
                                                                height={20}
                                                                width={20}
                                                                style={{
                                                                    filter: 'brightness(0%)'
                                                                }}
                                                            />
                                                        </span>
                                                    </h3>
                                                    <p className='font-weight-500'> {getNameFormattedText(item.reason, { person1: personsData?.agent, person2: personsData?.prospect })} </p>
                                                </div>

                                            </div>
                                        )}

                                    </>
                                )
                            })
                        })}

                    </div>
                )}

            </Card>
        </>

    )
}
export { PersonalizedCard }