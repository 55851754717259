import React from 'react'
import { icons, image } from '@Assets';
import { Image } from '@Components';
import { Card, Container, Row, Col, CardBody, Button } from "reactstrap";
import { translate } from "@I18n";
import { Link } from 'react-router-dom';

function Home() {


  return (
    <>
      <div className="pt-6 pb-9 bg-customGradient-primary">
        <Container>
          <div className="header-body ">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="pr-5 scrolled slide-left">
                  <h1 className="display-2 text-white font-weight-bold mb-0 ">
                    Convolution<b className=''>X</b>
                  </h1>
                  <h2 className="display-4 text-white font-weight-light">
                    Streamline Your Convolution<b className=''>X</b>
                  </h2>
                  <p className="text-white mt-4 text-justify ">
                    Convolution X is a cutting-edge call tracking and analytics system designed to facilitate voice calls between prospects and agents. Our system allows for the recording and analysis of call conversations, providing valuable insights and analytics to improve agent performance and increase conversion rates. With advanced features like personalized approach recommendations, emotion analysis, and campaign management, Convolution X empowers businesses to achieve their sales goals efficiently.
                  </p>
                  
                </div>
              </Col>
              <Col lg="6" className='pt-4 '>
                <Image
                  src={icons.convolution1}
                  height={"100%"}
                  width={"100%"}
                  className='scrolled slide-right'

                />
              </Col>
            </Row>
          </div>
        </Container>
      </div>

    </>
  )
}

export { Home }