import { Back, Card } from '@Components'
import { translate } from '@I18n'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux';

function ClientDetails() {
    const { clientsDataList,clientDetails} = useSelector((state: any) => state.AuthReducer);
    const { first_name, last_name, email, mobile_number, age,about_personal ,about_professional,linkedIn_url} = clientsDataList;
    console.log('clientsDataList===========>',clientsDataList);


    
    return (
        <div className='container'>
            <div className='m-3'>
                <Back/>
                <Card
                title={translate("common.ClientDetails")}
                >
                    
                    <div className="row  mt-4">
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.firstName')}</h5>
                            <h4 className="ct-title text-dark">{clientsDataList?.client_info?.first_name}</h4>
                        </div>
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.lastName')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.last_name}</h4>
                        </div>
                    </div>

                    <div className="row  mt-4">
                    <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.email')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.email}</h4>
                        </div>
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.mobileNumber')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.mobile_number}</h4>
                        </div>
                       
                    </div>
                    <div className="row  mt-4">
                    <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.age')}</h5>
                            <h4 className="ct-title">{ clientsDataList?.client_info?.age}</h4>
                        </div>
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.Personal Info')}</h5>
                            <h4 className="ct-title">{clientsDataList?.about_personal}</h4>
                        </div>
                       
                    </div>
                    <div className="row  mt-4">
                    <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.Professional Info')}</h5>
                            <h4 className="ct-title">{clientsDataList?.about_professional}</h4>
                        </div>
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.LinkedInUrl')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.linkedIn_url}</h4>
                        </div>
                       
                    </div>
                    <div className="row  mt-4">
                    <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.Company')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.company_name}</h4>
                        </div>
                        <div className="col-xl-6">
                            <h5 className="ct-title text-muted mb-0">{translate('auth.designation')}</h5>
                            <h4 className="ct-title">{clientsDataList?.client_info?.desination}</h4>
                        </div>
                       
                    </div>
                </Card>
            </div>
        </div>
    )
}
export { ClientDetails }
