import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchDepartments,
    fetchDesignations,
    getCampaigns,
    getClients,
    getEmployees,
    settingSelectedEmployeeItem
} from "@Redux";
import { useDropDown, useLoader, useModal, useNavigation } from '@Hooks';
import { Button, Card, Checkbox, CommonTable, DropDown, Modal, NoDataFound, NoRecordsFound, Spinner, showToast, Image } from '@Components';
import { translate } from "@I18n";
import { ROUTES } from '@Routes';
import { paginationHandler, getImageUrl, getPhoto } from '@Utils';
import { image } from '@Assets';
import { icons } from '@Assets';

function CampaignsList() {
    const dispatch = useDispatch();
    const { goTo } = useNavigation();
    const { campaignsList } = useSelector((state: any) => state.AuthReducer);

    const isLoading = useLoader(false)

    console.log("campaignsList", campaignsList);



    useEffect(() => {
        getCampaignsHandler()
    }, [])


    const getCampaignsHandler = () => {
        const params = {

        }
        isLoading.show()
        dispatch(
            getCampaigns({
                params,
                onSuccess: (response: any) => () => {
                    isLoading.hide()
                },
                onError: (error) => () => {
                    isLoading.hide()
                },
            })

        )
    }


    const normalizedCampaignsTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {

            return {
                Name: el?.name,
                code: el?.code,
                'Start date': el?.start_date,
                'End date': el?.end_date,
            }
        }
        )
    }

    const manageRoutingHandler = (item) => {
        // item ? dispatch(settingSelectedEmployeeItem(item)) : dispatch(settingSelectedEmployeeItem(item))
        goTo(ROUTES['Dashboard'].addCampaign)
    }


    return (
        <div className='m-3'>
            <Card >
                <div className='d-flex justify-content-between'>
                    {/* <div className='h4 text-muted'>
                        {company_branch?.name}
                    </div> */}
                    <div>
                        <h3>{'Campaigns List'}</h3>
                    </div>
                    <div>
                        <Button
                            size={'sm'}
                            text={'Add Campaign'}
                            onClick={() => {
                                manageRoutingHandler(undefined)
                                // goTo(ROUTES['user-company-module']['add-user'])
                            }}
                        />
                    </div>

                </div>


                {isLoading.loader &&
                    <div className='d-flex justify-content-center'>
                        <Spinner />
                    </div>
                }

                {!isLoading.loader && (
                    <div className='overflow-auto scroll-hide mt-3'
                        style={{
                            marginLeft: "-23px",
                            marginRight: "-23px"
                        }}
                    >

                        {campaignsList && campaignsList.length > 0 ?
                            <CommonTable

                                isPagination
                                tableDataSet={campaignsList}
                                displayDataSet={normalizedCampaignsTableData(campaignsList)}
                                tableOnClick={(e) => {
                                    e.stopPropagation()
                                    goTo(ROUTES['Dashboard'].clientsList)
                                }}
                            />
                            :
                            <NoDataFound />
                        }

                    </div>
                )}

            </Card>

        </div>
    )
}

export { CampaignsList }