import React from 'react'
import { BettermentAnalyticsStatsCardProps } from './interface'
import { Card, CommonTable } from '@Components'
import { Progress } from 'reactstrap'
import { removePercentege } from '@Utils'

function BettermentAnalyticsCard({ title, subTitle, progress, sentence, progressColor = 'success' }: BettermentAnalyticsStatsCardProps) {

    const normalizedTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {
            const { context, previousSentence, newSentence,alternativeSentence } = el
            return {
                'Previous interaction': previousSentence ? previousSentence : '-',
                'New interaction': alternativeSentence ? alternativeSentence : '-'
            }
        }
        )
    }


    return (
        <>
            <Card className="mr--1" style={{}}>
                <h3 className='text-primary'>{title}</h3>

                {
                    sentence && sentence.length > 0 &&
                    <div className='overflow-auto scroll-hide' style={{marginLeft:'-24px',marginRight:'-24px'}}>
                        <CommonTable
                            isPagination
                            tableDataSet={sentence}
                            displayDataSet={normalizedTableData(sentence)}

                        />
                    </div>
                }

            </Card>
        </>

    )
}
export { BettermentAnalyticsCard }