import React, { useState } from 'react'
import { NextStepCardProps } from './interface'
import { Card, CommonTable, P, ProgressCircleChart } from '@Components'
import { Progress } from 'reactstrap'
import { removePercentege, toCheckTheFirstIndexIsNumber } from '@Utils'
import { useDynamicHeight } from '@Hooks'
import { icons } from '@Assets'

function NextStepCard({ title, reason, isShow, progress, progressColor = 'primary', data, progressBarData, overAllPercentage, isShowTable = false, tableData, personsName, onArrowClick, onCardClick }: NextStepCardProps) {

    const dynamicHeight: any = useDynamicHeight()

    const [nextStepData, setNextStepData] = useState<any>(progressBarData)

    const getNameFormattedText = (text, personsData) => {

        const sentence = text
        if (text) {
            const updatedSentence = sentence?.replace(/prospect/g, personsData.person2).replace(/agent/g, personsData.person1).replace(/Prospect/g, personsData.person2).replace(/Agent/g, personsData.person1);
            return updatedSentence
        }
        else {
            return text
        }
    };

    return (
        <>
            <Card className={'mr--1 '}
                onClick={() => {
                    if (onCardClick)
                        onCardClick()
                    console.log("90909090909090")
                }}
            >
                <div className='d-flex justify-content-between mx--3 mb--1'>
                    <div className='col'>
                        <h2 className=''>{title}</h2>
                    </div>
                    <div className=''>
                        {progressBarData && progressBarData.length > 0 && progressBarData.map((el, index) => {
                            const { approach_overview, increasedPercent } = el || {}

                            if (approach_overview === title) {
                                return (
                                    <>
                                        <div className='row h5 font-weight-900 text-primary text-right ' style={{ marginTop: '6px' }}>
                                            {`Max : + ${removePercentege(increasedPercent)} %`}
                                            <div className='ml-2 mr-4' onClick={() => {
                                                if (onArrowClick)
                                                    onArrowClick(index)
                                            }}>
                                                {isShow ? <i className="bi bi-chevron-up text-black"></i> : <i className="bi bi-chevron-down text-black"></i>}
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        })}
                    </div>
                </div>

                {isShow && (
                    <div className='row '>

                        <div className='col-xl-12 col-md-12 text-black mb-3 '>

                            <div className='mt-3'>
                                <h3 className=''>{'Steps'}  <i className="bi bi-bar-chart-steps ml-2 text-black"></i></h3>
                                {
                                    data && data?.map((item, index) => {
                                        return (
                                            <>
                                                <div className='m-0 mb-2 '>
                                                    {toCheckTheFirstIndexIsNumber(item) ? item : `${index + 1}. ${item}`}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>

                            <div className=''>
                                <h3 className=''>Reason
                                    <span className='ml-2'>
                                        <img

                                            src={icons.agentTraitDefault}
                                            alt='...'
                                            height={20}
                                            width={20}
                                            style={{
                                                filter: 'brightness(0%)'
                                            }}
                                        />
                                    </span>
                                </h3>
                                <p className='font-weight-500'> {getNameFormattedText(reason, { person1: personsName?.agent, person2: personsName?.prospect })} </p>

                            </div>

                        </div>

                    </div>
                )}

            </Card>
        </>

    )
}
export { NextStepCard }