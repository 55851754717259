import React, { useEffect, useState } from 'react'
import { Button, DropDown, FileUpload, Input, Spinner, Image, Card, AudioPlayer, NoRecordsFound, NoDataFound } from '@Components'
import { icons, image } from '@Assets'
import { useDispatch, useSelector } from 'react-redux'
import { getView, getNextStep, getTrait, setIsSync, getBettermentAnalytics, fetchOverAllPercent, fetchExpectation, fetchNextStepPersonalized, getPersonsList, getPersonsListSuccess, fetchAgentTrait, getChatConversation, getProspectSharedDetails, getAgentSharedInfoGeneric, getProspectSharedInfoGroup, getProspectSharedInfoItem, getAgentSharedInfoGroup, getAgentSharedInfoItem } from '@Redux'
import { View, NextStep, Trait, BettermentAnalytics, Expectation, NextStepPersonalized, AgentTrait, Transcription, ProspectSharedInfoGeneric, AgentsSharedInformationGeneric, ProspectSharedGroupInformation, ProspectSharedItemInformation, AgentSharedGroupInformation, AgentSharedItemInformation } from '@Modules'
import { useDropDown } from '@Hooks'
import { CardBody, Col, Row } from 'reactstrap'
import { getImageUrl } from '@Utils'
import { CallDetails } from '../../Container/CallDetails'
import { Icons } from 'react-toastify'



function Dashboard() {

    const ANALYTICS_TYPE = [
        { id: 1, name: 'Call Transcription', value: 1, component: <Transcription />, transcription: false, isShow: true, disable: false },
        { id: 2, name: 'Tailored Engagement Strategies', value: 2, component: <NextStepPersonalized />, reengagementInterestProgress: false, isShow: true, disable: false },
        { id: 3, name: 'Standard Engagement Strategies', value: 3, component: <NextStep />, nextStepProgress: false, isShow: true, disable: false },
        { id: 4, name: 'Performance Metrics Overview', value: 4, component: <Expectation />, expectationExtractionProgress: false, isShow: true, disable: false },
        { id: 5, name: 'Performance Enhancement Suggestions', value: 5, component: <BettermentAnalytics />, bettermentAnalyticsProgress: false, isShow: true, disable: false },
        { id: 6, name: 'Prospect Shared Information (Generic)', value: 6, component: <ProspectSharedInfoGeneric />, prospectSharedInformationGenericProgress: false, isShow: true, disable: false },//prospect
        { id: 7, name: 'Personality Analysis', value: 7, component: <Trait />, traitProgress: false, isShow: true, disable: false },
        { id: 8, name: 'Call Perception', value: 8, component: <View />, viewProgress: false, isShow: true, disable: false },
        { id: 9, name: 'Agent Trait', value: 9, component: <AgentTrait />, agentTraitProgress: false, isShow: true, disable: false },
        { id: 10, name: 'Prospect Item', value: 10, component: <ProspectSharedItemInformation />, prospectItemExclusiveProgress: false, isShow: true, disable: false },//prospect
        { id: 11, name: 'Prospect Group', value: 11, component: <ProspectSharedGroupInformation />, prospectGroupExclusiveProgress: false, isShow: true, disable: false },//prospect
        { id: 12, name: 'Agent Shared Information (Generic)', value: 12, component: <AgentsSharedInformationGeneric />, agentSharedInformationGenericProgress: false, isShow: true, disable: false },//Agent
        { id: 13, name: 'Agent Item', value: 13, component: <AgentSharedItemInformation />, agentItemExclusiveProgress: false, isShow: true, disable: false },//Agent
        { id: 14, name: 'Agent Group', value: 14, component: <AgentSharedGroupInformation />, agentGroupExclusiveProgress: false, isShow: true, disable: false },//Agent
        { id: 15, name: 'Call Details', value: 15, component: <CallDetails />, callDetails: false, isShow: true, disable: false },//Engagement

    ];


    const [engagementTabs, setEngagementTabs] = useState([
        { id: 15, name: 'Call Details', value: 15, order: 1, component: <CallDetails />, callDetails: false, isShow: true, activeIcon: icons.callDetailsActive, defaultIcon: icons.callDetailsDefault, disable: false },//Engagement
        { id: 1, name: 'Call Transcription', value: 1, order: 2, component: <Transcription />, transcription: false, isShow: true, activeIcon: icons.callTranscriptionActive, defaultIcon: icons.callTranscriptionDefault, disable: false },
        { id: 2, name: 'Tailored Engagement Strategies', value: 2, order: 3, component: <NextStepPersonalized />, reengagementInterestProgress: false, isShow: true, activeIcon: icons.tailoredEngagementStrategiesActive, defaultIcon: icons.tailoredEngagementStrategiesDefault, disable: false },
        { id: 3, name: 'Standard Engagement Strategies', value: 3, order: 4, component: <NextStep />, nextStepProgress: false, isShow: true, activeIcon: icons.standardEngagementStrategiesActive, defaultIcon: icons.standardEngagementStrategiesDefault, disable: false },
        { id: 4, name: 'Performance Metrics Overview', value: 4, order: 5, component: <Expectation />, expectationExtractionProgress: false, isShow: true, activeIcon: icons.performanceMetricsOverviewActive, defaultIcon: icons.performanceMetricsOverviewDefault, disable: false },
    ])

    const [agentTabs, setAgentTabs] = useState([
        { id: 5, name: 'Agent Performance Enhancement Suggestions', value: 5, order: 1, component: <BettermentAnalytics />, bettermentAnalyticsProgress: false, isShow: true, activeIcon: icons.agentEngagementSuggessionActive, defaultIcon: icons.agentEngagementSuggessionDefault, disable: false },
        { id: 9, name: 'Agent Trait', value: 9, order: 2, component: <AgentTrait />, agentTraitProgress: false, isShow: true, activeIcon: icons.agentTraitActive, defaultIcon: icons.agentTraitDefault, disable: false },
        { id: 12, name: 'Agent Shared Information (Generic)', value: 12, order: 3, component: <AgentsSharedInformationGeneric />, agentSharedInformationGenericProgress: false, isShow: true, activeIcon: icons.agentSharedInfoGenericActive, defaultIcon: icons.agentSharedInfoGenericDefault, disable: false },//Agent
        { id: 13, name: 'Agent Shared Information Item', value: 13, order: 4, component: <AgentSharedItemInformation />, agentItemExclusiveProgress: false, isShow: true, activeIcon: icons.agentSharedInfoExclusiveActive, defaultIcon: icons.agentSharedInfoExclusiveDefault, disable: false },//Agent
        { id: 14, name: 'Agent Shared Information Group', value: 14, order: 5, component: <AgentSharedGroupInformation />, agentGroupExclusiveProgress: false, isShow: true, activeIcon: icons.agentSharedInfoExclusiveActive, defaultIcon: icons.agentSharedInfoExclusiveDefault, disable: false },//Agent

    ])

    const [prospectTabs, setProspectTabs] = useState([
        { id: 7, name: 'Prospect Personality Analysis ', value: 7, order: 1, component: <Trait />, traitProgress: false, isShow: true, activeIcon: icons.agentTraitActive, defaultIcon: icons.agentTraitDefault, disable: false },
        { id: 8, name: 'Prospect Call Perception', value: 8, order: 2, component: <View />, viewProgress: false, isShow: true, activeIcon: icons.prospectCallPerceptionActive, defaultIcon: icons.prospectCallPerceptionDefault, disable: false },
        { id: 6, name: 'Prospect Shared Information (Generic)', value: 6, order: 3, component: <ProspectSharedInfoGeneric />, prospectSharedInformationGenericProgress: false, isShow: true, activeIcon: icons.agentSharedInfoGenericActive, defaultIcon: icons.agentSharedInfoGenericDefault, disable: false },
        { id: 10, name: 'Prospect Shared Information Item', value: 10, order: 4, component: <ProspectSharedItemInformation />, prospectItemExclusiveProgress: false, isShow: true, activeIcon: icons.agentSharedInfoExclusiveActive, defaultIcon: icons.agentSharedInfoExclusiveDefault, disable: false },//prospect
        { id: 11, name: 'Prospect Shared Information Group', value: 11, order: 5, component: <ProspectSharedGroupInformation />, prospectGroupExclusiveProgress: false, isShow: true, activeIcon: icons.agentSharedInfoExclusiveActive, defaultIcon: icons.agentSharedInfoExclusiveDefault, disable: false },//prospect

    ])

    const personIcon =[
    icons.userDefault,
    icons.SteveJobs,
    icons.RobertGreen,
    icons.ZigZigler
        
    ]


    const { isSync, referenceId, bettermentPersonsList, analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);
    console.log(analyticsOverAllPercentage, 'analyticsOverAllPercentage');

    const [currentStatusId, setCurrentStatusId] = useState(15)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [isShowTabs, setIsShowTabs] = useState(false)
    const [analyticsTabsData, setAnalyticsTabsData] = useState<any>(ANALYTICS_TYPE)
    const [selectedPersonIndex, setSelectedPersonIndex] = useState<any>(0)
    const [bettermentPersonaId, setBetterMentPersonaId] = useState('')

    const [note, setNote] = useState('')

    const [selectedTab, setSelectedTab] = useState('Call Details')

    useEffect(() => {
        // getChatConversationApi()
        getOverAllPercentageApi();
        dispatch(getPersonsListSuccess({ details: [] }))
    }, [])


    const getNextStepPersonalizedApiHandler = (key?: string) => {
        // if (!isSync.nextStep) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })
        }

        setNote('')

        dispatch(
            fetchNextStepPersonalized({
                params,
                onSuccess: (success: any) => () => {
                    setNote(" Provides a list of areas of engagement or interest expressed by the prospect, along with customized approaches and detailed steps for each approach. These suggestions aim to re-engage the prospect and increase the conversion ratio")

                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            nextStepPersonalized: true
                        })
                    );
                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    const getInitialView = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })
        }
        setNote('')

        dispatch(
            getView({
                params,
                onSuccess: (success: any) => () => {
                    setNote("contains propspect view on the goal defined for the call by the agent")
                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            view: true
                        })
                    );


                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };



    const getNextStepApiHandler = (key?: string) => {
        // if (!isSync.nextStep) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })
        }

        setNote('')

        dispatch(
            getNextStep({
                params,
                onSuccess: (success: any) => () => {
                    setNote("Presents a list of generic approaches, along with detailed steps for each approach, to re-engage the prospect and increase the conversion ratio.")

                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            nextStep: true
                        })
                    );
                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };


    const getAgentTrait = (key?: string) => {

        const params = {
            id: referenceId,
            ...(key && { isRefresh: true })
        }

        setNote('')
        setLoading(true)


        dispatch(fetchAgentTrait({
            params,
            onSuccess: (success: any) => () => {
                setNote("Provides detailed insights into the traits of the agent based on the conversation analysis")

                setLoading(false)
                dispatch(
                    setIsSync({
                        ...isSync,
                        nextStep: true
                    })
                );
            },
            onError: (error: string) => () => {
                setLoading(false)
            },
        }))
    }

    const getTraitApiHandler = (key?: string) => {
        // if (!isSync.traits) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })
        }
        setNote('')

        dispatch(
            getTrait({
                params,
                onSuccess: (success: any) => () => {
                    setNote("contains details about trait of the prospect with relative percentage by analysing the conversation")

                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            traits: true
                        })
                    );
                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //   }
    };


    const getBetterNextApiHandler = (id?: string, key?: string) => {
        //if (!isSync.betterment) {
        console.log("xxxxxxxxxxxxx", bettermentPersonaId);

        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(id && { personaId: id }),
            ...(key === 'Refresh' && { personaId: bettermentPersonaId }),
            ...(key && { isRefresh: true })

        }

        setNote('')

        dispatch(
            getBettermentAnalytics({
                params,
                onSuccess: (success: any) => () => {
                    setNote(" Recommends alternative approaches for the agent to enhance their performance and achieve the desired targets, based on conversation analysis")

                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            betterment: true
                        })
                    );

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
    };



    const getBettermentPersonsList = () => {

        const params = {}

        dispatch(
            getPersonsList({
                params,
                onSuccess: (success: any) => () => {
                    console.log("success===========>", success);

                },
                onError: (error: string) => () => {
                },
            })
        );
    }


    const getExpectationApiHandler = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }

        setNote('')

        dispatch(
            fetchExpectation({
                params,
                onSuccess: (success: any) => () => {
                    setNote("Contains the list of key metrics defined by the agent prior to the call, along with details on the percentage achieved based on references from the conversation. It also includes the reasons behind the performance results")

                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            view: true
                        })
                    );


                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    console.log(analyticsOverAllPercentage, agentTabs, false,"analyticsOverAllPercentage, agentTabs, false")
    // console.log(expectationDetails,"============={")
    //getChatConversation

    const getChatConversationApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }

        dispatch(
            getChatConversation({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Contains transcription extracted from the call between the agent and the prospect')
                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Prospect shared info generic

    const getProspectSharedDetailsApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getProspectSharedDetails({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    dispatch(
                        setIsSync({
                            ...isSync,
                            prospectSharedInfoGeneric: true
                        })
                    );
                    setNote('Contains all the crucial information shared by the prospect during the conversation')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Agent shared info generic

    const getAgentSharedInfoGenericApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getAgentSharedInfoGeneric({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Presents a summary of all the essential information shared by the agent during the conversation')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Prospect shared info group
    const getProspectSharedInfoGroupApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getProspectSharedInfoGroup({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Contains all the crucial information shared by the prospect with the agent, focusing on specific key items provided by the agent')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Prospect shared info item
    const getProspectSharedInfoItemApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getProspectSharedInfoItem({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Contains all the crucial information shared by the prospect with the agent, focusing on specific key items provided by the agent')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Agent shared info group
    const getAgentSharedInfoGroupApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getAgentSharedInfoGroup({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Contains all the crucial information shared by the prospect with the agent, focusing on specific key items provided by the agent')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };

    //Agent shared info item
    const getAgentSharedInfoItemApi = (key?: string) => {
        //  if (!isSync.view) {
        setLoading(true)
        const params =
        {
            id: referenceId,
            ...(key && { isRefresh: true })

        }
        setNote('')

        dispatch(
            getAgentSharedInfoItem({
                params,
                onSuccess: (success: any) => () => {
                    setLoading(false)
                    setNote('Contains all the crucial information shared by the prospect with the agent, focusing on specific key items provided by the agent')

                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
        //  }
    };




    const getOverAllPercentageApi = () => {
        setLoading(true)
        const params =
        {
            id: referenceId
        }

        dispatch(
            fetchOverAllPercent({
                params,
                onSuccess: (success: any) => () => {
                    console.log("success====>", success);
                    setAnalyticsTabsData(isShowTab(success.details, analyticsTabsData, true))
                    setLoading(false)
                    setIsShowTabs(true)
                },
                onError: (error: string) => () => {
                    setLoading(false)
                },
            })
        );
    };

    const TabViewApiHandler = (id: number, key?: string) => {
        console.log('//////////',id,key)

        switch (id) {
            case 1: //Call Transcription
                setNote('')
                getChatConversationApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 2: //Tailored Engagement Strategies
                getNextStepPersonalizedApiHandler(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 3: //Standard Engagement Strategies
                getNextStepApiHandler(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 4: //Performance Metrics Overview
                getExpectationApiHandler(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 5: //Performance Enhancement Suggestions
                setSelectedPersonIndex(0)
                getBetterNextApiHandler("", key)
                getBettermentPersonsList()
                break;
            case 6: //Prospect Shared Information (Generic)
                getProspectSharedDetailsApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 7: //Personality Analysis
                getTraitApiHandler(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 8: //Call Perception
                getInitialView(key);
                dispatch(getPersonsListSuccess({ details: [] }))
                break;

            case 9: //Agent Trait
                getAgentTrait(key);
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 10: //Prospect Shared Information item
                getProspectSharedInfoItemApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 11: //Prospect Shared Information group
                getProspectSharedInfoGroupApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 12: //Agent Shared Information (Generic)
                getAgentSharedInfoGenericApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 13: //Agent Shared Information Item
                getAgentSharedInfoItemApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 14: //Agent Shared Information group
                getAgentSharedInfoGroupApi(key)
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
            case 15: //Call details
                setNote('')
                dispatch(getPersonsListSuccess({ details: [] }))
                break;
        }
    }


    const isShowTab = (type, data, isSort) => {

        let analyticsType: any = []
        let object: any = { ...type }

        if (type && Object.keys(type)?.length > 0) {
            object = { ...type, transcription: 2, callDetails: 2 }
        }

        if (type && Object.keys(type)?.length > 0) {
            for (const key in object) {

                if (object.hasOwnProperty(key)) {
                    const matchingItem = data.find(item => item.hasOwnProperty(key));
                    if (matchingItem && Object.keys(matchingItem).length > 0) {
                        if (matchingItem && object[key] === 2) {
                            matchingItem.isShow = true;
                            matchingItem[key] = true;
                        }
                        else if (matchingItem && Object.keys(matchingItem).length > 0 && object[key] === -1 || object[key] === 1 || object[key] === 0) {
                            matchingItem.isShow = false;
                            matchingItem[key] = false;
                        }
                        else if (matchingItem && object[key] === -2) {
                            matchingItem.isShow = false;
                            matchingItem.disable = true;
                        }
                        analyticsType.push(matchingItem)
                    }
                }
            }
        }
        let filteredArray: any

        if (isSort) {
            filteredArray = analyticsType.filter(obj => obj !== null && obj !== undefined).sort((a, b) => a.order - b.order);
        }
        else {
            filteredArray = analyticsType.filter(obj => obj !== null && obj !== undefined)
        }

        return filteredArray
    }


    return (
        <>
            <div className='container-fluid pt-4' >
                <div className='row sticky-top' style={{ backgroundColor: '#f8f9fe' }}>
                    {analyticsOverAllPercentage && Object.keys(analyticsOverAllPercentage).length > 0 && (
                        <>
                            <div className='col-sm-4'>
                                <Card style={{ height: '10.7vh', paddingBottom: '6em' }}>
                                    <div className='d-flex justify-content-center mt--2 mb-2'>
                                        <h4>{'Engagement'}</h4>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {isShowTab(analyticsOverAllPercentage, engagementTabs, true)?.map((el: any, index: number) => {
                                            if (!el.disable) {
                                                return (
                                                    <>
                                                        <div className='mx-3'>
                                                            <Image
                                                                height={22}
                                                                width={22}
                                                                src={el.id === currentStatusId ? el.activeIcon : el.defaultIcon}
                                                                onClick={() => {
                                                                    setSelectedTab(el.name)
                                                                    setCurrentStatusId(el.id)
                                                                    TabViewApiHandler(el.id)
                                                                }}
                                                            />

                                                        </div>

                                                    </>
                                                )
                                            }
                                        })}

                                    </div>
                                </Card>
                            </div>

                            {/* Agent */}

                            <div className='col-sm-4'>
                                <Card style={{ height: '10.7vh', paddingBottom: '6em' }} >
                                    <div className='d-flex justify-content-center mt--2 mb-2'>
                                        <h4>{'Agent'}</h4>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        {isShowTab(analyticsOverAllPercentage, agentTabs, false)?.map((el: any, index: number) => {

                                            if (el.disable === false) {
                                                return (
                                                    <div className='mx-3'>
                                                        <Image
                                                            height={22}
                                                            width={22}
                                                            src={el.id === currentStatusId ? el.activeIcon : el.defaultIcon}
                                                            onClick={() => {
                                                                setCurrentStatusId(el.id)
                                                                setSelectedTab(el.name)
                                                                TabViewApiHandler(el.id)
                                                            }} 
                                                            />
                                                    </div>
                                                )
                                            }
                                        })}
                                    </div>

                                </Card>
                            </div>
                        </>
                    )}
                    {/* Prospect */}

                    <div className='col-sm-4 overflow-auto overflow-hide'>
                        <Card style={{ height: '10.7vh', paddingBottom: '6em' }} >
                            <div className='d-flex justify-content-center mt--2 mb-2'>
                                <h4>{'Prospect'}</h4>
                            </div>
                            <div className='d-flex justify-content-center'>
                                {isShowTab(analyticsOverAllPercentage, prospectTabs, false)?.map((el: any, index: number) => {

                                    if (!el.disable) {
                                        return (
                                            <div className='mx-3'>

                                                <Image
                                                    height={22}
                                                    width={22}
                                                    src={el.id === currentStatusId ? el.activeIcon : el.defaultIcon}
                                                    onClick={() => {
                                                        setCurrentStatusId(el.id)
                                                        setSelectedTab(el.name)
                                                        TabViewApiHandler(el.id)
                                                    }} />
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </Card>
                    </div>
                </div>
                <div className='' style={{zoom:'85%'}}>
                    <div className='d-flex justify-content-between'>
                        {analyticsOverAllPercentage && Object.keys(analyticsOverAllPercentage).length > 0 && (
                            <div className=''>
                                {selectedTab && <h2 className='text-primary'>{selectedTab}</h2>}
                                {note && <h4 className='pb-2 text-muted '>{note}</h4>}
                            </div>
                        )}
                        <div className=''>
                            {analyticsOverAllPercentage && Object.keys(analyticsOverAllPercentage).length > 0 && analyticsTabsData.map((el) => {

                                if (el.isShow && currentStatusId === el.value && el.name !== 'Call Transcription' && el.name !== 'Call Details') {
                                    return (
                                        <div>
                                            <Button text={'Refresh'} size={'sm'}
                                                onClick={() => {
                                                    TabViewApiHandler(currentStatusId, 'Refresh')
                                                    const findCurrentPerson = bettermentPersonsList && bettermentPersonsList.length > 0 && bettermentPersonsList.findIndex((el) => el.personaId === bettermentPersonaId)
                                                    setSelectedPersonIndex(findCurrentPerson)
                                                    console.log("findCurrentPerson", findCurrentPerson);

                                                }}
                                            />
                                        </div>
                                    )
                                }
                            })}
                        </div>
                    </div>
                    {/*** Performance Enhancement Suggestions users card*/}

                    <div className={`row col-auto ${!loading ? '' : 'mt-4'}`}>
                        {bettermentPersonsList && bettermentPersonsList?.length > 0 && bettermentPersonsList?.map((item, index) => {

                            return (
                                <div className='mx-2' onClick={() => {
                                    setSelectedPersonIndex(index)
                                    setBetterMentPersonaId(item.personaId)
                                    getBetterNextApiHandler(item.personaId)
                                }}>
                                    <Card className={'col-auto'}>
                                        <div className="row align-items-center">
                                            <Col className="col-auto">

                                                <img
                                                    style={{ border: index === selectedPersonIndex ? '4px solid rgba(133, 77, 243, 1.0)' : '' }}
                                                    alt="..."
                                                    className="rounded-circle"
                                                    src={personIcon[index]}
                                                    height={50}
                                                    width={50}
                                                />
                                                
                                            </Col>
                                            <div className="col ml--2">
                                                <h4 className={`mb-0 ${index === selectedPersonIndex ? 'text-primary' : 'text-black'} `}>
                                                    {item.title}
                                                </h4>
                                                <p className="text-sm text-muted mb-0">-</p>
                                            </div>
                                            
                                        </div>
                                    </Card>
                                </div>
                            )
                        })}
                    </div>
                    {loading && (
                        <div className="d-flex align-items-center  justify-content-center" style={{ minHeight: '200px' }}>
                            <Spinner />
                        </div>
                    )}
                    {!loading && (
                        <>
                            <div className="tab-content px-1" id="myTabContent">

                                {analyticsTabsData.map((el) => {
                                    console.log("analyticsTabsData", analyticsTabsData)
                                    if (el.isShow) {
                                        return (
                                            <div

                                                className={`tab-pane fade ${currentStatusId === el.value && " show active"}     `}
                                                id={`tabs-icons-text-${el.id}`}
                                                role="tabpanel"
                                                aria-labelledby={`tabs-icons-text-${el.id}-tab`}
                                            >
                                                {el.component}
                                            </div>
                                        )
                                    }
                                    else {
                                        return (
                                            <div
                                                className={`tab-pane fade ${currentStatusId === el.value && " show active"} d-flex justify-content-center align-items-center`}
                                                id={`tabs-icons-text-${el.id}`}
                                                role="tabpanel"
                                                aria-labelledby={`tabs-icons-text-${el.id}-tab`}
                                            >
                                                <Button
                                                    text={'Re-Generate'}
                                                    onClick={() => { TabViewApiHandler(currentStatusId, 'Re-Generate') }}
                                                />
                                            </div>

                                        )
                                    }

                                })}
                            </div>
                        </>
                    )}


                </div>

            </div >
        </>
    )
}


export { Dashboard }