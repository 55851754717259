
export const RESET_AUTH = 'RESET_AUTH'


export const VALIDATE_USER_BUSINESS = 'VALIDATE_USER_BUSINESS';
export const VALIDATE_USER_BUSINESS_SUCCESS = 'VALIDATE_USER_BUSINESS_SUCCESS';
export const VALIDATE_USER_BUSINESS_FAILURE = 'VALIDATE_USER_BUSINESS_FAILURE';



/**
 * OTP_LOGIN
 */

export const OTP_LOGIN = 'OTP_LOGIN';
export const OTP_LOGIN_SUCCESS = 'OTP_LOGIN_SUCCESS';
export const OTP_LOGIN_FAILURE = 'OTP_LOGIN_FAILURE';


export const SET_REGISTER_MOBILE_NUMBER = 'SET_REGISTER_MOBILE_NUMBER';



/**
 * GET DASHBOARD
 */

export const GET_DASHBOARD = 'GET_DASHBOARD';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';

/**
 * Add employee
 */

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

/**
 * Add employee photo
 */

export const UPDATE_EMPLOYEE_PROFILE_PHOTO = 'UPDATE_EMPLOYEE_PROFILE_PHOTO';
export const UPDATE_EMPLOYEE_PROFILE_PHOTO_SUCCESS = 'UPDATE_EMPLOYEE_PROFILE_PHOTO_SUCCESS';
export const UPDATE_EMPLOYEE_PROFILE_PHOTO_FAILURE = 'UPDATE_EMPLOYEE_PROFILE_PHOTO_FAILURE';

/**
 * Get employees
 */

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS';
export const GET_EMPLOYEES_FAILURE = 'GET_EMPLOYEES_FAILURE';

/**
 * get employee details
 */

export const FETCH_EMPLOYEE_DETAILS = "FETCH_EMPLOYEE_DETAILS";
export const FETCH_EMPLOYEE_DETAILS_SUCCESS = "FETCH_EMPLOYEE_DETAILS_SUCCESS";
export const FETCH_EMPLOYEE_DETAILS_FAILURE = "FETCH_EMPLOYEE_DETAILS_FAILURE";

//Setting selected employee details

export const SETTING_SELECTED_EMPLOYEE_ITEM = "SETTING_SELECTED_EMPLOYEE_ITEM"

//AdD Client

export const ADD_CLIENT = "ADD_CLIENT";
export const ADD_CLIENT_SUCCESS = "ADD_CLIENT_SUCCESS";
export const ADD_CLIENT_FAILURE = "ADD_CLIENT_FAILURE";

//Get Client

export const GET_CLIENTS = "GET_CLIENTS";
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILURE = "GET_CLIENTS_FAILURE";

//AdD Campaign

export const ADD_CAMPAIGN = "ADD_CAMPAIGN";
export const ADD_CAMPAIGN_SUCCESS = "ADD_CAMPAIGN_SUCCESS";
export const ADD_CAMPAIGN_FAILURE = "ADD_CAMPAIGN_FAILURE";

//Get Campaigns

export const GET_CAMPAIGNS = "GET_CAMPAIGNS";
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS";
export const GET_CAMPAIGNS_FAILURE = "GET_CAMPAIGNS_FAILURE";

//Get products 

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILURE = "GET_PRODUCTS_FAILURE";

//Bulk upload client info

export const BULK_UPLOAD_CLIENT_INFO = "BULK_UPLOAD_CLIENT_INFO";
export const BULK_UPLOAD_CLIENT_INFO_SUCCESS = "BULK_UPLOAD_CLIENT_INFO_SUCCESS";
export const BULK_UPLOAD_CLIENT_INFO_FAILURE = "BULK_UPLOAD_CLIENT_INFO_FAILURE";


//Get client template

export const GET_CLIENT_TEMPLATE = "GET_CLIENT_TEMPLATE";
export const GET_CLIENT_TEMPLATE_SUCCESS = "GET_CLIENT_TEMPLATE_SUCCESS";
export const GET_CLIENT_TEMPLATE_FAILURE = "GET_CLIENT_TEMPLATE_FAILURE";

//setting current campaign details

export const SETTING_CURRENT_CAMPAIGN_DETAILS = 'SETTING_CURRENT_CAMPAIGN_DETAILS'

//initiate campaign call


export const INITIATE_CAMPAIGN_CALL = "INITIATE_CAMPAIGN_CALL";
export const INITIATE_CAMPAIGN_CALL_SUCCESS = "INITIATE_CAMPAIGN_CALL_SUCCESS";
export const INITIATE_CAMPAIGN_CALL_FAILURE = "INITIATE_CAMPAIGN_CALL_FAILURE";

//Mark end call

export const MARK_END_CALL = "MARK_END_CALL";
export const MARK_END_CALL_SUCCESS = "MARK_END_CALL_SUCCESS";
export const MARK_END_CALL_FAILURE = "MARK_END_CALL_FAILURE";

//is campaign added

export const IS_CAMPAIGN_ADDED = 'IS_CAMPAIGN_ADDED'



//Mark end call

export const FETCH_AGENT_TRAIT = "FETCH_AGENT_TRAIT";
export const FETCH_AGENT_TRAIT_SUCCESS = "FETCH_AGENT_TRAIT_SUCCESS";
export const FETCH_AGENT_TRAIT_FAILURE = "FETCH_AGENT_TRAIT_FAILURE";

//get chat conversation

export const GET_CHAT_CONVERSATION = "GET_CHAT_CONVERSATION";
export const GET_CHAT_CONVERSATION_SUCCESS = "GET_CHAT_CONVERSATION_SUCCESS";
export const GET_CHAT_CONVERSATION_FAILURE = "GET_CHAT_CONVERSATION_FAILURE";

//get prospect shared details generic

export const GET_PROSPECT_SHARED_DETAILS = "GET_PROSPECT_SHARED_DETAILS";
export const GET_PROSPECT_SHARED_DETAILS_SUCCESS = "GET_PROSPECT_SHARED_DETAILS_SUCCESS";
export const GET_PROSPECT_SHARED_DETAILS_FAILURE = "GET_PROSPECT_SHARED_DETAILS_FAILURE";



//Get Agent shared info generic

export const GET_AGENT_SHARED_INFO_GENERIC = "GET_AGENT_SHARED_INFO_GENERIC";
export const GET_AGENT_SHARED_INFO_GENERIC_SUCCESS = "GET_AGENT_SHARED_INFO_GENERIC_SUCCESS";
export const GET_AGENT_SHARED_INFO_GENERIC_FAILURE = "GET_AGENT_SHARED_INFO_GENERIC_FAILURE";

//Get Prospect shared info Exclusive

export const GET_PROSPECT_SHARED_INFO_GROUP = "GET_PROSPECT_SHARED_INFO_GROUP";
export const GET_PROSPECT_SHARED_INFO_GROUP_SUCCESS = "GET_PROSPECT_SHARED_INFO_GROUP_SUCCESS";
export const GET_PROSPECT_SHARED_INFO_GROUP_FAILURE = "GET_PROSPECT_SHARED_INFO_GROUP_FAILURE";

//Get prospect shared info exclusive

export const GET_PROSPECT_SHARED_INFO_ITEM = "GET_PROSPECT_SHARED_INFO_ITEM";
export const GET_PROSPECT_SHARED_INFO_ITEM_SUCCESS = "GET_PROSPECT_SHARED_INFO_ITEM_SUCCESS";
export const GET_PROSPECT_SHARED_INFO_ITEM_FAILURE = "GET_PROSPECT_SHARED_INFO_ITEM_FAILURE";

//Get Agent shared info Exclusive

export const GET_AGENT_SHARED_INFO_GROUP = "GET_AGENT_SHARED_INFO_GROUP";
export const GET_AGENT_SHARED_INFO_GROUP_SUCCESS = "GET_AGENT_SHARED_INFO_GROUP_SUCCESS";
export const GET_AGENT_SHARED_INFO_GROUP_FAILURE = "GET_AGENT_SHARED_INFO_GROUP_FAILURE";

//Get prospect shared info exclusive

export const GET_AGENT_SHARED_INFO_ITEM = "GET_AGENT_SHARED_INFO_ITEM";
export const GET_AGENT_SHARED_INFO_ITEM_SUCCESS = "GET_AGENT_SHARED_INFO_ITEM_SUCCESS";
export const GET_AGENT_SHARED_INFO_ITEM_FAILURE = "GET_AGENT_SHARED_INFO_ITEM_FAILURE";

//call chats

export const CALL_NOTES = 'CALL_NOTES'

//selected call details

export const SELECTED_CALL_DETAILS = 'SELECTED_CALL_DETAILS'

//i do it 

export const REGISTER_COMPANY ='REGISTER_COMPANY'
export const REGISTER_COMPANY_SUCCESS ='REGISTER_COMPANY_SUCCESS'
export const REGISTER_COMPANY_FAILURE ='REGISTER_COMPANY_FAILURE'

export const GET_ALL_BRANCHES ='GET_ALL_BRANCHES'
export const GET_ALL_BRANCHES_SUCCESS ='GET_ALL_BRANCHES_SUCCESS'
export const GET_ALL_BRANCHES_FAILURE ='GET_ALL_BRANCHES_FAILURE'

export const GET_COMPANY_BRANCH_DETAILS ='GET_COMPANY_BRANCH_DETAILS'
export const GET_COMPANY_BRANCH_DETAILS_SUCCESS ='GET_COMPANY_BRANCH_DETAILS_SUCCESS'
export const GET_COMPANY_BRANCH_DETAILS_FAILURE ='GET_COMPANY_BRANCH_DETAILS_FAILURE'

export const GET_DESIGNATIONS ='GET_DESIGNATIONS'
export const GET_DESIGNATIONS_SUCCESS ='GET_DESIGNATIONS_SUCCESS'
export const GET_DESIGNATIONS_FAILURE ='GET_DESIGNATIONS_FAILURE'

export const ADD_DESIGNATIONS ='ADD_DESIGNATIONS'
export const ADD_DESIGNATIONS_SUCCESS ='ADD_DESIGNATIONS_SUCCESS'
export const ADD_DESIGNATIONS_FAILURE ='ADD_DESIGNATIONS_FAILURE'


//Bulk upload clients 

export const BULK_UPLOAD_CLIENTS = "BULK_UPLOAD_CLIENTS";
export const BULK_UPLOAD_CLIENTS_SUCCESS = "BULK_UPLOAD_CLIENTS_SUCCESS";
export const BULK_UPLOAD_CLIENTS_FAILURE = "BULK_UPLOAD_CLIENTS_FAILURE";

//Bulk upload clients 

export const CLIENTS_DATA = "CLIENTS_DATA";
