import { Header, Home, Form,Footer, Cards,Data } from "@Modules"
import './index.css';
import React, { useEffect } from "react";
import { useDispatch } from 'react-redux'
import { loginUser } from '@Redux'
import { Divider } from "@Components";



function Landing() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      loginUser(false)

    )
  }


    , []);



  return (
    <>
      <Header />
      <Home />
      <Form />
      <Data />
      <Cards />
      <Footer />
     </>

    

  );
}

export { Landing };
