import React, { useEffect, useState } from 'react'
import { Card, NoDataFound, ProgressCircleChart, Spinner, } from '@Components';
import { useSelector } from 'react-redux';
import { useDynamicHeight, useWindowDimensions } from '@Hooks';
import { NextStepCard, OverallProgressCard } from '@Modules';
import { removePercentege } from '@Utils';
function NextStep() {
    const { nextStep, analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);
    const { approaches, currentConversionPercent } = nextStep || {}


    const dynamicHeight: any = useDynamicHeight()
    const [nextStepData, setNextStepData] = useState<any>(approaches)

    useEffect(() => {
        const modifiedNextStepData = approaches?.map((el: any) => {

            return { ...el, isShow: false }
        }).sort((a, b) => removePercentege(a.increasedPercent) - removePercentege(b.increasedPercent)).reverse()
        console.log("modifiedNextStepData", modifiedNextStepData);

        setNextStepData(modifiedNextStepData)
    }, [])


    return (
        <>
            {nextStepData && nextStepData?.length > 0 ? (
                <div className='row'>
                    <div className='col '>
                        <div className='row'>
                            {
                                nextStepData && nextStepData.length > 0 && nextStepData.map((item, index2) => {
                                    const { approach_overview, reason, relative_increase_in_percent, steps, isShow } = item
                                    return (
                                        <div className='col-xl-12 col-md-12 '
                                        >
                                            <NextStepCard
                                                onCardClick={() => {
                                                    const updatedData = [...nextStepData]
                                                    console.log("updatedDataupdatedData", updatedData)

                                                    updatedData[index2].isShow = !updatedData[index2].isShow
                                                    console.log("calleddd");

                                                    setNextStepData(updatedData)
                                                }}
                                                title={approach_overview}
                                                isShow={isShow}
                                                reason={reason}
                                                progress={relative_increase_in_percent}
                                                data={steps}
                                                progressBarData={nextStepData}
                                                overAllPercentage={analyticsOverAllPercentage?.overAllPercentage}
                                                personsName={nextStep?.personName}
                                                onArrowClick={(index) => {
                                                    const updatedData = [...nextStepData]
                                                    console.log("updatedDataupdatedData", updatedData)

                                                    updatedData[index2].isShow = !updatedData[index2].isShow
                                                    console.log("calleddd");

                                                    setNextStepData(updatedData)
                                                }}
                                            />
                                        </div>
                                    );
                                })

                            }

                        </div>
                    </div>

                </div>
            ) : <NoDataFound />}
        </>
    )
}

export { NextStep }


