import React from 'react'
import { StatsCardProps } from './interface'
import { Card, NoDataFound, P, ProgressCircleChart } from '@Components'
import { Progress } from 'reactstrap'
import { convertToUpperCase, removePercentege } from '@Utils'
import { useDynamicHeight } from '@Hooks'




function StatsCard({ title, subTitle, personsName, progress, progressColor = 'primary', data, reference = 'References', progressBarData, isShowPlusOnPercentage }: StatsCardProps) {

    const dynamicHeight: any = useDynamicHeight()

    const getNameFormattedText = (text, personsData) => {

        const sentence = text
        if (text) {
            const updatedSentence = sentence?.replace(/prospect/g, personsData.person2).replace(/agent/g, personsData.person1).replace(/Prospect/g, personsData.person2).replace(/Agent/g, personsData.person1);
            return updatedSentence
        }
        else {
            return text
        }

    };


    //     const sentence = "The prospect expressed curiosity about the features of the Infinity ZLX agent\n\n{ person1: personsName?.person1, person2: personsName?.person2 }";

    // const updatedSentence = sentence.replace(/person1/g, "prospect").replace(/person2/g, "agent");

    // console.log(updatedSentence);



    const conditions = [null, 'null', 'none', '', '-', 'n/a'];

    return (
        <div className='overflow-auto scroll-hidden'>
            <Card className={'mr--1 overflow-auto scroll-hidden'} style={{ height: dynamicHeight.dynamicHeight - 150 }}>

                <div className='row'>
                    <div className='col'>
                        <h2 className='text-primary'>{title}</h2>
                    </div>
                    <div className='col'>
                        {progressBarData && progressBarData?.length > 0 && progressBarData?.map((el) => {
                            const { emotion_perception, relative_percent, trait, percent, relative_increase_in_percent, expectation, increasedPercent, percentage } = el || {}

                            if (emotion_perception === title || trait === title || expectation === title) {
                                return (
                                    <>
                                        <div className='mt--2 font-weight-900 h5 text-black mr-2 text-right'>
                                            {`${isShowPlusOnPercentage ? '+' : ''} ${removePercentege(percentage) || removePercentege(relative_increase_in_percent) || removePercentege(percent) || removePercentege(increasedPercent) || 0} %`}
                                        </div>
                                        <Progress
                                            className="progress-md mb-0"
                                            max="100"
                                            value={removePercentege(percentage) || removePercentege(relative_increase_in_percent) || removePercentege(percent) || removePercentege(increasedPercent) || 0}
                                            color={'primary'}
                                        />
                                    </>
                                )
                            }
                        })}
                    </div>
                </div>


                <div>



                    <div className='mt-3 px-1'>
                        <h3 className=''>{reference}</h3>
                        {/* {data && data?.length > 10 ? */}
                        {
                            data?.map((item, index) => {
                                const convertedToString = item?.agentSentence + ''
                                const convertedToString2 = item?.prospectSentence + ''


                                return (
                                    <>

                                        <div className='py-2'>
                                            {!conditions.includes(convertedToString?.toLowerCase()) && (
                                                <div className='mr-5 py-4 bg-grey font-weight-500 font-family-Arial text-darkGrey text-wrap' style={{ maxWidth: "90%", borderRadius: 8 }}>
                                                    <React.Fragment>
                                                        <div style={{ marginLeft: 30 }}>
                                                            {` "${item?.agentSentence}"`}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                            {!conditions.includes(convertedToString2?.toLowerCase()) && (
                                                <div className='d-flex justify-content-end py-4 bg-grey font-weight-500 text-black ml-5 text-wrap mt-3' style={{ borderRadius: 8, fontStyle: 'italic' }}>
                                                    <React.Fragment >
                                                        <div className='' style={{ marginLeft: 30, marginRight: 30 }}>
                                                            {` "${item?.prospectSentence}"`}
                                                        </div>
                                                    </React.Fragment>
                                                </div>
                                            )}
                                        </div>

                                        {(data.length - 1 > index) &&
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <i className="bi bi-three-dots-vertical"></i>
                                            </div>
                                        }
                                    </>
                                )
                            })

                        }
                    </div>

                </div>
                <div className='col-xl-12 col-md-12 text-black row mt-5'>
                    <div className='pb-3'>
                        {/* <h3 className=''>Reason</h3> */}
                        <p className='font-weight-600' style={{ fontSize: 18 }}> {convertToUpperCase(getNameFormattedText(subTitle, { person1: personsName?.agent, person2: personsName?.prospect }))} </p>

                    </div>
                    {/* <div className='col-xl-4 col-md-4'>
                        <ProgressCircleChart progress={getParseNumber(progress)} />
                    </div> */}
                </div>

            </Card>
        </div>

    )
}
export { StatsCard }