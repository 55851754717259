import { NoDataFound } from '@Components';
import { OverallProgressCard, StatsCard } from '@Modules';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';

function AgentTrait() {
    const { agentTrait } = useSelector((state: any) => state.AuthReducer);
    const { traits, personName } = agentTrait || {}

    const [selectedData, setSelectedData] = useState<any>(traits && traits.length > 0 ? [traits[0]] : [])


    return (
        <>
            {traits && traits.length > 0 ? (
                <div className='row '>
                    <div className='col-xl-4 col-md-12 ml--1'>
                        <OverallProgressCard heading={'Traits'} isShowAsOnclick onItemClick={(item) => {
                            const filteredData = traits.filter((el) => el.trait === item.trait)
                            setSelectedData(filteredData)
                        }} data={traits} lineProgressColor={'primary'} />
                    </div>

                    <div className='col-xl-8 col-md-12 ' >
                        <div className='row'>
                            {
                                selectedData && selectedData.length > 0 && selectedData.map((item) => {
                                    const { trait, reason, percent, reference } = item
                                    return (
                                        <div className='col-xl-12 col-md-12 mb--3'>
                                            <StatsCard personsName={personName} title={trait} subTitle={reason} progress={percent} data={reference} progressBarData={traits} />
                                        </div>
                                    );
                                })

                            }
                        </div>
                    </div>
                </div>
            ) : <NoDataFound />}
        </>
    )
}

export { AgentTrait }