
import * as ActionTypes from '../ActionType'


export const resetAuthReducer = () => {
  return {
    type: ActionTypes.RESET_AUTH,
  }
}

export const validateUserBusiness = (params: any) => {
  return {
    type: ActionTypes.VALIDATE_USER_BUSINESS,
    payload: params,
  };
};

export const validateUserBusinessSuccess = (response: any) => {
  return {
    type: ActionTypes.VALIDATE_USER_BUSINESS_SUCCESS,
    payload: response,
  };
};

export const validateUserBusinessFailure = (error: any) => {
  return {
    type: ActionTypes.VALIDATE_USER_BUSINESS_FAILURE,
    payload: error,
  };
};



/**
 * Otp Register
 * @param state
 * @param action
 */
export const otpLogin = (params: any) => {
  return {
    type: ActionTypes.OTP_LOGIN,
    payload: params,
  };
};

export const otpLoginSuccess = (response: any) => {
  return {
    type: ActionTypes.OTP_LOGIN_SUCCESS,
    payload: response,
  };
};

export const otpLoginFailure = (error: any) => {
  return {
    type: ActionTypes.OTP_LOGIN_FAILURE,
    payload: error,
  };
};


export const setRegisteredMobileNumber = (params: any) => {
  return {
    type: ActionTypes.SET_REGISTER_MOBILE_NUMBER,
    payload: params,
  };
};




/**
* get Dashboard Details
*/


export const getDashboard = (params: any) => {
  return {
    type: ActionTypes.GET_DASHBOARD,
    payload: params,
  };
};
export const getDashboardSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_DASHBOARD_SUCCESS,
    payload: response,
  };
};
export const getDashboardFailure = (error: any) => {
  return {
    type: ActionTypes.GET_DASHBOARD_FAILURE,
    payload: error,
  };
};

/**
*add Employee tags
*/

export const addEmployee = (params: any) => {
  return {
    type: ActionTypes.ADD_EMPLOYEE,
    payload: params,
  };
};

export const addEmployeeSuccess = (response: any) => {

  return {
    type: ActionTypes.ADD_EMPLOYEE_SUCCESS,
    payload: response,

  };
};

export const addEmployeeFailure = (error: any) => {

  return {
    type: ActionTypes.ADD_EMPLOYEE_FAILURE,
    payload: error,
  };
};

/**
 * Add employees photo
 */

export const addUpdateEmployeePhoto = (params: any) => {

  return {
    type: ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO,
    payload: params,
  };
};

export const addUpdateEmployeePhotoSuccess = (response: any) => {
  return {
    type: ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO_SUCCESS,
    payload: response,
  };
};

export const addUpdateEmployeePhotoFailure = (error: any) => {

  return {
    type: ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO_FAILURE,
    payload: error,
  };
};

/**
 *get Employee 
 */

export const getEmployees = (params: any) => {

  return {
    type: ActionTypes.GET_EMPLOYEES,
    payload: params,
  };
};

export const getEmployeesSuccess = (response: any) => {

  return {
    type: ActionTypes.GET_EMPLOYEES_SUCCESS,
    payload: response,
  };
};

export const getEmployeesFailure = (error: any) => {
  return {
    type: ActionTypes.GET_EMPLOYEES_FAILURE,
    payload: error,
  };
};

//get employee details

export const getEmployeeDetails = (params) => {
  return {
    type: ActionTypes.FETCH_EMPLOYEE_DETAILS,
    payload: params,
  };
};

export const getEmployeeDetailsSuccess = (response) => {
  return {
    type: ActionTypes.FETCH_EMPLOYEE_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getEmployeeDetailsFailure = (error) => {
  return {
    type: ActionTypes.FETCH_EMPLOYEE_DETAILS_FAILURE,
    payload: error,
  };
};

/**
* setting selected employee item
*/

export const settingSelectedEmployeeItem = (value) => {
  console.log("value===========>", value);

  return {
    type: ActionTypes.SETTING_SELECTED_EMPLOYEE_ITEM,
    payload: value,
  };
};

//Add client

export const addClient = (params) => {
  return {
    type: ActionTypes.ADD_CLIENT,
    payload: params,
  };
};

export const addClientSuccess = (response) => {
  return {
    type: ActionTypes.ADD_CLIENT_SUCCESS,
    payload: response,
  };
};

export const addClientFailure = (error) => {
  return {
    type: ActionTypes.ADD_CLIENT_FAILURE,
    payload: error,
  };
};

//Get clients

export const getClients = (params) => {
  return {
    type: ActionTypes.GET_CLIENTS,
    payload: params,
  };
};

export const getClientsSuccess = (response) => {
  return {
    type: ActionTypes.GET_CLIENTS_SUCCESS,
    payload: response,
  };
};

export const getClientsFailure = (error) => {
  return {
    type: ActionTypes.GET_CLIENTS_FAILURE,
    payload: error,
  };
};


//Add campaign

export const addCampaign = (params) => {
  return {
    type: ActionTypes.ADD_CAMPAIGN,
    payload: params,
  };
};

export const addCampaignSuccess = (response) => {
  return {
    type: ActionTypes.ADD_CAMPAIGN_SUCCESS,
    payload: response,
  };
};

export const addCampaignFailure = (error) => {
  return {
    type: ActionTypes.ADD_CAMPAIGN_FAILURE,
    payload: error,
  };
};

//Get clients

export const getCampaigns = (params) => {
  return {
    type: ActionTypes.GET_CAMPAIGNS,
    payload: params,
  };
};

export const getCampaignsSuccess = (response) => {
  return {
    type: ActionTypes.GET_CAMPAIGNS_SUCCESS,
    payload: response,
  };
};

export const getCampaignsFailure = (error) => {
  return {
    type: ActionTypes.GET_CAMPAIGNS_FAILURE,
    payload: error,
  };
};


//get products

export const getProducts = (params) => {
  return {
    type: ActionTypes.GET_PRODUCTS,
    payload: params,
  };
};

export const getProductsSuccess = (response) => {
  return {
    type: ActionTypes.GET_PRODUCTS_SUCCESS,
    payload: response,
  };
};

export const getProductsFailure = (error) => {
  return {
    type: ActionTypes.GET_PRODUCTS_FAILURE,
    payload: error,
  };
};


//bulk upload client info

export const bulkUploadClientInfo = (params) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENT_INFO,
    payload: params,
  };
};

export const bulkUploadClientInfoSuccess = (response) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENT_INFO_SUCCESS,
    payload: response,
  };
};

export const bulkUploadClientInfoFailure = (error) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENT_INFO_FAILURE,
    payload: error,
  };
};

//Get client template

export const getClientTemplate = (params) => {
  return {
    type: ActionTypes.GET_CLIENT_TEMPLATE,
    payload: params,
  };
};

export const getClientTemplateSuccess = (response) => {
  return {
    type: ActionTypes.GET_CLIENT_TEMPLATE_SUCCESS,
    payload: response,
  };
};

export const getClientTemplateFailure = (error) => {
  return {
    type: ActionTypes.GET_CLIENT_TEMPLATE_FAILURE,
    payload: error,
  };
};

//setting current campaign details

export const settingCurrentCampaignDetails = (value) => {
  console.log("ssettingsss=========>", value);

  return {
    type: ActionTypes.SETTING_CURRENT_CAMPAIGN_DETAILS,
    payload: value,
  };
};

//Initiate campaign call

export const initiateCampaignCall = (params) => {
  console.log(params,"pppppppppppp")
  return {
    type: ActionTypes.INITIATE_CAMPAIGN_CALL,
    payload: params,
  };
};

export const initiateCampaignCallSuccess = (response) => {
  console.log(response,"rrrrrsssssssss")
  
  return {
    type: ActionTypes.INITIATE_CAMPAIGN_CALL_SUCCESS,
    payload: response,
  };
};

export const initiateCampaignCallFailure = (error) => {
  console.log(error,"eeeeerrrrrrrrrr[[[[[")
  return {
    type: ActionTypes.INITIATE_CAMPAIGN_CALL_FAILURE,
    payload: error,
  };
};

//Mark end call

export const markEndCall = (params) => {
  return {
    type: ActionTypes.MARK_END_CALL,
    payload: params,
  };
};

export const markEndCallSuccess = (response) => {
  return {
    type: ActionTypes.MARK_END_CALL_SUCCESS,
    payload: response,
  };
};

export const markEndCallFailure = (error) => {
  return {
    type: ActionTypes.MARK_END_CALL_FAILURE,
    payload: error,
  };
};

//Is campaign added

export const isCampaignAdded = (value) => {
  console.log("valueeee", value);

  return {
    type: ActionTypes.IS_CAMPAIGN_ADDED,
    payload: value,
  };
};




//get agent trait 

export const fetchAgentTrait = (params) => {
  return {
    type: ActionTypes.FETCH_AGENT_TRAIT,
    payload: params,
  };
};

export const fetchAgentTraitSuccess = (response) => {
  return {
    type: ActionTypes.FETCH_AGENT_TRAIT_SUCCESS,
    payload: response,
  };
};

export const fetchAgentTraitFailure = (error) => {
  return {
    type: ActionTypes.FETCH_AGENT_TRAIT_FAILURE,
    payload: error,
  };
};

//get chat conversation

export const getChatConversation = (params) => {
  return {
    type: ActionTypes.GET_CHAT_CONVERSATION,
    payload: params,
  };
};

export const getChatConversationSuccess = (response) => {
  return {
    type: ActionTypes.GET_CHAT_CONVERSATION_SUCCESS,
    payload: response,
  };
};

export const getChatConversationFailure = (error) => {
  return {
    type: ActionTypes.GET_CHAT_CONVERSATION_FAILURE,
    payload: error,
  };
};

//get PROSPECT SHARED DETAILS generic

export const getProspectSharedDetails = (params) => {
  console.log('calledd111');

  return {
    type: ActionTypes.GET_PROSPECT_SHARED_DETAILS,
    payload: params,
  };
};

export const getProspectSharedDetailsSuccess = (response) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getProspectSharedDetailsFailure = (error) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_DETAILS_FAILURE,
    payload: error,
  };
};



//Get agent shared info generic

export const getAgentSharedInfoGeneric = (params) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GENERIC,
    payload: params,
  };
};

export const getAgentSharedInfoGenericSuccess = (response) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GENERIC_SUCCESS,
    payload: response,
  };
};

export const getAgentSharedInfoGenericFailure = (error) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GENERIC_FAILURE,
    payload: error,
  };
}; 

//Get PROSPECT shared info group

export const getProspectSharedInfoGroup = (params) => {
  console.log("calledd222");

  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP,
    payload: params,
  };
};

export const getProspectSharedInfoGroupSuccess = (response) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP_SUCCESS,
    payload: response,
  };
};

export const getProspectSharedInfoGroupFailure = (error) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP_FAILURE,
    payload: error,
  };
};

//Get prospect shared info item

export const getProspectSharedInfoItem = (params) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM,
    payload: params,
  };
};

export const getProspectSharedInfoItemSuccess = (response) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM_SUCCESS,
    payload: response,
  };
};

export const getProspectSharedInfoItemFailure = (error) => {
  return {
    type: ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM_FAILURE,
    payload: error,
  };
};


//Get Agent shared info group

export const getAgentSharedInfoGroup = (params) => {
  console.log("calledd222");

  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GROUP,
    payload: params,
  };
};

export const getAgentSharedInfoGroupSuccess = (response) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GROUP_SUCCESS,
    payload: response,
  };
};

export const getAgentSharedInfoGroupFailure = (error) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_GROUP_FAILURE,
    payload: error,
  };
};

//Get Agent shared info item

export const getAgentSharedInfoItem = (params) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_ITEM,
    payload: params,
  };
};

export const getAgentSharedInfoItemSuccess = (response) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_ITEM_SUCCESS,
    payload: response,
  };
};

export const getAgentSharedInfoItemFailure = (error) => {
  return {
    type: ActionTypes.GET_AGENT_SHARED_INFO_ITEM_FAILURE,
    payload: error,
  };
};

//Setting call notes

export const settingCallNotes = (value) => {
  console.log("<<<<<<<<,",value);
  
  return {
    type: ActionTypes.CALL_NOTES,
    payload: value,
  };
};

//setting selected call details

export const settingSelectedCallDetails = (value) => {

  
  return {
    type: ActionTypes.SELECTED_CALL_DETAILS,
    payload: value,
  };
};


//I DO IT REGISTER COMPANY
export const registerCompany = (params) => {
  return {
    type: ActionTypes.REGISTER_COMPANY,
    payload: params,
  };
};

export const registerCompanySuccess = (response) => {
  return {
    type: ActionTypes.REGISTER_COMPANY_SUCCESS,
    payload: response,
  };
};

export const registerCompanyFailure = (error) => {
  console.log(error,'eeeerrrrrr')
  return {
    type: ActionTypes.REGISTER_COMPANY_FAILURE,
    payload: error,
  };
};


//GET ALL BRANCHES
export const getAllBranches = (params) => {

  return {
    type: ActionTypes.GET_ALL_BRANCHES,
    payload: params,
  };
};

export const getAllBranchesSuccess = (response) => {
 
  return {
    type: ActionTypes.GET_ALL_BRANCHES_SUCCESS,
    payload: response,
  };
};

export const getAllBranchesFailure = (error) => {
  return {
    type: ActionTypes.GET_ALL_BRANCHES_FAILURE,
    payload: error,
  };
};

//GET COMPANY BRANCHES

export const getCompanyBranchesDetails = (params) => {
  return {
    type: ActionTypes.GET_COMPANY_BRANCH_DETAILS,
    payload: params,
  };
};

export const getCompanyBranchesDetailsSuccess = (response) => {
  return {
    type: ActionTypes.GET_COMPANY_BRANCH_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getCompanyBranchesDetailsFailure = (error) => {
  return {
    type: ActionTypes.GET_COMPANY_BRANCH_DETAILS_FAILURE,
    payload: error,
  };
};

//GET DESIGNATION
export const  getDesignations= (params) => {
  return {
    type: ActionTypes.GET_DESIGNATIONS,
    payload: params,
  };
};

export const getDesignationsSuccess = (response) => {
  return {
    type: ActionTypes.GET_DESIGNATIONS_SUCCESS,
    payload: response,
  };
};

export const getDesignationsFailure = (error) => {
  return {
    type: ActionTypes.GET_DESIGNATIONS_FAILURE,
    payload: error,
  };
};

//ADD DESIGNATION
export const  addDesignations= (params) => {
  return {
    type: ActionTypes.ADD_DESIGNATIONS,
    payload: params,
  };
};

export const addDesignationsSuccess = (response) => {
  return {
    type: ActionTypes.ADD_DESIGNATIONS_SUCCESS,
    payload: response,
  };
};

export const addDesignationsFailure = (error) => {
  return {
    type: ActionTypes.ADD_DESIGNATIONS_FAILURE,
    payload: error,
  };
};


export const bulkUploadClients = (params) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENTS,
    payload: params,
  };
};

export const bulkUploadClientsSuccess = (response) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENTS_SUCCESS,
    payload: response,
  };
};

export const bulkUploadClientsFailure = (error) => {
  return {
    type: ActionTypes.BULK_UPLOAD_CLIENTS_FAILURE,
    payload: error,
  };
};




export const clientsData = (response: any) => {
  console.log(response,"response")
  return {
    type: ActionTypes.CLIENTS_DATA,
    payload: response,
  };
};