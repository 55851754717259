/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import { icons, image } from '@Assets';
import { Image, Button, Input, Divider, Badge, Card } from '@Components';
import { translate } from "@I18n";
import { Link } from 'react-router-dom';
import { Container, Row, Col, CardBody, UncontrolledTooltip } from 'reactstrap';




function Form() {
    
  return (
    <div style={{ backgroundColor: '#f0edff' }}>

      <section className="py-5 pb-9 " >
        <Container fluid>
          <Row className="justify-content-center text-center ">
            <Col md="11">
              <h2 className="display-3 ">
                Revolutionizing Sales with AI-Driven Call Optimization
              </h2>
              <p className="lead ">
                At Convolution X, we are committed to revolutionizing the way businesses interact with their prospects. This app is experts in developing a state-of-the-art system that leverages AI-driven analytics to optimize call interactions and increase conversion rates. With a focus on personalized communication strategies, we empower agents to build meaningful connections with prospects and drive success.
              </p>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section-lg pt-lg-0 mt--8" >
        <Container>
          <Row className="justify-content-center">
            <h2 className="display-4 ">
              SalesXpert Suite
            </h2>
            <Col lg="12 pt-5">
              <Row className='row-grid justify-content-center align-item-center'>

                <Col lg="3"

                >
                  <div className="card" style={{ height: '380px', backgroundColor: '#f8f8f8' }}

                  >
                    <CardBody className="py-3 ">
                      <div className=' d-flex justify-content-center '>
                        {/* <i className=" bi bi-telephone-outbound-fill icon icon-shape bg-gradient-info text-white rounded-circle mb-4"></i> */}
                        <Image src={icons.call} height={30} width={30} className='icon icon-shape bg-gradient-info text-white rounded-circle mb-4' />
                      </div>
                      <h4 className="h2 text-black text-center ">
                        Call module
                      </h4>
                      <p className=" text-black mt-3">
                        Our call module offers two powerful features - Direct Call and Phone to Phone, enabling agents to engage prospects efficiently and analyze conversations for valuable insights.

                      </p>
                    </CardBody>
                  </div>
                </Col>

                <Col lg="3">
                  <div className="card" style={{ height: '380px', backgroundColor: '#f8f8f8' }}>
                    <CardBody className="py-3">
                      <div className=" d-flex justify-content-center">
                        <Image src={icons.Analatic} height={30} width={30} className='icon icon-shape bg-gradient-success text-white rounded-circle mb-4' />
                      </div>
                      <h4 className="h2 text-black text-center">
                        Analytics Report
                      </h4>
                      <p className=" text-black mt-3">
                        Uncover prospects' emotions, traits, and expectations with our in-depth analytics. Empower your agents with actionable recommendations and strategies to increase conversion rates.

                      </p>
                    </CardBody>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="card" style={{ height: '380px', backgroundColor: '#f8f8f8' }}>
                    <CardBody className="py-3">
                      <div className=" d-flex justify-content-center">

                        <Image src={icons.camping} height={30} width={30} className='icon icon-shape bg-gradient-warning text-white rounded-circle mb-4' />
                      </div>
                      <h4 className="h2 text-black mx--2 text-center">
                        Campaigns Module
                      </h4>
                      <p className=" text-black mt-3">
                        Create compelling campaigns and seamlessly add clients to targeted marketing efforts. Monitor and optimize call interactions within campaigns for maximum impact.
                      </p>
                    </CardBody>
                  </div>
                </Col>
                <Col lg="3">
                  <div className="card" style={{ height: '380px', backgroundColor: '#f8f8f8' }}>
                    <CardBody className="py-3">
                      <div className=" d-flex justify-content-center ">
                        <Image src={icons.Agents} height={30} width={30} className=' icon icon-shape bg-gradient-pink text-white rounded-circle mb-4  ' />
                      </div>
                      <h4 className="h2 text-black text-center">
                        Agent Module
                      </h4>
                      <p className=" text-black mt-3">
                        Centralize agent details, track performance, and optimize your workforce for enhanced productivity.
                      </p>
                    </CardBody>
                  </div>
                </Col>

              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py4 " >
        <Container>
          <Row className="row-grid align-items-center pt-5 ">
            <Col className="order-md-2" md="6" style={{marginBottom:'70px'}}>
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage1}
                width={'350px'}
              />
            </Col>
            
            <Col className="order-md-1 test1 scrolled" md="6">
              <div className="pl-md-3 ">
                <h1 className=''>Prospect's Perception</h1>
                <p className=''>
                  Convolution X's Prospect's Perception analysis quantifies emotions and perceptions on a scale of 0 to 100, 
                  revealing positive sentiments like excitement, trust, curiosity, satisfaction, and interest. Utilizing advanced sentiment analysis algorithms, 
                  it provides valuable insights into how prospects perceive a product, optimizing engagement and conversion rates.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className='py4'>
        <Container>
          <Row className="row-grid align-items-center ">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage2}
                // style={{marginBottom:'100px'}}
              />
            </Col>
            <Col md="6 test2">
              <div className="pr-md-5 ">
                <h1>Prospect's Traits</h1>
                <p className=''>
                  Convolution X's sophisticated analysis techniques delve into linguistic and psychological aspects, allowing the identification and categorization of prospects' personality traits. Armed with this valuable knowledge, agents can tailor their approach to match individual preferences and behaviors, leading to more impactful and personalized interactions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid"
              src={icons.conImage3}
              />
            </Col>
            <Col className="order-md-1 test1" md="6">
              <div className="pr-md-5">
                <h1>Agent's Traits </h1>
                <p>
                  Convolution X uses linguistic and psychological analysis to identify agents' personality traits, optimizing their performance and communication strategies. Understanding these traits is crucial for building rapport with prospects and achieving higher conversion rates. Valuable insights enable effective adaptation of strategies for successful interactions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage4}
              />
            </Col>
            <Col md="6 test2">
              <div className="pr-md-5 ">
                <h1>Empowering Agents for Higher Conversions</h1>
                <p>
                  The Next Step Approach by Convolution X provides agents with personalized recommendations to elevate the conversion ratio and progress prospects along the sales funnel. With specific steps and estimated contribution percentages, agents can strategically align their efforts for maximum impact and efficiency. Empowered by customized insights, agents optimize their interactions, increasing the chances of successful conversions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col className="order-md-2" md="6">
              <img
                alt="..."
                className="img-fluid"
              src={icons.conImage5}
              />
            </Col>
            <Col className="order-md-1 test1" md="6">
              <div className="pr-md-5">
                <h1>Strategic Communication Enhancement for Maximum Impact</h1>
                <p>
                  Convolution X identifies areas for improvement in agents' communication skills and offers insights inspired by influential figures like Steve Jobs and Robert Greene, empowering agents to elevate their impact on prospects for improved conversion rates.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage6}
                width={'400px'}
                
              />
            </Col>
            <Col md="6 test2">
              <div className="pr-md-5">
                <h1>Exceeding Expectations for Enhanced Conversions</h1>
                <p>
                  Convolution X's Expectation Level Approach identifies crucial metrics and prospects' expectations from conversation data, enabling agents to surpass these expectations strategically. By delivering exceptional experiences, agents build trust and credibility, ultimately leading to higher conversion rates.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6" className='order-md-2'>
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage7}
              />
            </Col>
            <Col md="6 test1">
              <div className="pr-md-5">
                <h1>Maximizing Prospect Engagement</h1>
                <p>
                  Convolution X identifies top areas of interest and triggers for positive responses in prospects. Agents can effectively leverage these insights to reignite enthusiasm and increase re-engagement success, driving optimal conversions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6">
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage8}
                width={'400px'}
              />
            </Col>
            <Col md="6 test2">
              <div className="pr-md-5">
                <h1>Empowering Targeted Marketing</h1>
                <p>
                  Agents can create compelling campaigns, seamlessly add clients, and initiate recorded calls within campaigns for valuable insights, optimizing marketing strategies for increased engagement and conversions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="py-4">
        <Container>
          <Row className="row-grid align-items-center">
            <Col md="6" className='order-md-2'>
              <img
                alt="..."
                className="img-fluid"
                src={icons.conImage9}
                width={'450px'}
              />
            </Col>
            <Col md="6 test1">
              <div className="pr-md-5">
                <h1>Empowering Efficient Agent Management</h1>
                <p>
                  Administrators can seamlessly add new agents to Convolution X, capturing comprehensive details for streamlined management. Real-time monitoring empowers administrators to optimize agent performance and make data-driven decisions for increased efficiency..
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export { Form }