import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from "react-redux";
import { HOME_ROUTES, ROUTES } from '@Routes'
import { Sidebar } from '@Components'
import { icons } from '@Assets'
import { FCM_TOKEN, getDeviceInfo } from '@Utils'
import { ClientsList, PushNotification } from "@Modules";
import { getCampaigns, getClients, isCampaignAdded, settingCurrentCampaignDetails } from '@Redux';

type RequireAuthProps = {
    children: React.ReactNode;
}

export const RequireAuth = ({ children }: RequireAuthProps) => {
    const dispatch = useDispatch()

    const [sideNavOpen, setSideNavOpen] = useState(true);
    const mainContentRef = React.useRef<HTMLDivElement | null>(null);
    const [sideNavRoutes, setSideNavRoutes] = useState(HOME_ROUTES)
    const location = useLocation();


    const fcmToken = localStorage.getItem(FCM_TOKEN)

    const { loginDetails, token, } = useSelector(
        (state: any) => state.AppReducer
    );

    const { isCampaignAdd, campaignsList } = useSelector((state: any) => state.AuthReducer);



    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement!.scrollTop = 0;
        if (mainContentRef.current) {
            mainContentRef.current.scrollTop = 0;
        }
    }, [location]);

    useEffect(() => {
        if (loginDetails && loginDetails.isLoggedIn && token) {
            getPushNotification()
        }
    }, [token])

    useEffect(() => {
        getCampaignsHandler()
    }, [])

    useEffect(() => {
        if (isCampaignAdd)
            getCampaignsHandler()
    }, [isCampaignAdd])





    const getCampaignsHandler = () => {
        const params = {

        }
        dispatch(
            getCampaigns({
                params,
                onSuccess: (response: any) => () => {
                    console.log("response===============", response);
                    dispatch(isCampaignAdded(false))
                    settingSideNavbarPath(response)
                    if (response?.length > 0)
                        dispatch(settingCurrentCampaignDetails(response[0]))
                },
                onError: (error) => () => {
                },
            })
        )
    }


    function getPushNotification() {
        const params = {
            device_model: getDeviceInfo()?.model,
            device_platform: getDeviceInfo()?.platform,
            device_brand: getDeviceInfo()?.brand,
            device_token: fcmToken
        }

        // dispatch(addPushNotification({
        //     params,
        //     onSuccess: () => () => {
        //     },
        //     onError: () => () => {
        //     }
        // }))
    }



    if (!loginDetails?.isLoggedIn) {
        return <Navigate to={ROUTES['auth-module'].login} state={{ path: location.pathname }} />
    }


    const toggleSideNav = () => {
        if (document.body.classList.contains("g-sidenav-pinned")) {
            document.body.classList.remove("g-sidenav-pinned");
            document.body.classList.add("g-sidenav-hidden");
        } else {
            document.body.classList.add("g-sidenav-pinned");
            document.body.classList.remove("g-sidenav-hidden");
        }
        setSideNavOpen(!sideNavOpen);
    };


    const settingSideNavbarPath = (data) => {
        let updatedRoutes: any = [...HOME_ROUTES]

        if (data && data.length > 0) {

            const apiRoutes = data?.map((item: any) => {

                return {
                    path: "/clients-list",
                    name: item.name,
                    miniName: item?.name?.substring(0, 2).toUpperCase(),
                    component: <ClientsList />,
                    layout: "",
                    id: item.id
                }
            })

            updatedRoutes[1] = {
                collapse: true,
                state: "campaignCollapse",
                name: "Campaigns",
                icon: "bi bi-megaphone",
                parentNav: 'Campaigns',
                views: [...apiRoutes],
            }
            setSideNavRoutes(updatedRoutes)
        }
        else {
            updatedRoutes[1] = {
                collapse: true,
                state: "campaignCollapse",
                name: "Campaigns",
                icon: "bi bi-megaphone",
                parentNav: 'Campaigns',
                views: [],
            }
        }

    }

    return (
        <>
            <Sidebar
                routes={sideNavRoutes}
                toggleSideNav={toggleSideNav}
                sideNavOpen={sideNavOpen}
                logo={{
                    innerLink: "/",
                    imgSrc: icons.logo,
                    imgAlt: "...",
                }}
            />
            <div className='main-content' ref={mainContentRef}>
                <PushNotification />
                {children}
            </div>


            {sideNavOpen ? (
                <div className={"backdrop d-xl-none"} onClick={toggleSideNav} />
            ) : null}
        </>
    )
}

export default RequireAuth;
