import { ProgressCircleChartProps } from './interface'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { removePercentege } from '@Utils'


function ProgressCircleChart({ progress = 50, width=150, height=150, textColor = 'black', pathColor = '#854df3' }: ProgressCircleChartProps) {
    return (

        <div className='container d-flex justify-content-center align-items-center' style={{ width: width, height: height }}>
            <CircularProgressbar value={parseInt(removePercentege(progress))} text={`${removePercentege(progress)}%`}
                styles={buildStyles({
                    pathColor: pathColor,
                    textColor: textColor,
                    trailColor: '#d6d6d6',
                    backgroundColor: '#3e98c7',
                
                })}

            />
        </div>

    )
}
export { ProgressCircleChart }