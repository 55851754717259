import { Card, CommonTable, NoDataFound } from '@Components';
import React from 'react'
import { useSelector } from 'react-redux';

function ProspectSharedInfoGeneric() {


    const { prospectSharedDetails } = useSelector((state: any) => state.AuthReducer);

    console.log("prospectSharedDetails", prospectSharedDetails);


    const normalizedTableData = (data: any, type: string) => {
        return data && data.length > 0 && data?.map((el: any) => {

            console.log("vvvvvvvvvvv", el);


            const titleHead = type
            let formattedString = titleHead.replace(/([A-Z])/g, ' $1');

            const { informationValue, informationkey, title, value } = el
            return {
                'Key': title ? title : '-',
                'value': value ? value : '-'
            }
        }
        )
    }


    return (

        <div>

            {
                prospectSharedDetails && prospectSharedDetails?.groupItems && prospectSharedDetails?.groupItems.length > 0 ? prospectSharedDetails?.groupItems?.map((key, index) => {

                    if (key.collections?.length > 0) {
                        return (
                            <Card className="mr--1">

                                <div className='overflow-auto scroll-hide' style={{ marginLeft: '-24px', marginRight: '-24px' }}>
                                    <h3 className='text-primary ml-4'>{key.type}</h3>

                                    <CommonTable
                                        isPagination
                                        tableDataSet={key.collections}
                                        displayDataSet={normalizedTableData(key.collections, key.type)}

                                    />
                                </div>
                            </Card>

                        )
                    }
                }) :
                    <NoDataFound />

            }

        </div>
    )
}

export { ProspectSharedInfoGeneric }