import React, { useEffect, useState } from "react";
import { OverallProgressCardProps } from "./interface";
import { Card, ProgressCircleChart } from "@Components";
import { Progress } from "reactstrap";
import { convertToUpperCase, removePercentege } from '@Utils'
import { useDynamicHeight } from "@Hooks";



function OverallProgressCard({ progress, data, circleProgressColor, lineProgressColor = 'primary', heading, onItemClick, isShowAsOnclick = false, isShowTwoBar = false, isShowPlusOnPercentage = false }: OverallProgressCardProps) {

    const [active, setActive] = useState(0)

    console.log("data==============", data);


    const dynamicHeight: any = useDynamicHeight()


    return (
        <div className='overflow-auto scroll-hidden'>
            <Card className={'mx--2 overflow-auto scroll-hidden'} style={{ height: dynamicHeight.dynamicHeight - 150 }}>
                {progress && <h2 className='text-center'>Overall Progress</h2>}
                {progress && <ProgressCircleChart progress={progress ? progress : '0'} pathColor={circleProgressColor} />}
                {data && <h3>{heading || 'Breakdowns'} </h3>}
                {
                    data && data.length > 0 && data.map((item, index) => {

                        const { emotion_perception, approach_overview, relative_percent, trait, percent, relative_increase_in_percent, expectation, increasedPercent, percentage } = item || {}

                        const progressBarStyle1 = {
                            width: `${removePercentege(relative_percent) || removePercentege(relative_increase_in_percent) ||
                                removePercentege(percent) || removePercentege(increasedPercent) || removePercentege(percentage)}%`,
                            backgroundColor: '#FCCF12',
                            borderBottomRightRadius: '10px',
                            borderTopRightRadius: '10px'
                        };

                        const progressBarStyle2 = {
                            width: `${progress ? +removePercentege(progress) : 0}%`,
                            backgroundColor: 'bg-gradient-primary',
                            borderRadius: '7px'
                        };


                        const percentageMax = {
                            progress1: +removePercentege(relative_percent) || +removePercentege(relative_increase_in_percent) ||
                                +removePercentege(percent) || +removePercentege(increasedPercent) || +removePercentege(percentage),
                            progress2: progress
                        }

                        console.log(isShowTwoBar + 'isShowTwoBar');


                        return (
                            <>
                                { (relative_percent > 0 || relative_increase_in_percent > 0 || percent > 0 || increasedPercent > 0 || percentage > 0) &&
                                    <div className={`${isShowAsOnclick && active === index ? 'bg-grey' : ''} mx--4 px-4 py-2`}>
                                    <div className={`pointer`} onClick={() => {
                                        if (isShowAsOnclick && onItemClick) {
                                            onItemClick(item)
                                        }
                                        setActive(index)
                                    }}>
                                        <h5 className="font-weight-600">{convertToUpperCase(emotion_perception || approach_overview || trait || expectation)}</h5>

                                        <div className='row py-2'>

                                            <div className='col'>
                                                <div className='mt--2 font-weight-900 h5 text-black mr-2 text-right'>
                                                    {`${isShowPlusOnPercentage ? '+' : ''} ${removePercentege(relative_percent) || removePercentege(relative_increase_in_percent) || removePercentege(percent) || removePercentege(increasedPercent) || removePercentege(percentage) || 0} %`}
                                                </div>
                                                {isShowTwoBar ?
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar" style={progressBarStyle2} aria-valuemin={0} aria-valuemax={percentageMax.progress2 + percentageMax.progress1}></div>
                                                        <div className="progress-bar" role="progressbar" style={progressBarStyle1} aria-valuemin={0} aria-valuemax={percentageMax.progress2 + percentageMax.progress1}></div>

                                                    </div> :
                                                    <Progress
                                                        className="progress-md mb-0"
                                                        max="100"
                                                        value={removePercentege(relative_percent) || removePercentege(relative_increase_in_percent) || removePercentege(percent) || removePercentege(percentage) || removePercentege(increasedPercent) || 0}
                                                        color={lineProgressColor}
                                                    />}
                                            </div>

                                        </div>

                                    </div>
                                </div>}
                            </>
                        )
                    })
                }
            </Card>
        </div >

    )
}
export { OverallProgressCard }