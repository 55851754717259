import { icons } from "@Assets";

export const DEFAULT_TASK_GROUP = { id: 'ALL', Photo: null, code: "ALL" }

export const ERRORS = {
  SOMETHING_WENT_WRONG: 'Something went wrong, please try again later',
  INVALID_REQUEST: 'Invalid Request',
};

export const USER_TOKEN = 'USER_TOKEN'

export const FCM_TOKEN = 'FCM_TOKEN'

export const GENDER_LIST = [
  { id: 'M', text: 'Male' },
  { id: 'F', text: 'Female' },
  { id: 'O', text: 'Others' },
];

export const BLOOD_GROUP_LIST = [
  { id: 'A+ve', name: 'A+ve', text: 'A+ve' },
  { id: 'B+ve', name: 'B+ve', text: 'B+ve' },
  { id: 'AB+ve', name: 'AB+ve', text: 'AB+ve' },
  { id: 'O+ve', name: 'O+ve', text: 'O+ve' },
  { id: 'A-ve', name: 'A-ve', text: 'A-ve' },
  { id: 'B-ve', name: 'B-ve', text: 'B-ve' },
  { id: 'AB-ve', name: 'AB-ve', text: 'AB-ve' },
  { id: 'O-ve', name: 'O-ve', text: 'O-ve' },
];

export const DESIGNATION_LIST = [
  { id: 'true', text: 'Business Owner' },
  { id: 'false', text: 'Management' },
];

export const LANGUAGES = [
  { id: '1', text: 'English', value: 'en' },
  { id: '2', text: 'Tamil', value: 'ta' },
];

export const type = [
  { id: '1', text: 'External', value: 'Ext' },
  { id: '2', text: 'Internal', value: 'Int' },
]

export const DEFAULT_LANGUAGE = LANGUAGES[0]


export function isExist(val: any) {
  return val ? val : ''
}

export const LANGUAGE_ENGLISH = 'EN';
export const LANGUAGE_TAMIL = 'TA';


export const BUSINESS = 'business';
export const ERROR_MESSAGE_ALERT = 'A';
export const ERROR_MESSAGE_SHORT_TOAST = 'S';
export const ERROR_MESSAGE_LONG_TOAST = 'L';
export const ERROR_MESSAGE_MEDIUM_TOAST = 'M';

export const OTP_RESEND_DEFAULT_TIME = 59;

/* Event types Status Code */

export const MEA = 'MEA'
export const TGU = 'TGU'
export const RGU = 'RGU'
export const TEM = 'TEM'
export const EVS = 'EVS'
export const RTS = 'RTS'
export const ETA = 'ETA'

export const TASK_FILTER_LIST = [
  { id: 'ALL', text: 'All' },
  { id: 'created_by', text: 'Created by me' },
  { id: 'assigned_to', text: 'Assigned to me' },
  { id: 'tagged_to', text: 'Tagged' }
];


export const TASK_STATUS_LIST = [
  { id: 'ALL', text: 'All', },
  { id: 'RAI', text: 'Raised', color: "gray" },
  { id: 'INP', text: 'In-Progress', color: "yellow" },
  { id: 'CAN', text: 'Cancel', color: "red" },
  { id: "CLS", text: 'Close', color: "red" },
  { id: 'ONH', text: 'On-Hold', color: "black" },
  { id: 'REJ', text: 'Reject', color: "red" }

]


export const TASK_PRIORITY_LIST = [
  { id: 'ALL', text: "All" },
  { id: 1, text: "Lowest", color: "black" },
  { id: 2, text: "Low", color: "black" },
  { id: 3, text: "Medium", color: "orange" },
  { id: 4, text: "High", color: "red" },
  { id: 5, text: "Critical", color: "gray" },
];

export const TICKET_FILTER_LIST = [
  { id: 'ALL', text: 'All' },
  { id: 'created_by', text: 'Created by me' },
  { id: 'assigned_to', text: 'Assigned to me' },
  { id: 'tagged_to', text: 'Tagged' }
];

export const TICKET_STATUS_LIST = [
  { id: 'ALL', text: 'All', },
  { id: 'RAI', text: 'Raised', color: "gray" },
  { id: 'INP', text: 'In-Progress', color: "yellow" },
  { id: 'CAN', text: 'Cancel', color: "red" },
  { id: "CLS", text: 'Close', color: "red" },
  { id: 'ONH', text: 'On-Hold', color: "black" },
  { id: 'REJ', text: 'Reject', color: "red" }

]

export const TICKET_PRIORITY_LIST = [
  { id: 'ALL', text: "All" },
  { id: 1, text: "Lowest", color: "black" },
  { id: 2, text: "Low", color: "black" },
  { id: 3, text: "Medium", color: "orange" },
  { id: 4, text: "High", color: "red" },
  { id: 5, text: "Critical", color: "gray" },
];



export const STATUS_LIST = [
  { id: 'ALL', text: 'All', },
  { id: 'RAI', text: 'Raised', color: "gray" },
  { id: 'INP', text: 'In-Progress', color: "yellow" },
  { id: 'CAN', text: 'Cancel', color: "red" },
  { id: "CLS", text: 'Close', color: "red" },
  { id: 'ONH', text: 'On-Hold', color: "black" },
  { id: 'REJ', text: 'Reject', color: "red" }

]

export const GROUP_STATUS_LIST = [
  { id: 'ALL', text: 'All', },
  { id: 'RAI', text: 'Raised', color: "gray" },
  { id: 'INP', text: 'In-Progress', color: "yellow" },
  { id: 'CAN', text: 'Cancel', color: "red" },
  { id: "CLS", text: 'Close', color: "red" },
  { id: 'ONH', text: 'On-Hold', color: "black" },
  { id: 'REJ', text: 'Reject', color: "red" }

]


export const FILTERED_LIST = [
  { id: 'ALL', text: 'All' },
  { id: 'created_by', text: 'Created by me' },
  { id: 'assigned_to', text: 'Assigned to me' },
  { id: 'tagged_to', text: 'Tagged' }
];



export const PRIORITY_DROPDOWN_LIST = [
  { id: 'ALL', text: "All" },
  { id: 1, text: "Lowest", color: "black" },
  { id: 2, text: "Low", color: "black" },
  { id: 3, text: "Medium", color: "orange" },
  { id: 4, text: "High", color: "red" },
  { id: 5, text: "Critical", color: "gray" },
];
export const PRIORITY = [
  { id: 1, text: "Lowest", color: "black" },
  { id: 2, text: "Low", color: "black" },
  { id: 3, text: "Medium", color: "orange" },
  { id: 4, text: "High", color: "red" },
  { id: 5, text: "Critical", color: "gray" },
];

export const PRIORITY_DROPDOWNICON_LIST = [
  { id: 'ALL', text: "All" },
  { id: 1, text: "Lowest", icon: icons.eye, color: "black" },
  { id: 2, text: "Low", icon: icons.eye, color: "black" },
  { id: 3, text: "Medium", icon: icons.eye, color: "orange" },
  { id: 3, text: "High", icon: icons.eye, color: "red" },
  { id: 3, text: "Critical", icon: icons.eye, color: "gray" }
];


export const COMPANY_TYPE = [
  { id: '', text: "Self" },
]


export const SEARCH_PAGE = 1
export const INITIAL_PAGE = 1


export const TASK_EVENT_ETA = 'ETA'


export const NEXT_STEP_PERSONALIZED = [
  {
    "perception": "Interest in car features",
    "reason": "Arun asked about the car's best features and safety features",
    "reference": [
      "arun: Yes, please. What are some of its best features?",
      "hari: The XYZ Deluxe has a powerful engine that is fuel efficient. It has safety features like adaptive cruise control, blind spot and monitoring."
    ],
    "approaches": [
      {
        "approach_overview": "Provide more information on the car's features and safety",
        "increasedPercent": "10",
        "reason": "Arun showed interest in the car's features and safety",
        "steps": [
          "Provide a detailed list of all the features and safety measures of the car",
          "Explain how these features and safety measures work to ensure a safe and comfortable driving experience",
          "Highlight the benefits of these features and safety measures in comparison to other cars in the market"
        ]
      }
    ]
  },
  {
    "perception": "Interest in special offers",
    "reason": "Arun asked about the warranty and special offers",
    "reference": [
      "arun: What about the warranty and any special offers?",
      "hari: Right now, we have a special offer. If you buy yearly, you will get a free maintenance package for the first year."
    ],
    "approaches": [
      {
        "approach_overview": "Provide more information on the special offers and financing options",
        "increasedPercent": "15",
        "reason": "Arun showed interest in the special offers and financing options",
        "steps": [
          "Provide a detailed list of all the special offers and financing options available",
          "Explain how these offers and financing options work to ensure a cost-effective purchase",
          "Highlight the benefits of these offers and financing options in comparison to other cars in the market"
        ]
      }
    ]
  },
  {
    "perception": "Interest in test driving the car",
    "reason": "Arun agreed to schedule a test drive",
    "reference": [
      "arun: I would like to test it. When and where can I come?",
      "hari: We can schedule your test whenever you are available."
    ],
    "approaches": [
      {
        "approach_overview": "Provide a personalized test drive experience",
        "increasedPercent": "20",
        "reason": "Arun has shown interest in test driving the car",
        "steps": [
          "Schedule a test drive at a convenient time and location for Arun",
          "Provide a personalized test drive experience by highlighting the features and benefits of the car",
          "Address any concerns or questions Arun may have during the test drive",
          "Offer a follow-up test drive or additional information if needed"
        ]
      }
    ]
  },
  {
    "perception": "Interest in price and color options",
    "reason": "Arun asked about the price and color options of the car",
    "reference": [
      "arun: Can you tell me the price and the colors it comes in?",
      "hari: XYZ Deluxe starts at $30,000. It comes in classic colors like black, white, red, and silver."
    ],
    "approaches": [
      {
        "approach_overview": "Provide more information on pricing and color options",
        "increasedPercent": "10",
        "reason": "Arun showed interest in the price and color options of the car",
        "steps": [
          "Provide a detailed breakdown of the pricing and any additional costs associated with the purchase",
          "Highlight the benefits of the pricing and how it compares to other cars in the market",
          "Provide a personalized color consultation to help Arun choose the best color option for his preferences and needs"
        ]
      }
    ]
  },
  {
    "perception": "Interest in financing options",
    "reason": "Hari mentioned good financing options available",
    "reference": [
      "hari: We also have good financing option available."
    ],
    "approaches": [
      {
        "approach_overview": "Provide more information on financing options",
        "increasedPercent": "5",
        "reason": "Hari mentioned good financing options available",
        "steps": [
          "Provide a detailed breakdown of the financing options available",
          "Explain how these financing options work to ensure a cost-effective purchase",
          "Highlight the benefits of these financing options in comparison to other cars in the market"
        ]
      }
    ]
  }
]

