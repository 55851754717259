import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input, DropDown, Button, showToast, Dropzone, Back, Card
} from "@Components";
import {
  GENDER_LIST,
  ADD_USER_RULES,
  validate,
  ifObjectExist,
  getValidateError,
  BLOOD_GROUP_LIST,
  getObjectFromArrayByKey,
  getImageUrl,
  getDropDownDisplayData,
} from "@Utils";

import { useInput, useDropDown, useNavigation, useLoader } from "@Hooks";
import { translate } from "@I18n";
import { addEmployee, fetchDepartments, fetchDesignations, getEmployeeDetails } from "@Redux";


function AddUser() {


  const dispatch = useDispatch();
  const { goBack } = useNavigation();


  const { selectedCompany } = useSelector((state: any) => state.AuthReducer);

  const { selectedEmployeeItem } = useSelector(
    (state: any) => state.AuthReducer
  );

  const { designationData, departmentData } = useSelector(
    (state: any) => state.AppReducer
  );


  console.log("selectedEmployeeItem",selectedEmployeeItem)

  const firstName = useInput("");
  const lastName = useInput("")
  const contactNumber = useInput("");
  const email = useInput("");
  const gender = useDropDown({});
  const bloodGroup = useDropDown({})
  const aadhar = useInput("");
  const employeeIds = useInput('')
  // const department = useDropDown({})
  // const designation = useDropDown({})
  const [photo, setPhoto] = useState("");

  const submitLoader = useLoader(false)

  let imageUrlValue = photo && (photo.toString().includes('https') || photo.toString().includes('http'))


  useEffect(() => {


    const params = {
      branch_id: selectedCompany?.branch_id,
    };

    dispatch(
      fetchDesignations({
        params,
        onSuccess: (response: any) => () => {
        },
        onError: () => () => {
        },
      })
    );
  }, []);


  useEffect(() => {

    const params = {
      branch_id: selectedCompany?.branch_id,
    };

    dispatch(
      fetchDepartments({
        params,
        onSuccess: () => () => {
        },
        onError: () => () => {
        },
      })
    );

    if (selectedEmployeeItem) {
      getEmployeeData()
    }
  }, []);


  const getEmployeeData = () => {

    const params = {
      user_id: selectedEmployeeItem?.id,
    };

    dispatch(
      getEmployeeDetails({
        params,
        onSuccess: (success) => () => {
          prefillEmployeeDetails(success)
        },
        onError: () => () => {
        },
      })
    );
  }

  const prefillEmployeeDetails = (empDetails: any) => {

    firstName.set(empDetails.first_name)
    lastName.set(empDetails.last_name)
    contactNumber.set(empDetails.mobile_number)
    email.set(empDetails.email)
    aadhar.set(empDetails.aadhar_number)
    gender.set(getObjectFromArrayByKey(GENDER_LIST, "id", empDetails.gender))
    bloodGroup.set(getObjectFromArrayByKey(BLOOD_GROUP_LIST, "id", empDetails.blood_group))
    // department.set(getObjectFromArrayByKey(departmentData, "id", empDetails.department_id))
    // designation.set(getObjectFromArrayByKey(designationData, "id", empDetails.designation_id))
    setPhoto(getImageUrl(empDetails.profile_image))

  }

  const submitAddUserHandler = () => {

    const params = {
      first_name: firstName.value,
      ...(lastName.value && { last_name: lastName.value }),
      mobile_number: contactNumber.value,
      email: email.value,
      gender: gender.value?.id,
      ...(!photo?.includes('https') && !photo?.includes('http') && { photo: photo }),
      // department_id: department?.value?.id,
      // designation_id: designation?.value?.id,
      ...(selectedEmployeeItem && { id: selectedEmployeeItem.id }),
      employee_id:employeeIds?.value
    }
    console.log("params", params);


    const validation = validate(ADD_USER_RULES, params);

    if (ifObjectExist(validation)) {

      submitLoader.show()
      dispatch(
        addEmployee({
          params,
          onSuccess: (response: any) => () => {
            submitLoader.hide()
            if (response.success) {
              showToast(response.message, "success");
              // dispatch(settingSelectedEmployeeItem(undefined))
              goBack();
            }
          },
          onError: (error) => () => {
            showToast(error.error_message, "error");
            submitLoader.hide()

          },
        })
      );
    }
    else {
      showToast(getValidateError(validation));
    }
  }



  return (
    <Card className="m-3">
      <div className='col'>
        <div className="row">
          <Back />
          <h3 className="ml-3">{selectedEmployeeItem ? 'Edit ' : 'Add Agent'}</h3>
        </div>
      </div>
      {/* <hr className='mt-3'></hr> */}
      <div className="mt-3">

        <div className="row">
          <div className="col-sm-6">
            <Input
              heading={translate("auth.firstName")}
              placeholder={translate("auth.firstName")}
              value={firstName.value}
              onChange={firstName.onChange}
            />
          </div>
          <div className="col-sm-6">
            <Input
              heading={translate("auth.lastName")}
              placeholder={translate("auth.lastName")}
              value={lastName.value}
              onChange={lastName.onChange}
            />
          </div>

        </div>

        <div className="row">
          <div className="col-sm-6">
            <Input
              type={"number"}
              heading={translate("auth.contactNumber")}
              placeholder={translate("auth.contactNumber")}
              maxLength={10}
              value={contactNumber.value}
              onChange={contactNumber.onChange}
            />
          </div>
          <div className="col-sm-6">
            <Input
              heading={translate("auth.email")}
              value={email.value}
              placeholder={translate("auth.email")}
              onChange={email?.onChange}
            />
          </div>

        </div>

        <div className="row">

          <div className="col-sm-6">
            <DropDown
              heading={translate("auth.gender")}
              placeHolder={translate("auth.gender")!}
              data={GENDER_LIST}
              selected={gender.value}
              onChange={gender.onChange}
            />
          </div>
          <div className='col-sm-6'>
             
               
                    <Input
                      heading={'Employee Id'}
                        placeholder={'Employee Id'}
                        value={employeeIds.value}
                        onChange={employeeIds.onChange}
                    />
          
            </div>

          
          {/* <div className="col-sm-6">
            <DropDown
              heading={'Designation'}
              placeHolder={'Designation'}
              data={getDropDownDisplayData(designationData)}
              selected={designation.value}
              value={designation.value}
              onChange={designation.onChange}
            />
          </div> */}
        </div>
    



        <div className="row">
          {/* <div className="col-sm-6">
            <DropDown
              placeHolder={translate("common.department")!}
              heading={translate("common.department")}
              data={getDropDownDisplayData(departmentData)}
              selected={department.value}
              value={department.value}
              onChange={department.onChange}
            />
          </div> */}

        </div>


        <div >
          <label className={`form-control-label`}>
            {'Photo'}
          </label>
        </div>
        <div className=" pb-2 pt-1">
          <Dropzone
            variant="ICON"
            profile={photo}
            imageVariant={'rounded'}
            icon={photo}
            size="xl"
            onSelect={(image) => {
              let encoded = image.toString().replace(/^data:(.*,)?/, "");
              setPhoto(encoded);
            }}
          />
        </div>
      </div>

      <div className="text-right">
        <Button
          isLoading={submitLoader.loader}
          text={selectedEmployeeItem ? 'Update' : translate("common.submit")}
          onClick={submitAddUserHandler}
        />
      </div>
    </Card>
  );
}
export { AddUser };
