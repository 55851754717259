import { Card, CommonTable, NoDataFound } from '@Components';
import { convertToUpperCase } from '@Utils';
import React from 'react'
import { useSelector } from 'react-redux';

function AgentSharedGroupInformation() {


  const { agentSharedInfoGroup, agentSharedInfoItem } = useSelector((state: any) => state.AuthReducer);

  const normalizedTableData = (data: any, type: string) => {
    return data && data.length > 0 && data?.map((el: any) => {

      const titleHead = type
      let formattedString = titleHead.replace(/([A-Z])/g, ' $1');

      const { informationValue, key, title, value } = el
      return {
        'Key': key ? convertToUpperCase(key) : '-',
        'Value': value ? value : '-'
      }
    }
    )
  }

  return (

    <div className=''

    >

      {
        agentSharedInfoGroup && agentSharedInfoGroup.groupItems && agentSharedInfoGroup?.groupItems.length > 0 ?

          agentSharedInfoGroup?.groupItems.map((el) => {
            return (
              <Card className="mr--1  "
               
              >
                <div className='overflow-auto scroll-hide' style={{ marginLeft: '-24px', marginRight: '-24px' }}>
                  <h3 className='ml-4 text-primary'>{el?.type}</h3>
                  <CommonTable
                    tableDataSet={el?.collections}
                    displayDataSet={normalizedTableData(el?.collections, '')}

                  />
                </div>
              </Card>
            )
          })

          :
          <NoDataFound />
      }

    </div>
  )
}

export { AgentSharedGroupInformation }