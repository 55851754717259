import React, { useState } from 'react'
import Select from 'react-select'
import { InputHeading } from '../InputHeading'
import { AutoSearchProps } from './interfaces'
import { Button } from '@Components';


function AutoSearch({ heading, options, onInputChange, onChange, indexSelect, defaultValue, value, buttonText, classNames = 'rounded-pill px-3 custom-gradient border-0', size, isButton = false, onClick }: AutoSearchProps) {

  const customNoOptionsMessage = () => {
    return <div >
      {isButton ? <div className='text-right'><Button text={buttonText} className={classNames} size={size} onClick={onClick} /></div> : 'No Data Found'}
    </div>;
  };

  return (
    <div>
      <InputHeading heading={heading} />
      <Select
        options={options}
        value={value}
        onInputChange={onInputChange}
        onChange={onChange}
        defaultValue={defaultValue}
        noOptionsMessage={customNoOptionsMessage}
      />
    </div>
  )
}

export { AutoSearch }