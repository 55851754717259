import { Back, Button, Card, DropDown, Input, InputHeading, Modal, MultiSelectDropDown, PhoneNumberInput, showToast, AutoSearch } from '@Components'
import { useDropDown, useInput, useLoader, useModal, useNavigation } from '@Hooks'
import { translate } from '@I18n'
import { addClient, addDesignations, getAllBranches, getCompanyBranchesDetails, getDesignations, getDesignationsSuccess, registerCompany } from '@Redux'
import { GENDER_LIST, getDropDownDisplayData, getDropDownSearchData, getValidateError, ifObjectExist, REGISTER_COMPANY_RULE, REGISTER_DESIGNATION, validate } from '@Utils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

function AddClient() {

    const { clientsList, currentCampaignDetails, campaignsList, branchesData, designations, companyBranchDetails } = useSelector((state: any) => state.AuthReducer);

    const { goBack } = useNavigation();

    const dispatch = useDispatch();
    const [contactNumber, setPhoneNumber] = useState()
    const companyDetailsModal = useModal(false)
    const designationModal = useModal(false)
    const name = useInput('')
    const lastName = useInput('')
    const [selectedCompanyDetails, setSelectedCompanyDetails] = useState<any>()
    const [selectedDesignation, setSelectedDesignation] = useState<any>()
    const age = useInput('')
    const email = useInput('')
    const personal = useInput('')
    const professional = useInput('')
    const companyAddress = useInput('')
    const companyName = useInput('')
    const designation = useInput('')
    const PhoneNumber = useInput('')
    const linkedInUrl = useInput('')
    const cites = useInput('')
    const states = useInput('')
    const industries = useInput('')
    const employees = useInput('')
    const countries = useInput('')
    const employeeId = useInput('')
    const submitLoader = useLoader(false)
    const [noResultCompany, setNoResultCompany] = useState<any>()
    const [noResultDesignation, setNoResultDesignation] = useState<any>()

    useEffect(() => {
        handleGetAllBranches()
        getDesignationDispatch()

    }, [])

    useEffect(() => {
        if (selectedCompanyDetails) {
            handleDesignation()
        }


    }, [selectedCompanyDetails])

    const handleGetAllBranches = () => {
        const params = {}
        dispatch(
            getAllBranches(
                {
                    params,
                    onSuccess: (response: any) => () => {



                        if (companyName.value) {
                            let companyData: any = response.details.filter((option) => option.name === companyName.value)

                            setSelectedCompanyDetails({ value: companyData[0]?.name, label: companyData[0].name, id: companyData[0]?.id })

                        }

                    },
                    onError: (error: any) => () => {

                    }

                }
            )
        )

    }

    const handleDesignation = () => {
        const params = { company_branch_id: selectedCompanyDetails.id }
        dispatch(getDesignations({

            params,
            onSuccess: (response: any) => () => {
                if (designation.value) {
                    let designationData = response?.details.filter((el) => el.name === designation.value)
                    setSelectedDesignation({ value: designationData[0]?.name, label: designationData[0]?.name, id: designationData[0]?.id })
                }
            },
            onError: () => () => { }


        })
        )
    }



    const reSelectValue = () => {
        companyAddress.set('')
        PhoneNumber.set('')
        cites.set('')
        countries.set('')
        states.set('')
        industries.set('')
        employees.set('')
    }
    const handleAddDesignation = () => {

        const params = {
            name: designation.value,
            company_branch_id: selectedCompanyDetails.id
        }

        const validation = validate(REGISTER_DESIGNATION, params);
        if (ifObjectExist(validation)) {
            dispatch(
                addDesignations({
                    params,
                    onSuccess: () => () => {
                        designationModal.hide()
                        // setSelectedDesignation({value:designation?.value,label:designation?.value})
                        handleDesignation()
                        setNoResultDesignation('')
                    },
                    onError: () => () => { }
                })
            )
        }
        else {
            showToast(getValidateError(validation));
        }
    }

    const handleRegisterCompany = () => {

        const params = {
            brand_name: companyName.value,
            communication_address: companyAddress.value,
            mobile_number: PhoneNumber.value,
            city: cites.value,
            state: states.value,
            country: countries.value,
            employee_size: employees.value,
            industry: industries.value
        }
        const validation = validate(REGISTER_COMPANY_RULE, params);
        if (ifObjectExist(validation)) {
            dispatch(
                registerCompany({
                    params,
                    onSuccess: () => () => {
                        companyDetailsModal.hide()
                        getDesignationDispatch()

                        // setSelectedCompanyDetails({value:companyName.value,label:companyName.value})
                        handleGetAllBranches()
                        setNoResultCompany('')

                    },
                    onError: () => () => {

                    }

                })
            )
        }
        else {
            showToast(getValidateError(validation));
        }
    }

    const handleCompanyBranchDetails = (el: any) => {
        const params = { company_branch_id: el?.id }
        dispatch(
            getCompanyBranchesDetails({
                params,
                onSuccess: (response: any) => () => {
                    const { branch_details } = response?.details
                    const { company_name, address, company_phone_number, city, country, state, industry, employee_size } = branch_details
                    companyName.set(company_name)
                    companyAddress.set(address)
                    PhoneNumber.set(company_phone_number)
                    cites.set(city)
                    countries.set(country)
                    states.set(state)
                    industries.set(industry)
                    employees.set(employee_size)


                },
                onError: () => () => {

                }
            })
        )

    }

    const onValidatePostParams = () => {

        if (!name.value) {
            showToast('Name field should not be empty', 'error')
            return false
        }

        else if (!age.value) {
            showToast('Age field should not be empty', 'error')
            return false
        }
        else if (!contactNumber) {
            showToast('Contact number field should not be empty', 'error')
            return false
        }
        else if ([contactNumber].length >= 10) {
            showToast('Contact number field should not be 10 characters empty', 'error')
            return false
        }
        else if (!personal.value) {
            showToast('Personal info field should not be empty', 'error')
            return false
        }
        else if (!professional.value) {
            showToast('Professional info field should not be empty', 'error')
            return false
        }
        else if (!selectedDesignation.id) {
            showToast('designation info field should not be empty', 'error')
            return false
        }
        else if (!selectedCompanyDetails.id) {
            showToast('companyDetails info field should not be empty', 'error')
            return false
        }

        else {
            return true
        }
    }


    const onAddClientHandler = () => {
        const params = {
            first_name: name.value,
            last_name: lastName.value,
            email: email.value,
            age: age.value,
            mobile_number: contactNumber,
            linkedIn_url: linkedInUrl?.value,
            about_personal: personal.value,
            about_professional: professional.value,
            designation_id: selectedDesignation?.id,
            campaign_id: currentCampaignDetails?.id,
            company_branch_id: selectedCompanyDetails.id,
            //  employee_Id:employeeId?.value
        }



        if (onValidatePostParams()) {
            dispatch(
                addClient({
                    params,
                    onSuccess: (response: any) => () => {
                        submitLoader.hide()
                        if (response.success) {
                            showToast(response.message, "success");
                            goBack();
                        }
                    },
                    onError: (error) => () => {
                        showToast(error.error_message, "error");
                        submitLoader.hide()

                    },
                })
            );
        }

    }
    const getDesignationDispatch = () => {
        dispatch(
            getDesignationsSuccess({})
        )
    }

    return (
        <Card className="m-3">
            <div className='col'>
                <div className="row">
                    <Back />
                    <h3 className="ml-3">{'Add Client'}</h3>
                </div>
            </div>


            <div className='ml-2'>
                <div className='row pt-2 '>
                    <div className='col-2 text-left pt-1'>
                        <InputHeading heading={" First Name"} />
                    </div>
                    <div className='col-6'>
                        <Input

                            placeholder={'First Name'}
                            value={name.value}
                            onChange={name.onChange}
                        />
                    </div>


                </div>

                <div className='row pt-2'>
                    <div className='col-2 text-left pt-1'>
                        <InputHeading heading={" Last Name"} />
                    </div>
                    <div className='col-6'>
                        <Input
                            // heading={'Name'}
                            placeholder={'Last Name'}
                            value={lastName.value}
                            onChange={lastName.onChange}
                        />
                    </div>
                </div>

                <div className='row pt-2'>
                    <div className='col-2 text-left pt-1'>
                        <InputHeading heading={"Gmail"} />
                    </div>
                    <div className='col-6'>
                        <Input
                            // heading={'Name'}
                            placeholder={'Gmail'}
                            value={email.value}
                            onChange={email.onChange}
                        />
                    </div>


                </div>


                <div className='row pt-2'>


                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"Mobile Number"} />
                    </div>
                    <div className='col-6'>

                        <PhoneNumberInput onChange={setPhoneNumber} value={contactNumber} />
                    </div>

                </div>



                <div className='row pt-2'>
                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"Age"} />
                    </div>
                    <div className='col-6'>
                        <Input
                            type={"number"}
                            id='Age'
                            placeholder={'Age'}
                            maxLength={2}
                            value={age.value}
                            onChange={age.onChange}
                        />
                    </div>
                </div>




                <div className='row pt-2'>
                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"Personal Info"} />
                    </div>

                    <div className='col-sm-6'>
                        <Input

                            placeholder={'Personal Info'}
                            value={personal.value}
                            onChange={personal.onChange}
                        />
                    </div>
                </div>
                <div className='row pt-2'>
                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"Professional Info"} />
                    </div>
                    <div className='col-sm-6'>
                        <Input

                            placeholder={'Professional Info'}
                            value={professional.value}
                            onChange={professional.onChange}
                        />
                    </div>
                </div>

                {/* <div className='row pt-2'>
                <div className='col-2  text-left pt-1'>
                <InputHeading    heading={"Employee Id"}  />
                </div>
                <div className='col-sm-6'>
                    <Input
                      
                        placeholder={'Professional Info'}
                        value={employeeId.value}
                        onChange={employeeId.onChange}
                    />
                </div>
            </div> */}

                <div className='row pt-2'>
                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"LinkedInUrl"} />
                    </div>
                    <div className='col-sm-6'>
                        <Input
                            placeholder={'LinkedInUrl'}
                            value={linkedInUrl.value}
                            onChange={linkedInUrl.onChange}
                        />
                    </div>

                </div>

                <div className='row pt-2'>
                    <div className='col-2  text-left pt-1'>
                        <InputHeading heading={"Company"} />
                    </div>
                    <div className='col-sm-6'>
                        <AutoSearch
                            // defaultValue={ {value:defaultName,label:defaultName}}
                            options={getDropDownSearchData(branchesData, 'name')}
                            onInputChange={(el) => {
                                if (el.length > 0) {
                                    setNoResultCompany(el)

                                }
                            }}
                            onChange={(el) => {
                                setSelectedCompanyDetails(el)
                                handleCompanyBranchDetails(el)

                            }}
                            value={selectedCompanyDetails}
                            isButton={true}
                            buttonText='create'
                            onClick={() => {
                                companyName.set(noResultCompany)
                                reSelectValue()
                                companyDetailsModal.show()

                            }}

                        />

                    </div>

                    {/* {noResultCompany &&  noResultCompany.length>0 && selectedCompanyDetails===undefined &&   <div>
                <Button  
                    text={'Create'} size={'md'}
                     onClick={()=>{
                        companyName.set(noResultCompany)
                        companyDetailsModal.show()
                    }}
                    />
                </div>
} */}


                </div>






                {selectedCompanyDetails && <div>
                    <hr></hr>

                    <h3>Company Details</h3>
                    <div className='row pt-3'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"CompanyName"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'CompanyName'}
                                value={companyName.value}
                                onChange={companyName.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"CompanyAddress"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'CompanyAddress'}
                                value={companyAddress.value}
                                onChange={companyAddress.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"PhoneNumber"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'PhoneNumber'}
                                value={PhoneNumber.value}
                                onChange={PhoneNumber.onChange}
                                maxLength={10}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"City"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'City'}
                                value={cites.value}
                                onChange={cites.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"Country"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'Country'}
                                value={countries.value}
                                onChange={countries.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"State"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'State'}
                                value={states.value}
                                onChange={states.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"Industry"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'Industry'}
                                value={industries.value}
                                onChange={industries.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-2 text-left pt-1'>
                            <InputHeading heading={"Employee Size"} />
                        </div>
                        <div className='col-6'>
                            <Input
                                placeholder={'Employee Size'}
                                value={employees.value}
                                onChange={employees.onChange}
                            />
                        </div>

                    </div>

                    <div className='row pt-2'>
                        <div className='col-2  text-left pt-1'>
                            <InputHeading heading={"Designation"} />
                        </div>
                        <div className='col-sm-6'>
                            <AutoSearch
                                options={getDropDownSearchData(designations, 'name')}
                                value={selectedDesignation}
                                onInputChange={(el) => {
                                    if (el.length > 0) {
                                        setNoResultDesignation(el)
                                    }
                                }}
                                onChange={(el) => {
                                    setSelectedDesignation(el)

                                }}
                                isButton={true}
                                buttonText='Create Designation'
                                onClick={() => {
                                    designationModal.show()
                                    designation.set(noResultDesignation)
                                }

                                }
                            />
                        </div>




                        {/* {noResultDesignation &&  noResultDesignation.length>0 && selectedDesignation===undefined &&              <div>
             
                   <Button  
                    text={'Create Designation'} size={'md'}
                     onClick={()=>{designationModal.show()
                        designation.set(noResultDesignation)
                     }
                    }
                    />
                </div>
} */}


                    </div>




                    <div className='row   justify-content-end'>

                        <Button text={'Submit'} onClick={() => {

                            onAddClientHandler()


                        }} />

                    </div>

                </div>
                }


            </div>

            <Modal isOpen={companyDetailsModal.visible} onClose={() => {
                handleCompanyBranchDetails(selectedCompanyDetails)
                companyDetailsModal.hide()
            }} title={'Add Company Details'} size={'lg'}>
                <div className='m-3'>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"CompanyName"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'CompanyName'}
                                value={companyName.value}
                                onChange={companyName.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"CompanyAddress"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'CompanyAddress'}
                                value={companyAddress.value}
                                onChange={companyAddress.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"PhoneNumber"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'PhoneNumber'}
                                type={'number'}
                                value={PhoneNumber.value}
                                onChange={PhoneNumber.onChange}
                                maxLength={10}

                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"City"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'City'}
                                value={cites.value}
                                onChange={cites.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Country"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Country'}
                                value={countries.value}
                                onChange={countries.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"State"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'State'}
                                value={states.value}
                                onChange={states.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Industry"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Industry'}
                                value={industries.value}
                                onChange={industries.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Employee Size"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Employee Size'}
                                value={employees.value}
                                onChange={employees.onChange}
                            />
                        </div>

                    </div>



                    <div className='row   justify-content-end'>

                        <Button text={'Submit'} onClick={() => {

                            handleRegisterCompany()




                        }} />

                    </div>
                </div>



            </Modal>

            <Modal isOpen={designationModal.visible} onClose={designationModal.hide} title={'Add Designation'} >
                <div className='m-3'>
                    <div className='row'>
                        <div className='col-2 text-left'>
                            <InputHeading heading={"Designation"} />
                        </div>
                        <div className='col-8'>
                            <Input
                                placeholder={'Designation'}
                                value={designation.value}
                                onChange={designation.onChange}
                            />
                        </div>

                    </div>
                    <div className='row   justify-content-end'>

                        <Button text={'Submit'}
                            onClick={(el) => {

                                handleAddDesignation()
                            }} />

                    </div>

                </div>

            </Modal>
        </Card>
    )
}

export { AddClient }