import { Card, NoDataFound, ProgressCircleChart, ProgressTrack, Spinner } from '@Components';
import React, { useState } from 'react'
import { useWindowDimensions } from '@Hooks';
import { useSelector } from 'react-redux';
import { StatsCard, OverallProgressCard } from '@Modules';
function Expectation() {

    const { expectationDetails, analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);

    const { expectations, personName } = expectationDetails || {}

    const [selectedData, setSelectedData] = useState<any>(expectations && expectations.length > 0 ? [expectations[0]] : [])


    console.log("expectations", expectations)


    const { width, height } = useWindowDimensions()
    return (
        <>
            {expectations && expectations?.length > 0 ? (
                <div className='row' >
                    <div className='col-xl-4 col-md-6 col-sm-12 ml-xl--1'>
                        {analyticsOverAllPercentage && <OverallProgressCard isShowAsOnclick progress={analyticsOverAllPercentage?.overAllPercentage} data={expectations} lineProgressColor={'primary'}
                            onItemClick={(item) => {
                                const filteredData = expectations.filter((el) => el.expectation === item.expectation)
                                setSelectedData(filteredData)

                            }}
                        />}
                    </div>

                    <div className='col-xl-8 col-md-6 col-sm-12 overflow-auto overflow-hide' style={{ overflowY: 'scroll' }}>
                        <div className='row '>

                            {
                                selectedData && selectedData.length > 0 && selectedData.map((item) => {
                                    const { reason, percent, reference, expectation } = item
                                    return (
                                        <div className='col-xl-12 col-md-12 mb--3'>
                                            <StatsCard personsName={personName} title={expectation} subTitle={reason} progress={percent} data={reference} reference='References' progressBarData={expectations} />
                                        </div>
                                    );
                                })

                            }
                        </div>
                    </div>




                </div>
            ) : <NoDataFound />}
        </>
    )
}

export { Expectation }
