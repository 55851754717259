export const icons = {
  google: require('./Google/icon.png'),
  minus: require('./Minus/icon.png'),
  plus: require('./Plus/icon.png'),
  close: require('./Close/icon.png'),
  downArrow: require('./Downarrow/icon.png'),
  downArrowOutline: require('./DownarrowOutline/icon.png'),
  search: require('./Search/icon.png'),
  addFill: require('./AddFill/icon.png'),
  addFillRounded: require('./AddFillRounded/icon.png'),
  addFillSquare: require('./AddFillSquare/iconWhitePlus.png'),
  addOutline: require('./AddOutline/icon@3x.png'),
  navigationArrow: require('./NavigationArrow/icon.png'),
  arrowRight: require('./ArrowRight/icon.png'),
  calenderList: require('./CalenderList/icon.png'),
  backButton: require('./BackButton/icon.png'),
  quantaTms: require('./QuantaTms/icon.png'),
  booking: require('./Booking/icon.png'),
  bookingFill: require('./BookingFill/icon.png'),
  brandFill: require('./BrandFill/icon.png'),
  briefCase: require('./BriefCase/icon.png'),
  calender: require('./Calender/icon.png'),
  calenderBlack: require('./CalenderBlack/icon.png'),
  calenderCross: require('./CalenderCross/icon.png'),
  calenderDate: require('./CalenderDate/icon.png'),
  calenderFill: require('./CalenderFill/icon.png'),
  calenderLine: require('./CalenderLine/icon.png'),
  calenderTick: require('./CalenderTick/icon.png'),
  calenderTime: require('./CalenderTime/icon.png'),
  call: require('./Call/icon.png'),
  cart: require('./Cart/icon.png'),
  locationFill: require('./LocationFill/icon.png'),
  chatMiddle: require('./ChatMiddle/icon.png'),
  chatSmall: require('./ChatSmall/icon.png'),
  clock: require('./Clock/icon.png'),
  closeBtn: require('./CloseBtn/icon.png'),
  closeFill: require('./CloseFill/icon.png'),
  cross: require('./Cross/icon.png'),
  delete: require('./Delete/icon.png'),
  deleteCurve: require('./DeleteCurve/icon.png'),
  deleteLine: require('./DeleteCurve/icon.png'),
  doubleTick: require('./DoubleTick/icon.png'),
  dropDown: require('./Dropdown/icon.png'),
  edit: require('./Edit/icon.png'),
  eye: require('./Eye/icon.png'),
  faceBook: require('./Facebook/icon.png'),
  feed: require('./Feed/icon.png'),
  greenProfile: require('./GreenProfile/icon.png'),
  home: require('./Home/icon.png'),
  homeBg: require('./HomeBg/icon.png'),
  homeFill: require('./HomeFill/icon.png'),
  leftArrow: require('./LeftArrow/icon.png'),
  location: require('./Location/icon.png'),
  logo: require('./Logo/icon 10101.png'),
  logout: require('./Logout/icon.png'),
  logoWhite: require('./LogoWhite/icon.png'),
  marker: require('./Marker/icon.png'),
  medal: require('./Medal/icon.png'),
  menuBlack: require('./MenuBlack/icon.png'),
  menuCircle: require('./MenuCircle/icon.png'),
  menuGreen: require('./MenuGreen/icon.png'),
  menuWhiteDot: require('./MenuWhiteDot/icon.png'),
  money: require('./Money/icon.png'),
  newMemberImage: require('./NewMemberImage/icon.png'),
  next: require('./Next/icon.png'),
  nextFill: require('./NextFill/icon.png'),
  nextLine: require('./NextLine/icon.png'),
  noButton: require('./NoButton/icon.png'),
  offSwitch: require('./OffSwitch/icon.png'),
  onSwitch: require('./OnSwitch/icon.png'),
  onGoing: require('./OnGoing/icon.png'),
  pencil: require('./Pencil/icon.png'),
  pencilEdit: require('./PencilEdit/icon.png'),
  pencilLine: require('./PencilLine/icon.png'),
  pending: require('./Pending/icon.png'),
  plusBlack: require('./PlusBlack/icon.png'),
  previousArrow: require('./PreviousArrow/icon.png'),
  productFill: require('./ProductFill/icon.png'),
  productLine: require('./ProductLine/icon.png'),
  productPhoto: require('./ProductPhoto/icon.png'),
  profile: require('./Profile/icon.png'),
  profileBlack: require('./ProfileBlack/icon.png'),
  reject: require('./Reject/icon.png'),
  reset: require('./Reset/icon.png'),
  restimonial: require('./Restimonial/icon.png'),
  searchLine: require('./SearchLine/icon.png'),
  selectOff: require('./SelectOff/icon.png'),
  selectOn: require('./SelectOn/icon.png'),
  send: require('./Send/icon.png'),
  share: require('./Share/icon.png'),
  shoppingCartFill: require('./ShoppingCartFill/icon.png'),
  store: require('./Store/icon.png'),
  tickGreen: require('./TickGreen/icon.png'),
  trolley: require('./Trolley/icon.png'),
  categories: require('./Categories/icon.png'),
  categoriesFill: require('./CategoriesFill/icon.png'),
  agent: require('./Agent/icon.png'),
  agentFill: require('./AgentFill/icon.png'),
  menuCircleFill: require('./MenuCircleFill/icon.png'),
  sentFill: require('./SentFill/icon.png'),
  equalizer: require('./Equalizer/icon.png'),
  clinic: require('./Clinic/icon.png'),
  back: require('./Back/icon.png'),
  addFillGray: require('./AddFillGray/plus new.png'),
  addToFill: require('./AddToFill/clipboard.png'),
  Equalizer: require('./Panel/panel.png'),
  backArrow: require('./BackArrow/icon.png'),
  questionMark: require('./RoundedQuestionMark/icon.png'),
  issuesProblem: require('./IssuesProblem/problem.png'),
  bellIcon: require('./BellIcon/icon.png'),
  editLineIcon: require('./EditLine/editing.png'),
  subTask: require('./SubTask/icon.png'),
  imageEdit: require('./ImageEdit/icon.png'),
  updatedProfile: require('./UpdatedProfile/add-photo.png'),
  profilePick: require('./ProfilePick/user.png'),
  basic: require('./Basic/icon.png'),
  advance: require('./Advance/interactivity.png'),
  ticket: require('./Ticket/planning.png'),
  message: require('./Message/icon.png'),
  task: require('./Task/icon.png'),
  issue: require('./Issue/icon.png'),
  company: require('./Company/icon.png'),
  setting: require('./Setting/icon.png'),
  advanceFilter: require('./AdvanceFilter/icon.png'),
  history: require('./History/icon.png'),
  tagUser: require('./TagUser/icon.png'),
  reassignUser: require('./ReassignUser/icon.png'),
  taskStatus: require('./TaskStatus/icon.png'),
  upload: require('./Upload/icon.png'),
  addSub: require('./AddSub/icon.png'),
  markAsClose: require('./MarkAsClose/icon.png'),
  markAsOpen: require('./MarkAsOpen/icon.png'),
  editEta: require('./EditEta/icon.png'),
  Comments: require('./Comments/icon.png'),
  CommentsPink: require('./CommentsPink/icon.png'),
  attachments: require('./Attachments/icon.png'),
  attachmentsPink: require('./AttachmentsPink/icon.png'),
  reference: require('./Reference/icon.png'),
  referencePink: require('./ReferencePink/icon.png'),
  timeline: require('./TimelineHistoty/icon.png'),
  users: require('./Users/icon.png'),
  usersPink: require('./UsersPink/icon.png'),
  employeePortfolio: require('./EmployeePortfolio/icon.png'),
  timeSheet: require('./Timesheet/icon.png'),
  attachmentWhiteIcon: require('./AttachmentsWhiteIcons/icon.png'),
  taggedUserWhiteIcon: require('./TaggedUserWhiteIcon/icon.png'),
  statusWhiteIcon: require('./StatusWhiteIcon/icon.png'),
  referenceTaskWhiteIcon: require('./ReferenceTaskWhiteIcon/icon.png'),
  reassignedUserWhiteIcon: require('./ReassignedUserWhiteIcon/icon.png'),
  clockWhiteIcon: require('./Clock/icon.png'),
  startTime: require('./StartTime/icon.png'),
  endTime: require('./EndTime/icon.png'),
  broadCast: require('./BroadcastMessage/icon.png'),
  downArrowBlack: require('./DownArrowBlack/icon.png'),
  videoConference: require('./VideoConference/icon.png'),
  verticalLine: require('./VerticalLine/icon.png'),
  protfolio: require('./Protfolio/icon.png'),
  myTimeSheet: require('./MyTimeSheet/icons.png'),
  previousBackArrow: require('./PreviousBackArrow/icons.png'),
  nextArrow: require('./NextArrow/icons.png'),
  mic: require('./Mic/icon.png'),
  file: require('./File/icon.png'),
  email: require('./Email/icon.png'),
  whatsapp: require('./Whatsapp/icon.png'),
  defaultIcon: require('./DefaultIcon/icon.png'),
  excel: require('./Excel/icon.png'),
  call_icon: require('./CallIcon/telephone.png'),
  call_icon2: require('./CallIcon/user.png'),
  call_icon3: require('./CallIcon/user (1).png'),
  CallModule: require('./CallModule/icon.png'),
  userProfile:require('./UserProfile/icon.png'),
  camping:require('./Camping/icon.png'),
  Agents:require('./Agents/icon.png'),
  Analatic:require('./Analatic/icon.png'),
  convolution1:require('./Covolution1/icon2.png'),
  conImage1:require('./ConImage1/icon.png'),
  conImage2:require('./ConImage2/icon1.png'),
  conImage3:require('./ConImage3/icon.png'),
  conImage4:require('./ConImage4/icon.png'),
  conImage5:require('./ConImage5/icon.png'),
  conImage6:require('./ConImage6/icon.png'),
  conImage7:require('./ConImage7/icon2.png'),
  conImage8:require('./ConImage8/icon.png'),
  conImage9:require('./ConImage9/icon.png'),
  Security:require('./Security/icon.png'),
  features:require('./Features/icon.png'),
  benifits:require('./Benifits/icon.png'),
  



  agentTraitActive: require('./AgentTrait/icon1.png'),
  agentTraitDefault: require('./AgentTrait/icon2.png'),

  agentSharedInfoGenericActive: require('./AgentSharedInfoGeneric/icon1.png'),
  agentSharedInfoGenericDefault: require('./AgentSharedInfoGeneric/icon2.png'),

  agentSharedInfoExclusiveActive: require('./AgentSharedInfoExclusive/icon1.png'),
  agentSharedInfoExclusiveDefault: require('./AgentSharedInfoExclusive/icon2.png'),

  agentEngagementSuggessionActive: require('./AgentEngagementSuggession/icon1.png'),
  agentEngagementSuggessionDefault: require('./AgentEngagementSuggession/icon2.png'),

  callTranscriptionActive: require('./CallTranscription/icon1.png'),
  callTranscriptionDefault: require('./CallTranscription/icon2.png'),

  performanceMetricsOverviewActive: require('./PerformanceMetricsOverview/icon1.png'),
  performanceMetricsOverviewDefault: require('./PerformanceMetricsOverview/icon2.png'),

  prospectCallPerceptionActive: require('./ProspectCallPerception/icon1.png'),
  prospectCallPerceptionDefault: require('./ProspectCallPerception/icon2.png'),

  standardEngagementStrategiesActive: require('./StandardEngagementStrategies/icon1.png'),
  standardEngagementStrategiesDefault: require('./StandardEngagementStrategies/icon2.png'),

  tailoredEngagementStrategiesActive: require('./TailoredEngagementStrategies/icon1.png'),
  tailoredEngagementStrategiesDefault: require('./TailoredEngagementStrategies/icon2.png'),

  customerService: require('./CustomerService/icon.png'),
  userDefault: require('./UserDefault/icon.png'),
  RobertGreen:require('./RobertGreen/icon.png'),
  SteveJobs:require('./SteveJobs/icon.png'),
  ZigZigler:require('./ZigZigler/icon.png'),

  notes:require('./Notes/icon.png'),

  callDetailsActive:require('./CallOutgoing/icon2.png'),
  callDetailsDefault:require('./CallOutgoing/icon1.png'),


  approaches:require('./Approaches/skills.png'),
  download:require('./Download/icon.png'),
  cvsFile:require('./CvsFile/icon.png'),
  CSVFileImage:require('./CSVFileImage/icon.png')


};
