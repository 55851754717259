import { icons } from '@Assets'
import { Back, Card, DateTimePicker, DropDown, Input, Image, Button, Modal, NoRecordsFound, showToast, MultiSelectDropDown, CommonTable, Divider, AutoSearch } from '@Components'
import { useDropDown, useInput, useLoader, useModal, useNavigation } from '@Hooks'
import { addCampaign, getCampaigns, getClients, getEmployees, getProducts, isCampaignAdded, postProduct } from '@Redux'
import { getDropDownDisplayData, getDropDownSearchData, getImageUrl, getMomentObjFromServer } from '@Utils'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'


const EXPECTATIONS = [
    { key: 'sales conversion', value: 'Sales conversion', name: 'Sales conversion' },
    { key: 'reengagement', value: 'Re-engagement', name: 'Re-engagement' },
    { key: 'curiosity', value: 'Curiosity', name: 'Curiosity' }
];

function AddCampaign() {

    const dispatch = useDispatch();
    const { goBack } = useNavigation();


    const { employeesList, productsList } = useSelector((state: any) => state.AuthReducer);

    const [agentInfoType, setAgentInfoType] = useState([
        { id: 1, value: 'Item', check: true, type: 'Item' },
        { id: 2, value: 'Group', check: false, type: 'Group' },
    ])

    const [prospectInfoType, setProspectInfoType] = useState([

        { id: 1, value: 'Item', check: true, type: 'Item' },
        { id: 2, value: 'Group', check: false, type: 'Group' },
    ])

    const [agentType, setAgentType] = useState('Item')
    const [prospectType, setProspectType] = useState('Item')

    const agentInfo = useInput('')
    const [selectedAgentInfo, setSelectedAgentInfo] = useState<any>([])
    const prospectInfo = useInput('')
    const [selectedProspectInfo, setSelectedProspectInfo] = useState<any>([])


    const name = useInput('')
    const [startDate, setStartDate] = useState<any>('')
    const [endDate, setEndDate] = useState<any>('')
    // const product = useDropDown({})
    const [selectedAgentsList, setSelectedAgentsList] = useState([])
    const [isAgentsModal, setIsAgentsModal] = useState(false)
    const isAgentsDataLoader = useLoader(false)
    const agenda = useInput('')
    const expectations = useInput('')
    
    const [selectedExpectationsData, setSelectedExpectationsData] = useState<any>([])
    const productModal = useModal(false)
    const [selectedProductDetails,setProductDetails]=useState<any>()
    const [noResultData,setNoResultData]=useState<any>()
    const title= useInput('')
    const description=useInput('')

    useEffect(() => {
        getProductsList()
    }, [])

    const getEmployeesHandler = () => {
        const params = {

        }
        isAgentsDataLoader.show()
        dispatch(
            getEmployees({
                params,
                onSuccess: (response: any) => () => {
                    isAgentsDataLoader.hide()
                },
                onError: (error) => () => {
                    isAgentsDataLoader.hide()
                },
            })
        )
    }

    const handleAddProduct =()=>{
        const params={name:title.value,
        description:description.value}
        dispatch(
            
            postProduct({
                params,
                onSuccess:(response:any)=>()=>{
                    
                    productModal.hide()
                    getProductsList()
          

                },
                onError:()=>()=>{

                }
            })
        )

    }


    const getProductsList = () => {

        const params = {}

        dispatch(
            getProducts({
                params,
                onSuccess: (response: any) => () => {
                
              
                    if(title.value){
                        let designationData = response.filter((el)=>el.name===title.value)
                       
                        setProductDetails({value:designationData[0]?.name,label:designationData[0]?.name,id:designationData[0]?.id})
                          }

                },
                onError: (error) => () => {
                },
            })
        )
    }


    const onValidatePostParams = () => {

        if (!name.value) {
            showToast('Name field should not be empty')
            return false
        }
        else if (!startDate) {
            showToast('Please select start date')
            return false
        }
        else if (!endDate) {
            showToast('Please select end date')
            return false
        }
        else if (!selectedProductDetails?.id) {

            showToast('Please select product')
            return false
        }
        else if (selectedAgentsList.length === 0) {
            showToast('Please select atLeast one Agent')
            return false
        }
        else {
            return true
        }
    }


    const onAddCampaign = () => {

        const agentsIds = selectedAgentsList.length > 0 ? selectedAgentsList.map((el: any) => { return el.id }) : []

        const params = {
            name: name.value,
            start_date: startDate,
            end_date: endDate,
            product_id: selectedProductDetails?.id,
            agents_ids: agentsIds,
            clients_ids: [],
            agenda: agenda.value,
            expectations: selectedExpectationsData,
            ...(agentType === 'Item' && selectedAgentInfo.length > 0 ? { agent_information_to_share: selectedAgentInfo } : selectedAgentInfo.length > 0 && { agent_information_group: selectedAgentInfo }),
            ...(prospectType === 'Item' && selectedProspectInfo.length > 0 ? { prospect_information_to_share: selectedProspectInfo } : selectedProspectInfo.length > 0 && { prospect_information_group: selectedProspectInfo })
        }

        console.log("params==>", params);
        if (onValidatePostParams()) {

            dispatch(
                addCampaign({
                    params,
                    onSuccess: (response: any) => () => {
                        dispatch(isCampaignAdded(true))
                        showToast(response.message, "success");
                        goBack();
                    },
                    onError: (error) => () => {
                        showToast(error.error_message, "error");

                    },
                })
            );
        }

    }


    const addSelectedAgents = (item: any) => {
        let updateSelectedAgents = [...selectedAgentsList];

        const isExists = updateSelectedAgents?.some(
            (el: any) => el.id === item.id
        );

        if (isExists) {
            updateSelectedAgents = updateSelectedAgents?.filter(
                (eachItem: any) => eachItem.id !== item.id
            );
        }
        else {
            updateSelectedAgents = [...updateSelectedAgents, item as never];
        }
        setSelectedAgentsList(updateSelectedAgents as never)
    };


    const handleMultiSelectDropDownData = (type, item) => {
        let array: any = [...selectedExpectationsData]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedExpectationsData(array)
        }
        else if (type === 'remove') {

            let modified = selectedExpectationsData.filter((el, index) => el !== item)
            console.log("modified", modified);

            setSelectedExpectationsData(modified)
        }
        expectations.set('')
    }

    const handleMultiSelectAgentInfo = (type, item) => {
        let array: any = [...selectedAgentInfo]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedAgentInfo(array)
        }
        else if (type === 'remove') {
            let modified = array.filter((el, index) => el !== item)
            setSelectedAgentInfo(modified)
        }
        agentInfo.set('')
    }

    const handleMultiSelectProspectInfo = (type, item) => {
        let array: any = [...selectedProspectInfo]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedProspectInfo(array)
        }
        else if (type === 'remove') {
            let modified = array.filter((el, index) => el !== item)
            setSelectedProspectInfo(modified)
        }
        prospectInfo.set('')
    }

    const handleAgentCheckBoxData = (index) => {

        let modifiedData = agentInfoType.map((el) => {
            if (el.id === index) {
                return { ...el, check: true }
            }
            else {
                return { ...el, check: false }
            }

        })

        setAgentInfoType(modifiedData)
    }

    const handleProspectCheckBoxData = (index) => {

        let modifiedData = prospectInfoType.map((el) => {
            if (el.id === index) {
                return { ...el, check: true }
            }
            else {
                return { ...el, check: false }
            }

        })

        setProspectInfoType(modifiedData)
    }

    const normalizedTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {

            return {
                Name: el?.name,
                'Mobile number': el?.mobile_number,
                '': <h5 className='pointer' onClick={() => {
                    const filteredAgents = selectedAgentsList.filter((item: any) => item.id !== el.id)
                    setSelectedAgentsList(filteredAgents)
                }}>{'Delete'}</h5>
            }
        }
        )
    }


    return (
        <Card className="m-3">
            <div className='col'>
                <div className="row">
                    <Back />
                    <h3 className="ml-3">{'Add Campaign'}</h3>
                </div>
            </div>
            {/* <hr className='mt-3'></hr> */}

            <div className='row'>
                <div className='col-sm-6'>
                    <Input
                        heading={'Name'}
                        placeholder={'Name'}
                        value={name.value}
                        onChange={name.onChange}
                    />
                </div>
                <div className='col-sm-6'>
                    <DateTimePicker
                        heading={'Start Date'}
                        placeholder={'Start Date'}
                        value={startDate ? getMomentObjFromServer(startDate) : null!}
                        onChange={(e) => {
                            setStartDate(e)

                        }}
                    />
                </div>
            </div>

            <div className='row'>
                <div className='col-sm-6'>
                    <DateTimePicker
                        heading={'End Date'}
                        placeholder={'End Date'}
                        value={endDate ? getMomentObjFromServer(endDate) : null!}
                        onChange={(e) => {
                            setEndDate(e)

                        }}
                    />
                </div>
                {/* <div className='col-sm-6'>
                    <DropDown
                        heading={'Product'}
                        placeHolder={'Product'}
                        data={getDropDownDisplayData(productsList)}
                        selected={product.value}
                        value={product.value}
                        onChange={product.onChange}
                    />
                </div> */}
      <div className='col-sm-6 '>
    <div className='row'>


               <div className='col-10'>
              <AutoSearch
          heading={'Product'}
               options={getDropDownSearchData(productsList,'name')}
               onInputChange={(el)=>{
                if(el.length>0){
                    setNoResultData(el)
            }
               }}
               onChange={(el)=>{
                setProductDetails(el)
           
            
               }}
               value={selectedProductDetails}
               />
                 </div> 
                 <div className='col-2 ml--3 mt-4' >
                 <Button text={'Add'} onClick={()=>{ 
                    productModal.show()
                    title.set(noResultData)
                }}
                 
                 />
                 </div>
                 </div>

        </div>
            </div>

            <div className="row">
                {/* {Agent info} */}
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col">
                            <label className={'form-control-label'}>{'Agent Information To Share'}</label>

                            {/* {checkbox} */}

                            <div className="row">

                                {agentInfoType.map((el, index) => {
                                    return (
                                        <>
                                            <div className="form-check mb-3 ml-3">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault2"
                                                    id="flexRadioDefault2"
                                                    onChange={(e) => {
                                                        handleAgentCheckBoxData(el.id)
                                                        setAgentType(el.value)
                                                        setSelectedAgentInfo([])
                                                    }}
                                                    checked={el.check}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    {el.value}
                                                </label>

                                            </div>
                                        </>
                                    )
                                })
                                }
                            </div>

                            {agentInfoType.map((el, index) => {
                                if (el.check === true) {
                                    return (
                                        <h5 className="mt--3 text-muted">
                                            {el.value === 'Item' && el.check === true ? `Ex:Company Name, Contact Number, etc...` : 'Ex:Company Portfolio,Service Details, etc...'}
                                        </h5>
                                    )
                                }
                            })}

                            <div className="row">
                                <div className="col">
                                    <Input value={agentInfo.value} onChange={agentInfo.onChange} />
                                </div>
                                <div>
                                    <Button
                                        text={'Add'}
                                        size="md"
                                        onClick={() => {
                                            handleMultiSelectAgentInfo('add', agentInfo.value)
                                        }}
                                    />
                                </div>
                            </div>

                        </div>


                    </div>
                    <div className="row mt--2 mb-3 ml-0">
                        {
                            selectedAgentInfo && selectedAgentInfo.map((el, index) => {
                                return (
                                    <>
                                        <div className="mt-2">
                                            <div className="ml-2 bg-primary text-white pl-2 pr-1  rounded">
                                                {el}
                                                <span className="ml-2"
                                                    onClick={() => {
                                                        handleMultiSelectAgentInfo('remove', el)
                                                    }}
                                                >
                                                    <i className="bi bi-x-circle-fill  text-white"
                                                        style={{ fontSize: '13px' }}
                                                    ></i>
                                                </span>
                                            </div>

                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
                {/* {Prospect info} */}
                <div className="col-sm-6">
                    <div className="row">
                        <div className="col">
                            <label className={'form-control-label'}>{'Prospect Information To Capture'}</label>

                            {/* {checkbox} */}

                            <div className="row">

                                {prospectInfoType.map((el, index) => {
                                    return (
                                        <>
                                            <div className="form-check mb-3 ml-3">
                                                <input className="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault3"
                                                    id="flexRadioDefault3"
                                                    onChange={(e) => {
                                                        setSelectedProspectInfo([])
                                                        setProspectType(el.value)
                                                        handleProspectCheckBoxData(el.id)
                                                    }}
                                                    checked={el.check}
                                                />
                                                <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                    {el.value}
                                                </label>
                                            </div>
                                        </>
                                    )
                                })
                                }
                            </div>

                            {prospectInfoType.map((el, index) => {
                                if (el.check === true) {
                                    return (

                                        <h5 className="mt--3 text-muted">
                                            {el.value === 'Item' && el.check === true ? `Ex:First Name , E-Mail, Mobile Number, etc...` : 'Ex:Personal Information, Educational Details, etc...'}
                                        </h5>
                                    )
                                }
                            })}
                            <div className="row">
                                <div className="col">
                                    <Input value={prospectInfo.value} onChange={prospectInfo.onChange} />
                                </div>
                                <div>
                                    <Button
                                        text={'Add'}
                                        size="md"
                                        onClick={() => {
                                            handleMultiSelectProspectInfo('add', prospectInfo.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row mt--2 mb-3 ml-0">
                        {
                            selectedProspectInfo && selectedProspectInfo.map((el, index) => {
                                return (
                                    <>
                                        <div>
                                            <div className="ml-2 mt-2 bg-primary text-white pl-2 pr-1  rounded">
                                                {el}
                                                <span className="ml-2"
                                                    onClick={() => {
                                                        handleMultiSelectProspectInfo('remove', el)
                                                    }}
                                                >
                                                    <i className="bi bi-x-circle-fill  text-white"
                                                        style={{ fontSize: '13px' }}
                                                    ></i>
                                                </span>
                                            </div>

                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>
                </div>
            </div>


            <div className='row'>
                <div className='col-sm-6'>
                    <Input
                        heading={'Target'}
                        type={'textarea'}
                        style={{ resize: 'none', height: '20vh' }}
                        placeholder={'Target'}
                        value={agenda.value}
                        onChange={agenda.onChange}
                    />
                </div>
                <div className=" col-sm-6">
                    <div className='row'>
                        <div className='col'>
                            <Input heading='Key Metrics' value={expectations.value} onChange={expectations.onChange} />
                        </div>
                        <div style={{ marginTop: '30px' }}>
                            <Button
                                text={'Add'}
                                onClick={() => {
                                    handleMultiSelectDropDownData('add', expectations.value)
                                }}
                            />
                        </div>
                    </div>

                    <div className="row mt--2 mb-3 ml-0">
                        {
                            selectedExpectationsData && selectedExpectationsData.map((el, index) => {
                                return (
                                    <>
                                        <div className='pt-1'>
                                            <div className="ml-2 bg-primary text-white pl-2 pr-1  rounded">
                                                {el}
                                                <span className="ml-2"
                                                    onClick={() => {
                                                        handleMultiSelectDropDownData('remove', el)
                                                    }}
                                                >
                                                    <i className="bi bi-x-circle-fill  text-white"
                                                        style={{ fontSize: '13px' }}
                                                    ></i>
                                                </span>
                                            </div>

                                        </div>
                                    </>
                                )
                            })
                        }
                    </div>

                </div>
            </div>


            <div className='row'>
                <div className='col-sm-6'>
                    <div className='d-flex pt-2'>
                        <h3>{'Select Agents'}</h3>
                        <div className='ml-2'>
                            <Button
                                size={'sm'}
                                text={'Add'}
                                onClick={() => {
                                    getEmployeesHandler()
                                    setIsAgentsModal(true)
                                }}
                            />
                        </div>
                    </div>


                </div>
            </div>

            <div className='overflow-auto scroll-hide mt-3 mb-2'
                style={{
                    marginLeft: "-23px",
                    marginRight: "-23px"
                }}>
                {
                    selectedAgentsList && selectedAgentsList.length > 0 &&
                    <CommonTable

                        isPagination
                        tableDataSet={selectedAgentsList}
                        displayDataSet={normalizedTableData(selectedAgentsList)}

                    />
                }
                {selectedAgentsList && selectedAgentsList?.length > 0 && (
                    <Divider space={'1'} />
                )}
            </div>

            <div className='text-right'>
                <Button
                    text={'Submit'}
                    onClick={() => {
                        onAddCampaign()
                    }}
                />
            </div>

            {/**
  * Agents add modal
  */}

            <Modal
                margin={'ml-2'}
                title={'Agents'}
                isOpen={isAgentsModal}
                onClose={() => {
                    setIsAgentsModal(!isAgentsModal)
                }}>
                <div className='mt--4'>
                    {employeesList && employeesList.length > 0 ? employeesList?.map((el: any) => {
                        const isActive = selectedAgentsList?.some((item: any) => item.id === el.id)

                        return (
                            <div className='d-flex justify-content-between my-4'>
                                <div className='col-xl-6 col-sm-0 '>
                                    <h3>{el.name}</h3>
                                </div>
                                <td className="col-xl-2 col-sm-0 mt-sm-0" style={{ whiteSpace: "pre-wrap" }}>
                                    <i className={`bi bi-${isActive ? 'check-circle-fill text-success' : 'circle-fill text-light'}`}
                                        onClick={() => {
                                            addSelectedAgents(el)
                                        }}
                                    ></i>
                                </td>

                            </div>
                        )
                    }) : <NoRecordsFound />
                    }
                </div>

            </Modal>

            <Modal isOpen={productModal.visible} onClose={productModal.hide} title={'Add Designation'} >
                <div className='m-3'>


                <div className='col-8'>
                    <Input
                    heading={'name'}
                        placeholder={'name'}
                        value={title.value}
                        onChange={title.onChange}
                    />
                    </div>
                    <div className='col-8'>
                    <Input
                    heading={'description'}
                        placeholder={'description'}
                        value={description.value}
                        onChange={description.onChange}
                    />
                    </div>

       
                <div className='row   justify-content-end'>
                   
                <Button text={'Submit'} 
                onClick={(el)=>{
                  
                    handleAddProduct()
                        }}/>

                   </div>
            
                </div>

            </Modal>



        </Card>
    )
}

export { AddCampaign }