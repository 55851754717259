
import { takeLatest, put, call } from "redux-saga/effects";
import * as Api from '@Services'
import * as Action from '../Store'



function* validateUserBusinessSaga(action) {
  try {
    const response = yield call(Api.validateUserBusinessApi, action.payload.params);
    if (response.success) {

      yield put(Action.validateUserBusinessSuccess({ ...response }));
      yield call(action.payload.onSuccess(response));
    } else {

      yield put(Action.validateUserBusinessFailure(response));
      yield call(action.payload.onError(response));
    }
  } catch (error) {

    yield put(Action.validateUserBusinessFailure(error));
    yield call(action.payload.onError(error));
  }
}


/**
 * otp login
 * @param {*} action 
 */


function* otpLoginSaga(action) {
  try {

    const response = yield call(Api.otpLoginApi, action.payload.params);
    if (response.success) {
      yield put(Action.otpLoginSuccess({ ...response }));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.otpLoginFailure(response.error_message));
      yield call(action.payload.onError(response));
    }
  } catch (error) {
    yield put(Action.otpLoginFailure(error));
    yield call(action.payload.onError(error));
  }
}


/**
 * Dashboard Saga
 */

function* getDashboardSaga(action) {
  try {
    const response = yield call(Api.getDashboardApi, action.payload.params);
    if (response.success) {
      yield put(Action.getDashboardSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getDashboardFailure(response.error_message));
      yield call(action.payload.onError(response));
    }
  } catch (error) {
    yield put(Action.getDashboardFailure(error));
    yield call(action.payload.onError(error));
  }
}

/**
 * Add employee 
 */

function* addEmployeeSaga(action) {

  console.log('addEmployeeSaga');
  try {
    const response = yield call(Api.addEmployeeApi, action.payload.params);
    console.log(response);
    if (response.success) {
      yield put(Action.addEmployeeSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.addEmployeeFailure(response));
      yield call(action.payload.onError(response));
    }
  } catch (error) {

    yield put(Action.addEmployeeFailure(error));
    yield call(action.payload.onError(error));
  }
}

/**
 * Add employee photo
 */

function* addUpdateEmployeePhotoSaga(action) {
  try {

    const response = yield call(Api.updateEmployeeProfilePhotoApi, action.payload.params);
    if (response.success) {
      yield put(Action.addUpdateEmployeePhotoSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.addUpdateEmployeePhotoFailure(response.error_message));
      yield call(action.payload.onError(response));
    }
  } catch (error) {


    yield put(Action.addUpdateEmployeePhotoFailure(error));
    yield call(action.payload.onError(error));

  }
}

/**
 * Get employees
 */

function* getEmployeesSaga(action) {

  try {
    const response = yield call(Api.getEmployeesApi, action.payload.params);
    if (response.success) {
      yield put(Action.getEmployeesSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getEmployeesFailure(response.error_message));
      yield call(action.payload.onError(response));
    }
  } catch (error) {
    yield put(Action.getEmployeesFailure(error));
    yield call(action.payload.onError(error));
  }
}

/**
 * Get employee details
 */

function* getEmployeeDetails(action) {
  try {

    const response = yield call(Api.fetchEmployeeDetailsApi, action.payload.params);

    if (response.success) {
      yield put(Action.getEmployeeDetailsSuccess(response.details));
      yield call(action.payload.onSuccess(response.details));
    } else {
      yield put(Action.getEmployeeDetailsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getEmployeeDetailsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * Add client
 */

function* addClientSaga(action) {
  try {

    const response = yield call(Api.addClientApi, action.payload.params);

    if (response.success) {
      yield put(Action.addClientSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.addClientFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.addClientFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * Get clients
 */

function* getClientsSaga(action) {
  console.log(action,"pppppppppppppprrrrrrrrr")
  try {

    const response = yield call(Api.getClientsApi, action.payload.params);
console.log(response,"yyyyyyyyyyyyyyyyyyyy")
    if (response.success) {
      yield put(Action.getClientsSuccess(response.details));
      yield call(action.payload.onSuccess(response.details));
    } else {
      yield put(Action.getClientsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getClientsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


/**
 * Add campaign
 */

function* addCampaignSaga(action) {
  try {

    const response = yield call(Api.addCampaignApi, action.payload.params);

    if (response.success) {
      yield put(Action.addCampaignSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.addCampaignFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.addCampaignFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * Get clients
 */

function* getCampaignsSaga(action) {
  try {

    const response = yield call(Api.getCampaignsApi, action.payload.params);

    if (response.success) {
      yield put(Action.getCampaignsSuccess(response.details));
      yield call(action.payload.onSuccess(response.details));
    } else {
      yield put(Action.getCampaignsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getCampaignsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * get products
 */

function* getProductsSaga(action) {
  try {

    const response = yield call(Api.getProductsApi, action.payload.params);

    if (response.success) {
      yield put(Action.getProductsSuccess(response.details));
      yield call(action.payload.onSuccess(response.details));
    } else {
      yield put(Action.getProductsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getProductsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * Bulk upload client info
 */

function* bulkUploadClientInfoSaga(action) {
  try {

    const response = yield call(Api.bulkUploadClientInfoApi, action.payload.params);

    if (response.success) {
      yield put(Action.bulkUploadClientInfoSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.bulkUploadClientInfoFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.bulkUploadClientInfoFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}



function* initiateCampaignCallSaga(action) {
  try {

    const response = yield call(Api.initiateCampaignCallApi, action.payload.params);

    if (response.success) {
      yield put(Action.initiateCampaignCallSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.initiateCampaignCallFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.initiateCampaignCallFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

/**
 * Get client template
 */

function* getClientTemplateSaga(action) {
  try {

    const response = yield call(Api.getClientTemplateApi, action.payload.params);

    if (response.success) {
      yield put(Action.getClientTemplateSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getClientTemplateFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getClientTemplateFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

function* markEndCallSaga(action) {
  try {

    const response = yield call(Api.markEndCallApi, action.payload.params);

    if (response.success) {
      yield put(Action.markEndCallSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.markEndCallFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.markEndCallFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}




function* fetchAgentTraitSaga(action) {
  try {

    const response = yield call(Api.getAgentTraitApi, action.payload.params);

    if (response.success) {
      yield put(Action.fetchAgentTraitSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.fetchAgentTraitFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.fetchAgentTraitFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


//get chat conversation

function* getChatConversationSaga(action) {
  try {

    const response = yield call(Api.getChatConversationApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getChatConversationSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getChatConversationFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getChatConversationFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


//get prospect shared details

function* getProspectSharedDetailsSaga(action) {
  try {

    const response = yield call(Api.getProspectSharedDetailsApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getProspectSharedDetailsSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getProspectSharedDetailsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getProspectSharedDetailsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}





//get agent shared info generic

function* getAgentSharedInfoGenericSaga(action) {
  try {

    const response = yield call(Api.getAgentSharedInformationGenericApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getAgentSharedInfoGenericSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getAgentSharedInfoGenericFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getAgentSharedInfoGenericFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

//get prospect shared info group

function* getProspectSharedInfoGroupSaga(action) {
  try {

    const response = yield call(Api.getProspectInformationGroupApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getProspectSharedInfoGroupSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getProspectSharedInfoGroupFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getProspectSharedInfoGroupFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


//get prospect shared info item

function* getProspectSharedInfoItemSaga(action) {
  try {

    const response = yield call(Api.getProspectInformationItemApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getProspectSharedInfoItemSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getProspectSharedInfoItemFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getProspectSharedInfoItemFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


//get agent shared info group

function* getAgentSharedInfoGroupSaga(action) {
  try {

    const response = yield call(Api.getAgentInformationGroupApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getAgentSharedInfoGroupSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getAgentSharedInfoGroupFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getAgentSharedInfoGroupFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


//get prospect shared info item

function* getAgentSharedInfoItemSaga(action) {
  try {

    const response = yield call(Api.getAgentInformationItemApi, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getAgentSharedInfoItemSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getAgentSharedInfoItemFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getAgentSharedInfoItemFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}

function* registerCompanySaga(action) {
  try {

    const response = yield call(Api.registerCompanyV1Api, action.payload.params);

    if (response.success) {

      yield put(Action.registerCompanySuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.registerCompanyFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {

    yield put(Action.registerCompanyFailure("Invalid Request"));
    yield call(action.payload.onError(error));
 

  }
}


function* getAllBranchesSaga(action) {


  try {

    const response = yield call(Api.getAllBranchesV1Api, action.payload.params);

    if (response.success) {
 
     
      yield put(Action.getAllBranchesSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getAllBranchesFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getAllBranchesFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


function* getCompanyBranchDetailsSaga(action) {
  try {

    const response = yield call(Api.getCompanyBranchDetailsApi, action.payload.params);

    if (response.success) {
      yield put(Action.getCompanyBranchesDetailsSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getCompanyBranchesDetailsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getCompanyBranchesDetailsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}



function* getDesignationSaga(action) {
  try {

    const response = yield call(Api.getDesignationsV1Api, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.getDesignationsSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.getDesignationsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.getDesignationsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


function* addDesignationSaga(action) {
  try {

    const response = yield call(Api.addDesignationsV1Api, action.payload.params);

    if (response.success) {
      console.log("response", response);
      yield put(Action.addDesignationsSuccess(response));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.addDesignationsSuccess(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.addDesignationsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


/**
 * Bulk upload clients 
 */

function* bulkUploadClientsSaga(action) {
  try {

    const response = yield call(Api.bulkUploadClientsApi, action.payload.params);

    if (response.success) {
      yield put(Action.bulkUploadClientsSuccess(response.details));
      yield call(action.payload.onSuccess(response));
    } else {
      yield put(Action.bulkUploadClientsFailure(response.error_message));
      yield call(action.payload.onError(response.error_message));
    }
  } catch (error) {
    yield put(Action.bulkUploadClientsFailure("Invalid Request"));
    yield call(action.payload.onError(error));

  }
}


function* AuthSaga() {

  yield takeLatest(Action.VALIDATE_USER_BUSINESS, validateUserBusinessSaga);
  yield takeLatest(Action.OTP_LOGIN, otpLoginSaga);
  yield takeLatest(Action.GET_DASHBOARD, getDashboardSaga);
  yield takeLatest(Action.ADD_EMPLOYEE, addEmployeeSaga);
  yield takeLatest(Action.UPDATE_EMPLOYEE_PROFILE_PHOTO, addUpdateEmployeePhotoSaga);
  yield takeLatest(Action.GET_EMPLOYEES, getEmployeesSaga);
  yield takeLatest(Action.FETCH_EMPLOYEE_DETAILS, getEmployeeDetails);
  yield takeLatest(Action.ADD_CLIENT, addClientSaga);
  yield takeLatest(Action.GET_CLIENTS, getClientsSaga);
  yield takeLatest(Action.ADD_CAMPAIGN, addCampaignSaga);
  yield takeLatest(Action.GET_CAMPAIGNS, getCampaignsSaga);
  yield takeLatest(Action.GET_PRODUCTS, getProductsSaga);
  yield takeLatest(Action.BULK_UPLOAD_CLIENT_INFO, bulkUploadClientInfoSaga);
  yield takeLatest(Action.GET_CLIENT_TEMPLATE, getClientTemplateSaga);
  yield takeLatest(Action.MARK_END_CALL, markEndCallSaga);
  yield takeLatest(Action.INITIATE_CAMPAIGN_CALL, initiateCampaignCallSaga);
  yield takeLatest(Action.FETCH_AGENT_TRAIT, fetchAgentTraitSaga);
  yield takeLatest(Action.GET_CHAT_CONVERSATION, getChatConversationSaga);
  yield takeLatest(Action.GET_PROSPECT_SHARED_DETAILS, getProspectSharedDetailsSaga);
  yield takeLatest(Action.GET_AGENT_SHARED_INFO_GENERIC, getAgentSharedInfoGenericSaga);
  yield takeLatest(Action.GET_PROSPECT_SHARED_INFO_GROUP, getProspectSharedInfoGroupSaga);
  yield takeLatest(Action.GET_PROSPECT_SHARED_INFO_ITEM, getProspectSharedInfoItemSaga);
  yield takeLatest(Action.GET_AGENT_SHARED_INFO_GROUP, getAgentSharedInfoGroupSaga);
  yield takeLatest(Action.GET_AGENT_SHARED_INFO_ITEM, getAgentSharedInfoItemSaga);
  yield takeLatest(Action.REGISTER_COMPANY, registerCompanySaga);
  yield takeLatest(Action.GET_ALL_BRANCHES, getAllBranchesSaga);
  yield takeLatest(Action.GET_COMPANY_BRANCH_DETAILS, getCompanyBranchDetailsSaga);
  yield takeLatest(Action.GET_DESIGNATIONS, getDesignationSaga);
  yield takeLatest(Action.ADD_DESIGNATIONS, addDesignationSaga);
  yield takeLatest(Action.BULK_UPLOAD_CLIENTS, bulkUploadClientsSaga);

}

export default AuthSaga;