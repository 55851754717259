import { post } from '../ApiHelper';
import * as URL from '../UrlHelper'
const NODE = 2
const DJANGO = 1





export const validateUserBusinessApi = payload =>
  post(URL.VALIDATE_USER_BUSINESS, payload, {});
export const otpLoginApi = payload => post(URL.OTP_LOGIN, payload, {});
export const getDashboardApi = payload => post(URL.URL_GET_DASHBOARD, payload, {});





export const redialCampaignCallApi = (payload) => post(URL.REDIAL_CAMPAIGN_CALLS, payload, {})

export const postDataApi = (payload) => post(URL.POST_DATA, payload, {})
export const postDesignationApi = (payload) => post(URL.POST_DESIGNATION, payload, {})
export const fetchDesignationsAPi = (payload) => post(URL.FETCH_DESIGNATIONS, payload, {})
export const postDepartmentApi = (payload) => post(URL.POST_DEPARTMENT, payload, {})
export const fetchDepartmentsApi = (payload) => post(URL.FETCH_DEPARTMENTS, payload, {})

export const fetchTwilioTokenApi = (payload) => post(URL.FETCH_TWILIO_TOKEN, payload, {})
export const postInitiateInstantCallApi = (payload) => post(URL.POST_INITIATE_INSTANT_CALL, payload, {})


export const addEmployeeApi = payload =>
  post(URL.ADD_EMPLOYEE, payload, {})

export const updateEmployeeProfilePhotoApi = (payload) => post(URL.UPDATE_EMPLOYEE_PROFILE_PHOTO, payload, {})

export const getEmployeesApi = payload =>
  post(URL.GET_EMPLOYEES, payload, {})

export const fetchEmployeeDetailsApi = (payload) => post(URL.FETCH_EMPLOYEE_DETAILS_URL, payload, {})

export const addClientApi = (payload) => post(URL.ADD_CLIENT_URL, payload, {})

export const getClientsApi = (payload) => post(URL.GET_CLIENTS_URL, payload, {})

export const addCampaignApi = (payload) => post(URL.ADD_CAMPAIGN_URL, payload, {})

export const getCampaignsApi = (payload) => post(URL.GET_CAMPAIGNS_URL, payload, {})

export const getProductsApi = (payload) => post(URL.GET_PRODUCTS_URL, payload, {})

export const bulkUploadClientInfoApi = (payload) => post(URL.BULK_UPLOAD_CLIENT_INFO_URL, payload, {})

export const getClientTemplateApi = (payload) => post(URL.GET_CLIENT_TEMPLATE_URL, payload, {})

export const getInstantCallsApi = (payload) => post(URL.GET_INSTANT_CALLS, payload, {})


export const getTestNotificationApi = (payload) => post(URL.TEST_NOTIFICATION, payload, {})

export const markEndCallApi = (payload) => post(URL.MARK_END_CALL_URL, payload, {})

export const fetchRedialDirectCallApi = (payload) => post(URL.FETCH_REDIAL_DIRECT_CALL, payload, {})


export const initiateCampaignCallApi = (payload) => post(URL.INITIATE_CAMPAIGN_CALL_URL, payload, {})

export const getViewApi = (payload) => post(URL.FETCH_VIEW, payload, {}, NODE)
export const getTraitApi = (payload) => post(URL.FETCH_TRAIT, payload, {}, NODE)
export const getNextStepApi = (payload) => post(URL.FETCH_NEXTSTEP, payload, {}, NODE)
export const getBettermentAnalyticsApi = (payload) => post(URL.FETCH_BETTERMENT_ANALYTICS, payload, {}, NODE)
export const fetchExpectationApi = (payload) => post(URL.FETCH_EXPECTATION, payload, {}, NODE)
export const fetchOverAllPercentApi = (payload) => post(URL.FETCH_OVERALL_PERCENT, payload, {}, NODE)
export const fetchNextStepPersonalizedApi = (payload) => post(URL.FETCH_NEXT_STEP_PERSONALIZED_URL, payload, {}, NODE)
export const getPersonsListApi = (payload) => post(URL.GET_PERSONS_LIST_URL, payload, {}, NODE)

export const getAgentTraitApi = (payload) => post(URL.GET_AGENT_TRAIT, payload, {}, NODE)
export const getChatConversationApi = (payload) => post(URL.GET_CHAT_CONVERSATION_URL, payload, {}, NODE)
export const getProspectSharedDetailsApi = (payload) => post(URL.GET_PROSPECT_SHARED_DETAILS_URL, payload, {}, NODE)

export const getAgentSharedInformationGenericApi = (payload) => post(URL.GET_AGENT_SHARED_INFORMATION_GENERIC_URL, payload, {}, NODE)

export const getProspectInformationGroupApi = (payload) => post(URL.GET_PROSPECT_INFORMATION_GROUP_URL, payload, {}, NODE)
export const getProspectInformationItemApi = (payload) => post(URL.GET_PROSPECT_INFORMATION_ITEM_URL, payload, {}, NODE)

export const getAgentInformationGroupApi = (payload) => post(URL.GET_AGENT_INFORMATION_GROUP_URL, payload, {}, NODE)
export const getAgentInformationItemApi = (payload) => post(URL.GET_AGENT_INFORMATION_ITEM_URL, payload, {}, NODE)

//I DO IT
export const registerCompanyV1Api = (payload) => post(URL.REGISTER_COMPANY, payload, {})
export const  getAllBranchesV1Api= (payload) => post(URL.GET_ALL_BRANCHES, payload, {})
export const getCompanyBranchDetailsApi = (payload) => post(URL.GET_COMPANY_BRANCH_DETAILS, payload, {})
export const getDesignationsV1Api = (payload) => post(URL.GET_DESIGNATIONS, payload, {})
export const addDesignationsV1Api = (payload) => post(URL.ADD_DESIGNATIONS, payload, {})
export const addProductApi = (payload) => post(URL.ADD_PRODUCT, payload, {})

export const bulkUploadClientsApi = (payload) => post(URL.BULK_UPLOAD_CLIENTS_URL, payload, {})















