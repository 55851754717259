import React, { useState } from 'react';
import './StickyNote.css';
import { Image } from '@Components'
import { icons } from '@Assets';
import { settingCallNotes } from '@Redux';
import { useDispatch } from 'react-redux';

interface props{
    onInputOnChange?:(event)=>void;
    value?:string
}

const StickyNote = ({onInputOnChange,value}:props) => {

    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const dispatch = useDispatch();

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };
    return (
        <div className="StickyNote ">
            <div className='row'>
                <Image src={icons.notes} height={20} width={20} />
                <h5 className='ml-2'>{'Call Notes:'}</h5>
            </div>

            <textarea
                style={{ resize: 'none' }}
                className="StickyNoteContent overflow-auto overflow-hide"
                value={value}
                onChange={(e)=>{
                    dispatch(settingCallNotes(e.target.value))
                    if(onInputOnChange)
                    onInputOnChange(e)
                }}
            />
        </div>
    );
};

export { StickyNote };
