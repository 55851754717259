import React from 'react';
import { Button as RSButton } from 'reactstrap';
import { ButtonProps } from './interfaces';
import { Image } from "@Components"

function Button({
  text, color = 'primary', variant = 'default', size = 'md', icon, onEnter, height, width,bgColor, ...rest
}: ButtonProps) {

  return (
    <>
      {variant === 'default' && <RSButton type={'button'} className='rounded-pill px-3 custom-gradient border-0' size={size} color={color} {...rest} >{text}</RSButton>}

      {(variant === 'icon' || variant === 'icon-with-text') &&
        <RSButton type={'button'} size={size} className={'btn-icon'} color={color} {...rest} 
        
        >
          {
            <span className={`btn-inner--icon ${variant === 'icon-with-text' && 'mr-1'}`}>
              <i className='ni ni-atom' />
            </span>
          }
          {
            variant === 'icon-with-text' &&
            <span 
            className={'btn-inner--text'}>{text}</span>
          }
        </RSButton>
      }

      {variant === 'icon-rounded' &&
        <RSButton type={'button'} size={size} className={'btn-icon-only rounded-circle d-flex align-items-center justify-content-center'} color={color}  {...rest} >
          {/* <span className={'btn-inner--icon'}>
            <i className='ni ni-atom' />
          </span> */}
          <Image src={icon} height={height || 25} width={width || 25} />
        </RSButton>
      }
    </>
  )
}
export { Button };
