import { Card, NoDataFound, ProgressCircleChart, ProgressTrack, Spinner } from '@Components';
import React, { useEffect, useState } from 'react'
import { useWindowDimensions, useDynamicHeight } from '@Hooks';
import { useSelector } from 'react-redux';
import { StatsCard, OverallProgressCard } from '@Modules';
function View() {
    const { viewDetails, analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);
    const { extractions, conversionPercent, personName } = viewDetails || {}

    console.log("pppppppppp", personName);

    const dynamicHeight: any = useDynamicHeight()

    const [selectedData, setSelectedData] = useState<any>(extractions && extractions.length > 0 ? [extractions[0]] : [])

    const { width, height } = useWindowDimensions()

    useEffect(() => {
        return () => {
            setSelectedData(extractions && extractions.length > 0 ? [extractions[0]] : [])
        }
    }, [])

    return (
        <>
            {extractions && extractions.length > 0 ? (
                <div className='row '>

                    <div className='col-xl-4 col-md-12 ml--1'>
                        {conversionPercent && <OverallProgressCard isShowAsOnclick isShowTwoBar={false} heading={`${'Client'}'s ${'View'}`} progress={analyticsOverAllPercentage?.overAllPercentage} data={extractions} lineProgressColor={'primary'}
                            onItemClick={(item) => {
                                const filteredData = extractions.filter((el) => el.emotion_perception === item.emotion_perception)
                                setSelectedData(filteredData)

                            }}
                        />}
                    </div>

                    <div className='col-xl-8 col-md-12 overflow-auto overflow-hide ' style={{ overflowY: 'scroll' }}>
                        <div className='row'>
                            {
                                selectedData && selectedData.length > 0 && selectedData.map((item) => {
                                    const { emotion_perception, reason, relative_percent, reference, percentage } = item
                                    return (
                                        <div className='col-xl-12 col-md-12 mb--3'>
                                            <StatsCard title={emotion_perception} subTitle={reason} personsName={personName} progress={percentage} data={reference} progressBarData={extractions} reference='References' />

                                        </div>
                                    );
                                })

                            }
                        </div>
                    </div>

                </div>
            ) : <NoDataFound />}
        </>
    )
}

export { View }
