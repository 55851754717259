import React, { useEffect, useState } from 'react';
import { Device } from '@twilio/voice-sdk';
import { Button, Card, Image, Input, StickyNote } from '@Components';
import { log } from 'util';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTwilioToken, getClients, getDirectCallDetails, isOpenDirectCallModal, markEndCall, postInitiateInstantCallSuccess, settingCallNotes } from '@Redux';
import { icons } from '@Assets';
import { Row, Col, CardHeader, CardBody, NavItem, Nav, NavLink } from 'reactstrap';
import classNames from 'classnames';
import "./AnimatedImageFrame.scss";
import axios from 'axios';
import { SERVER } from '@Services';



interface DirectCallProps {

    onEndCall?: () => void;


}

const DirectCall = ({ onEndCall }: DirectCallProps) => {
    const { instantCallData, directCallData, isOpenDirectCall, currentCampaignDetails, campaignsList } = useSelector((state: any) => state.AppReducer);
    const imageClasses = classNames("animated-image", { blink: true });
    const { callNotes } = useSelector((state: any) => state.AuthReducer);
    let NOTES = 'NOTES'


    const [device, setDevice] = useState<Device | null>(null);
    const [phoneNumber, setPhoneNumber] = useState(directCallData ? directCallData?.to_number : '');
    const [initializeDevice, setInitializeDevice] = useState(true)
    const [toPersonName, setToPersonName] = useState<any>(directCallData ? directCallData?.to_person : 'Tamil')
    const dispatch = useDispatch();
    const [callDetails, setCallDetails] = useState<any>()
    const [callAcceptTime, setCallAcceptTime] = useState<any>(0)
    const [callDuration, setCallDuration] = useState<any>()
    const [start, setStart] = useState(false)
    const [notes, setNotes] = useState('')

    const [token, setToken] = useState<any>(instantCallData?.token)

    const [readyCall, setReadyCall] = useState<any>(false)


    useEffect(() => {

        // dispatch(isOpenDirectCallModal(false))
        if (instantCallData?.token) {
            initializeTwilioDevice(instantCallData?.token)
        }

    }, [])


    useEffect(() => {
        if (callNotes) {
            setNotes(callNotes)
        }
    }, [callNotes])



    const onEndCallApi = async () => {
        const params = {
            id: instantCallData?.id,
            note: await localStorage.getItem(NOTES)
        }


        handleClear()
        dispatch(markEndCall({
            params,
            onSuccess: (success: any) => () => {
                getClientsHandler()
                dispatch(settingCallNotes(undefined))


            },
            onError: (error: any) => () => {

            },
        }))
    }



    const handleClear = () => {
        setTimeout(() => {
            localStorage.setItem(NOTES, '')
        }, 2000);
    }

    const getClientsHandler = () => {
        const params = {
            campaign_id: currentCampaignDetails?.id
        }
        dispatch(
            getClients({
                params,
                onSuccess: (response: any) => () => {
                },
                onError: (error) => () => {
                },
            })
        )
    }



    const reset = async (device) => {
        await onEndCallApi()
        dispatch(getDirectCallDetails(undefined))
        setPhoneNumber(undefined)
        setToPersonName('')
        setDevice(null)
        setCallAcceptTime(0)
        dispatch(postInitiateInstantCallSuccess({ details: undefined }))
        setStart(false)
        dispatch(isOpenDirectCallModal(false))
        device.disconnectAll();
        device.unregister()
        device!.destroy()
    }


    useEffect(() => {

        if (device !== null && device) {

            device.on('error', (error: any) => {
                ////// console.log('Twilio.Device Error:', error.message);
                // alert('There is Problem in Initialize please try again after some times...')
            });

            device.on('incoming', (call: any) => {
                ////// console.log('Twilio.Device Error:2')
                handleIncomingCall(call);
            });

            if (directCallData) {
                const handleSuccessfulRegistration = () => {
                    ////// console.log('The device is ready to receive incoming calls.1')
                    setTimeout(() => {
                        makeOutgoingCall(notes)
                    }, 1000)

                    ////// console.log('The device is ready to receive incoming calls.')
                }
                ////// console.log('Twilio.Device Error:3')
                device.on('registered', handleSuccessfulRegistration);
            }



            // Register the device
            device.register();

        }

    }, [device])






    const initializeTwilioDevice = (token: string) => {
        ////// console.log('token', token)
        const deviceOptions: any = {
            logLevel: 1,
            codecPreferences: ['opus', 'pcmu'],
            audioContext: new AudioContext(),
            region: 'us1' // Add the region property
        };

        // Instantiate new Twilio.Device
        const twilioDevice = new Device(token);
        twilioDevice.updateOptions(deviceOptions)
        setDevice(twilioDevice)

    };

    const handleIncomingCall = (call: any) => {
        ////// console.log('=====================>Incoming call from', call.parameters.From);
        // Handle incoming call logic here
        // ...
    };




    const makeOutgoingCall = async (notes) => {

        console.log(notes + '======notes');


        if (!device) {
            ////// console.log('Device is not initialized');
            // setReadyCall(true)

            return;
        }
        else if (phoneNumber) {
            ////// console.log('Twilio.Device Error:7')
            if (phoneNumber.trim() === '') {
                alert('Please enter a phone number to call.');
                return;
            }

            let params: any = {
                To: phoneNumber,
                reference_id: instantCallData?.id
            }

            let call = await device.connect({
                params: params,
            })

            setCallDetails(call)

            call.on('accept', () => {
                ////// console.log('Twilio.Device Error:11')
                setReadyCall(true)
                setStart(true)
                setCallAcceptTime(new Date().getTime())

                ////// console.log('Call accepted');
            });

            call.on('accepted', () => {
                ////// console.log('vuuuuuuuuuuuuuuuuuuu')
            }
            )

            call.on('disconnect', () => {
                // self 1
                // p2 2


                if (device) {


                    console.log('Twilio.Device Error:8')
                    console.log(notes + '======notes');

                    reset(device)


                }
                else {
                    console.log('Twilio.Device Error:82')
                }

            });

            call.on('cancel', () => {
                ////// console.log('Call canceled');
            });
        }
    };

    ////// console.log('Call disconnected', phoneNumber);


    const handleHangup = async () => {
        if (callDetails) {
            callDetails.disconnect();
        }
    };

    console.log("00000000000", start)

    useEffect(() => {
        if (start && callAcceptTime) {
            setInterval(() => {
                let count = 0

                var countDownDate = callAcceptTime
                var now = new Date().getTime();
                var distance = now - countDownDate;

                var days1 = Math.floor(distance / (1000 * 60 * 60 * 24));
                var hours1 = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var minutes1 = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds1 = Math.floor((distance % (1000 * 60)) / 1000);
                var duration = days1 !== 0 ? days1 + "d " + hours1 + "h " + minutes1 + "m " + seconds1 + "s " : hours1 !== 0 ? hours1 + "h " + minutes1 + "m " + seconds1 + "s " : minutes1 !== 0 ? minutes1 + "m " + seconds1 + "s " : seconds1 !== 0 ? seconds1 + "s " : 0 + 's'
                setCallDuration(duration)
            }, 1000)
        }
    }, [start, callDuration])


    console.log("calloooooo", notes)



    return (
        <div className='container-fluid '>
            <div className='row justify-content-between align-items-center '>
                <div className='col-sm-6 mt-5 p-0'>

                    <div className="card-profile">
                        <Row className="justify-content-center">
                            <Col className="order-lg-2 mt-5 ">
                                <div className="card-profile-image">
                                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <img
                                            alt="..."
                                            className={`rounded-circle ${imageClasses}`}
                                            src={icons.call_icon3}
                                        />
                                    </a>
                                </div>
                                <div className="card-profile-image">

                                </div>
                            </Col>
                        </Row>

                        <CardBody className="mt-5">
                            <div className="text-center ">
                                <h5 className="h3">
                                    {toPersonName}
                                </h5>
                                <div className="h5 font-weight-300 mt-3">
                                    <i className="ni location_pin mr-2" />
                                    {callDuration ? 'Call Duration' : ''}
                                    <div className='h5 font-weight-300 mt--1'>
                                        {callDuration ? callDuration : ''}
                                    </div>
                                </div>

                                <div className='mt-4'>
                                    <Button
                                        disabled={!readyCall}
                                        text={'Hang up'}
                                        onClick={() => {
                                            if (onEndCall) {
                                                // onEndCallApi()
                                                onEndCall()
                                                setStart(true)
                                                handleHangup()
                                                setStart(false)
                                                dispatch(getDirectCallDetails(undefined))
                                                dispatch(isOpenDirectCallModal(false))

                                            }
                                        }} />
                                </div>

                            </div>
                        </CardBody>

                    </div>

                </div>
                <div className=" col-sm-6 p-0 m-0">
                    <StickyNote value={callNotes} onInputOnChange={(event) => {
                        console.log('camlled');

                        // setNotes(event.target.value)
                        localStorage.setItem(NOTES, event.target.value)
                        dispatch(settingCallNotes(event.target.value))
                    }} />
                </div>
            </div>


        </div>

    );
};

export { DirectCall }