import {
    LOGIN_USER,
  
  } from '../ActionTypes';


  export const loginUser = (params: any) => {

    return {
      type: LOGIN_USER,
      payload: params
    }
  }