import { Card, CommonTable, NoDataFound } from '@Components';
import React from 'react'
import { useSelector } from 'react-redux';

function AgentSharedItemInformation() {


  const { agentSharedInfoGroup, agentSharedInfoItem } = useSelector((state: any) => state.AuthReducer);

  const normalizedTableData = (data: any) => {
    return data && data.length > 0 && data?.map((el: any) => {

      const { informationValue, informationkey, title, value, key } = el
      return {
        'Key': title ? title : '-',
        'Value': value ? value : '-'
      }
    }
    )
  }


  return (

    <div className='mt--6'>

      {
        agentSharedInfoItem && agentSharedInfoItem.info && agentSharedInfoItem.info.length > 0 ?
          <Card className="mr--1">

            <div className='overflow-auto scroll-hide' style={{ marginLeft: '-24px', marginRight: '-24px' }}>
              {/* <h3 className='text-primary ml-4'>{key.type}</h3> */}

              <CommonTable
                isPagination
                tableDataSet={agentSharedInfoItem.info}
                displayDataSet={normalizedTableData(agentSharedInfoItem.info)}

              />
            </div>
          </Card>

          :
          <NoDataFound />
      }
    </div>
  )
}

export { AgentSharedItemInformation }