import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {
    fetchDepartments,
    fetchDesignations,
    getEmployees,
    settingSelectedEmployeeItem
} from "@Redux";
import { useDropDown, useLoader, useModal, useNavigation } from '@Hooks';
import { Button, Card, Checkbox, CommonTable, DropDown, Modal, NoDataFound, NoRecordsFound, Spinner, showToast, Image } from '@Components';
import { translate } from "@I18n";
import { ROUTES } from '@Routes';
import { paginationHandler, getImageUrl, getPhoto, convertToUpperCase, getImageUrlJango } from '@Utils';
import { image } from '@Assets';
import { icons } from '@Assets';

function EmployeesList() {
    const dispatch = useDispatch();
    const { goTo } = useNavigation();
    const { employeesList } = useSelector((state: any) => state.AuthReducer);

    const [departmentsDropdownData, setDepartmentsDropdownData] = useState([])
    const [designationsDropdownData, setDesignationsDropdownData] = useState([])

    const department = useDropDown({})
    const designation = useDropDown({})

    const [selectedEmployeeItem, setSelectedEmployeeItem] = useState<any>(undefined)

    const isLoading = useLoader(false)

    console.log("employeesList", employeesList);



    useEffect(() => {
        getEmployeesHandler()
        // getDepartmentData()
        // getDesignationsData()
    }, [])

    /*  useEffect(() => {
          if (department.value || designation.value) {
              getEmployeesHandler()
          }
      }, [department.value, designation.value]) */

    const getEmployeesHandler = () => {
        const params = {

        }
        isLoading.show()
        dispatch(
            getEmployees({
                params,
                onSuccess: (response: any) => () => {
                    isLoading.hide()
                },
                onError: (error) => () => {
                    isLoading.hide()
                },
            })

        )
    }

    const getDepartmentData = () => {

        const params = {
        };

        dispatch(
            fetchDepartments({
                params,
                onSuccess: (success) => () => {

                    if (success.details.data && success.details.data?.length > 0) {
                        let allObj = { id: '-1', name: 'All', text: 'All' }
                        let updatedData: any = [allObj, ...success.details.data]

                        setDepartmentsDropdownData(updatedData)
                    }
                },
                onError: () => () => {
                },
            })
        );
    }

    const getDesignationsData = () => {

        const params = {
        };

        dispatch(
            fetchDesignations({
                params,
                onSuccess: (success: any) => () => {

                    if (success.details.data && success.details.data?.length > 0) {
                        let allObj = { id: '-1', name: 'All', text: 'All' }
                        let updatedData: any = [allObj, ...success.details.data]
                        setDesignationsDropdownData(updatedData)
                    }
                },
                onError: () => () => {
                },
            })
        );
    }

    const normalizedEmployeesTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {
            console.log("getPhoto(el?.photo)", getPhoto(el?.photo))

            return {
                "": <div>
                    <Image variant={'rounded'} src={el?.photo ? getImageUrlJango(el?.photo) : icons.profile} />
                </div>,
                Name: convertToUpperCase(el?.name),
                'Mobile number': el?.mobile_number,
                // department: el?.department ? el?.department : '-',
                // designation: el?.designation ? el?.designation : '-',
                Action: <Button
                    text={'EDIT'}
                    size='sm'
                    onClick={(e) => {
                        e.stopPropagation()
                        manageRoutingHandler(el)
                        dispatch(settingSelectedEmployeeItem(el))
                    }} />

            }
        }
        )
    }

    const manageRoutingHandler = (item) => {
        console.log("items====>",item)
        item ? dispatch(settingSelectedEmployeeItem(item)) : dispatch(settingSelectedEmployeeItem(item))
        goTo(ROUTES['Dashboard'].addEmployee)
        console.log("selectedEmployeeItem", selectedEmployeeItem)
    }


    function getDropDownDisplayData(data: any) {
        return data && data?.map((item: any) => {
            return {
                ...item,
                text: item.name
            }
        })
    }



    return (
        <div className='m-3'>
            <Card >
                <div className='d-flex justify-content-between'>
                    {/* <div className='h4 text-muted'>
                        {company_branch?.name}
                    </div> */}
                    <div>
                        <h3>{'Agents List'}</h3>
                    </div>
                    <div>
                        <Button
                            size={'sm'}
                            text={'Add Agent'}
                            onClick={() => {
                                manageRoutingHandler(undefined)
                                // goTo(ROUTES['user-company-module']['add-user'])
                            }}
                        />
                    </div>

                </div>

                {/* <div className='row mt-3'>
                    <div className='col-sm-3'>
                        <DropDown
                            heading={translate("common.department")}
                            placeHolder={translate("common.department")!}
                            data={getDropDownDisplayData(departmentsDropdownData)}
                            selected={department.value}
                            value={department.value}
                            onChange={department.onChange}
                        />
                    </div>

                    <div className='col-sm-3'>
                        <DropDown
                            heading={'Designation'}
                            placeHolder={'Designation'}
                            data={getDropDownDisplayData(designationsDropdownData)}
                            selected={designation.value}
                            value={designation.value}
                            onChange={designation.onChange}
                        />
                    </div>
                </div> */}

                {isLoading.loader &&
                    <div className='d-flex justify-content-center'>
                        <Spinner />
                    </div>
                }

                {!isLoading.loader && (
                    <div className='overflow-auto scroll-hide'
                        style={{
                            marginLeft: "-23px",
                            marginRight: "-23px"
                        }}
                    >

                        {employeesList && employeesList.length > 0 ?
                            <CommonTable

                                isPagination
                                tableDataSet={employeesList}
                                displayDataSet={normalizedEmployeesTableData(employeesList)}

                            />
                            :
                            <NoDataFound />
                        }

                    </div>
                )}

            </Card>

        </div>
    )
}

export { EmployeesList }