import { Card, CommonTable, NoDataFound } from '@Components';
import React from 'react'
import { useSelector } from 'react-redux';

function ProspectSharedGroupInformation() {

  console.log("entereddddddddddddd");

  const { prospectSharedInfoGroup, prospectSharedInfoItem } = useSelector((state: any) => state.AuthReducer);
  console.log("prospectSharedInfoGroup");


  const normalizedTableData = (data: any, type: string) => {
    return data && data.length > 0 && data?.map((el: any) => {

      console.log("vvvvvvvvvvv", el);


      const titleHead = type
      let formattedString = titleHead.replace(/([A-Z])/g, ' $1');

      const { informationValue, informationkey, title, value } = el
      return {
        'key': title ? title : '-',
        'value': value ? value : '-'
      }
    }
    )
  }



  return (


    <div className='mt--2'>
      {


        prospectSharedInfoGroup && prospectSharedInfoGroup.groupItems && prospectSharedInfoGroup?.groupItems.length > 0 ?

          prospectSharedInfoGroup?.groupItems.map((el) => {
            return (
              <Card className="mr--1">
                <div className='overflow-auto scroll-hide' style={{ marginLeft: '-24px', marginRight: '-24px' }}>
                  <h3 className='ml-4 text-primary'>{el?.type}</h3>
                  <CommonTable
                    tableDataSet={el?.collections}
                    displayDataSet={normalizedTableData(el?.collections, '')}

                  />
                </div>
              </Card>
            )
          })

          :
          <NoDataFound />
      }

    </div>
  )
}

export { ProspectSharedGroupInformation }