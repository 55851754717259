import { USER_LOGIN_DETAILS, RESTORE_APP, HANDLING_API, FCM_TOKEN, RESET_APP } from '../ActionTypes';
import { AppStateProp } from '../../Interfaces';
import * as ActionTypes from '../ActionTypes'


const initialState: AppStateProp = {

  userLoggedIn: false,
  loginDetails: undefined,
  isSync: { view: false, nextStep: false, traits: false, betterment: false, nextStepPersonalized: false, prospectSharedInfoGeneric: false },
  token: undefined,
  viewDetails: undefined,
  traitDetails: undefined,
  nextStep: undefined,
  bettermentDetails: undefined,
  expectationDetails: undefined,
  loading: false,
  designationData: undefined,
  departmentData: undefined,
  twilioTokenData: undefined,
  instantCallData: undefined,
  referenceId: undefined,
  directCallData: undefined,
  getInstantCallData: undefined,
  isOpenDirectCall: false,
  analyticsOverAllPercentage: undefined,
  testNotification: undefined,
  redialDirectCall: undefined,
  nextStepPersonalized: undefined,
  bettermentPersonsList: undefined,
  addProductList: undefined,
  isDirectCalls: false,
  redialCampaignCalls: undefined
};

const AppReducer = (state = initialState, action: any) => {

  switch (action.type) {

    case RESET_APP:
      state = initialState;
      break;

    case RESTORE_APP:
      state = initialState;
      break;
    case USER_LOGIN_DETAILS:
      state = {
        ...state,
        loginDetails: { ...state.loginDetails, ...action.payload },
      };
      break;
    case HANDLING_API:
      state = {
        ...state,
        isSync: action.payload,
      };
      break;
    case FCM_TOKEN:
      state = {
        ...state,
        token: action.payload
      };
      break;

    case ActionTypes.GET_VIEW:
      state = {
        ...state,
        viewDetails: undefined,
      };
      break;
    case ActionTypes.GET_VIEW_SUCCESS:
      state = {
        ...state,
        viewDetails: action.payload.details,
      };
      break;

    case ActionTypes.GET_VIEW_FAILURE:
      state = { ...state, viewDetails: action.payload };
      break;

    case ActionTypes.GET_TRAIT:
      state = {
        ...state,
        traitDetails: undefined,
      };
      break;
    case ActionTypes.GET_TRAIT_SUCCESS:
      state = {
        ...state,
        traitDetails: action.payload.details,
      };
      break;
    case ActionTypes.GET_TRAIT_FAILURE:
      state = { ...state, traitDetails: action.payload };
      break;

    case ActionTypes.GET_NEXTSTEP:
      state = {
        ...state,
        nextStep: undefined,
      };
      break;
    case ActionTypes.GET_NEXTSTEP_SUCCESS:
      state = {
        ...state,
        nextStep: action.payload.details,
      };
      break;
    case ActionTypes.GET_NEXTSTEP_FAILURE:
      state = { ...state, nextStep: action.payload };
      break;

    case ActionTypes.GET_BETTERMENT_ANALYTICS:
      state = {
        ...state,
        bettermentDetails: undefined,
      };
      break;
    case ActionTypes.GET_BETTERMENT_ANALYTICS_SUCCESS:
      state = {
        ...state,
        bettermentDetails: action.payload.details,
      };
      break;
    case ActionTypes.GET_BETTERMENT_ANALYTICS_FAILURE:
      state = { ...state, bettermentDetails: action.payload };
      break;

    case ActionTypes.IS_LOADING:
      state = {
        ...state,
        loading: action.payload
      };
      break;



    // add data


    case ActionTypes.POST_DATA:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DATA_SUCCESS:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DATA_FAILURE:
      state = { ...state, };
      break;


    // add designation
    case ActionTypes.POST_DESIGNATION:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DESIGNATION_SUCCESS:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DESIGNATION_FAILURE:
      state = { ...state, };
      break;


    // get designation
    case ActionTypes.FETCH_DESIGNATIONS:
      state = {
        ...state
      };
      break;
    case ActionTypes.FETCH_DESIGNATIONS_SUCCESS:
      console.log("9999999999999999999", action.payload.details)
      state = {
        ...state, designationData: action.payload.details
      };
      break;
    case ActionTypes.FETCH_DESIGNATIONS_FAILURE:
      state = { ...state };
      break;


    // add department
    case ActionTypes.POST_DEPARTMENT:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DEPARTMENT_SUCCESS:
      state = {
        ...state,
      };
      break;
    case ActionTypes.POST_DEPARTMENT_FAILURE:
      state = { ...state, };
      break;


    // get department
    case ActionTypes.FETCH_DEPARTMENTS:
      state = {
        ...state, departmentData: undefined
      };
      break;
    case ActionTypes.FETCH_DEPARTMENTS_SUCCESS:
      state = {
        ...state, departmentData: action.payload.details
      };
      break;
    case ActionTypes.FETCH_DEPARTMENTS_FAILURE:
      state = { ...state, departmentData: undefined };
      break;

    case ActionTypes.FETCH_EXPECTATION:
      state = {
        ...state,
        expectationDetails: undefined,
      };
      break;

    case ActionTypes.FETCH_EXPECTATION_SUCCESS:

      console.log("reducerexpectation------->", action.payload.details)
      state = {
        ...state,
        expectationDetails: action.payload.details,
      };
      break;

    case ActionTypes.FETCH_EXPECTATION_FAILURE:
      state = { ...state, expectationDetails: action.payload };
      break;

    // get twilio token
    case ActionTypes.FETCH_TWILIO_TOKEN:
      state = {
        ...state, twilioTokenData: undefined
      };
      break;
    case ActionTypes.FETCH_TWILIO_TOKEN_SUCCESS:
      state = {
        ...state, twilioTokenData: action.payload?.details
      };
      break;
    case ActionTypes.FETCH_TWILIO_TOKEN_FAILURE:
      state = { ...state, twilioTokenData: undefined };
      break;




    // post initiate instant call
    case ActionTypes.POST_INITIATE_INSTANT_CALL:
      state = {
        ...state, instantCallData: undefined
      };
      break;
    case ActionTypes.POST_INITIATE_INSTANT_CALL_SUCCESS:
      state = {
        ...state, instantCallData: action.payload.details
      };
      break;
    case ActionTypes.POST_INITIATE_INSTANT_CALL_FAILURE:
      state = { ...state, instantCallData: undefined };
      break;

    case ActionTypes.REFERENCE_ID:
      state = { ...state, referenceId: action.payload };
      break;



    case ActionTypes.GET_DIRECT_CALL_DATA:
      console.log("99999999999", action.payload)
      state = {
        ...state, directCallData: action.payload
      };
      break;

    case ActionTypes.GET_INSTANT_CALL:
      state = {
        ...state, getInstantCallData: undefined
      };
      break;
    case ActionTypes.GET_INSTANT_CALL_SUCCESS:
      state = {
        ...state, getInstantCallData: action.payload.details.data
      };
      break;
    case ActionTypes.GET_INSTANT_CALL_FAILURE:
      state = { ...state, getInstantCallData: undefined };
      break;


    case ActionTypes.IS_OPEN_DIRECT_CALL_MODAL:
      console.log("99999999999", action.payload)
      state = {
        ...state, isOpenDirectCall: action.payload
      };
      break;

    case ActionTypes.IS_DIRECT_CALL_MODAL:
      console.log("99999999999", action.payload)
      state = {
        ...state, isDirectCalls: action.payload
      };
      break;


    case ActionTypes.FETCH_OVERALL_PERCENT:
      state = {
        ...state,
        analyticsOverAllPercentage: undefined,
      };
      break;
    case ActionTypes.FETCH_OVERALL_PERCENT_SUCCESS:
      state = {
        ...state,
        analyticsOverAllPercentage: action.payload.details,
      };
      break;

    case ActionTypes.FETCH_OVERALL_PERCENT_FAILURE:
      state = { ...state, analyticsOverAllPercentage: action.payload };
      break;




    case ActionTypes.FETCH_TEST_NOTIFICATION:
      state = {
        ...state, testNotification: undefined
      };
      break;
    case ActionTypes.FETCH_TEST_NOTIFICATION_SUCCESS:
      state = {
        ...state, testNotification: action.payload
      };
      break;
    case ActionTypes.FETCH_TEST_NOTIFICATION_FAILURE:
      state = { ...state, testNotification: undefined };
      break;


    //REDIAL direct call

    case ActionTypes.FETCH_REDIAL_DIRECT_CALL:
      state = {
        ...state, redialDirectCall: undefined
      };
      break;
    case ActionTypes.FETCH_REDIAL_DIRECT_CALL_SUCCESS:
      state = {
        ...state, redialDirectCall: action.payload
      };
      break;
    case ActionTypes.FETCH_REDIAL_DIRECT_CALL_FAILURE:
      state = { ...state, redialDirectCall: undefined };
      break;

    //Next step personalized

    case ActionTypes.FETCH_NEXT_STEP_PERSONALIZED:
      state = {
        ...state
      };
      break;
    case ActionTypes.FETCH_NEXT_STEP_PERSONALIZED_SUCCESS:
      state = {
        ...state, nextStepPersonalized: action.payload.details
      };
      break;
    case ActionTypes.FETCH_NEXT_STEP_PERSONALIZED_FAILURE:
      state = { ...state, nextStepPersonalized: undefined };
      break;


    //Get persons list

    case ActionTypes.GET_PERSONS_LIST:
      state = {
        ...state
      };
      break;
    case ActionTypes.GET_PERSONS_LIST_SUCCESS:
      state = {
        ...state, bettermentPersonsList: action.payload.details
      };
      break;
    case ActionTypes.GET_PERSONS_LIST_FAILURE:
      state = { ...state, bettermentPersonsList: undefined };
      break;
    //ADD PRODUCT LIST

    case ActionTypes.ADD_PRODUCT:
      state = {
        ...state
      };
      break;
    case ActionTypes.ADD_PRODUCT_SUCCESS:
      state = {
        ...state, addProductList: action.payload.details
      };
      break;
    case ActionTypes.ADD_PRODUCT_FAILURE:
      state = { ...state, addProductList: undefined };
      break;

    // redialCampaigncall


    case ActionTypes.REDIAL_CAMPAIGN_CALL:
      console.log(state,"ssssssssssss1111")
      state = { ...state ,
   
      };
      break;
    case ActionTypes.REDIAL_CAMPAIGN_CALL_SUCCESS:
      console.log(state,"ssssssssssss122222222222")
      state = {
        ...state,
        redialCampaignCalls:action.payload.details
      };
      break;
    case ActionTypes.REDIAL_CAMPAIGN_CALL_FAILURE:
      console.log(state,"ssssssssssss1fffffffffff")
      state = {
        ...state,
        redialCampaignCalls:undefined
      };
      break;




    default:
      state = state;
      break;

  }

  return state;
};

export { AppReducer };
