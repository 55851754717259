import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {

    bulkUploadClientInfo,
    bulkUploadClients,
    fetchRedialDirectCall,
    fetchTestNotification,
    getClientTemplate,
    getClients,
    getDirectCallDetails,
    initiateCampaignCall,
    isOpenDirectCallModal,
    postInitiateInstantCallSuccess,
    setReferenceId,
    settingCallNotes,
    settingSelectedCallDetails,
    clientsData,
    redialCampaignCall,

} from "@Redux";
import { useLoader, useNavigation } from '@Hooks';
import { Button, Card, CommonTable, NoDataFound, Spinner, FileUploadExcel, showToast, Back, Image, StickyNote, CsvFile, } from '@Components';
import { ROUTES } from '@Routes';
import { convertToUpperCase, urlDownloader } from '@Utils';
import { Badge, Modal, Button as RSButton } from 'reactstrap';
import { DirectCall } from '@Modules';
import { FileDownloadButton } from '@Components//Core/FileDownload';
import { icons, image } from '@Assets';



function ClientsList() {
    const dispatch = useDispatch();
    const { goTo } = useNavigation();
    const { clientsList, currentCampaignDetails, campaignsList } = useSelector((state: any) => state.AuthReducer);
    const { isOpenDirectCall, callNotes } = useSelector((state: any) => state.AppReducer);

    const isLoading = useLoader(false)
    const [clientsBulkUploadData, setClientsBulkUploadData] = useState<any>(undefined)
    const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState<boolean>(false)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [clientData, setClientData] = useState<any>()
    const [notes, setNotes] = useState('')
    // const [isOpenNewCall, setIsOpenNewCall] = useState<any>(false)
    // const [clientsList, clientsList] = useState<any>([])

    console.log(isBulkUploadModalOpen, "isBulkUploadModalOpen")

    useEffect(() => {
        if (campaignsList && campaignsList?.length > 0) {
            getClientsHandler()
        }
    }, [currentCampaignDetails])

    // useEffect(() => {
    //     if (isOpenDirectCall) {
    //         setIsOpenNewCall(false)
    //     }
    // }, [isOpenDirectCall])

    useEffect(() => {
        const interval = setInterval(() => {
            if (campaignsList && campaignsList?.length > 0) {
                getClientsHandler2()
            }

        }, 20000)
        return () => {
            clearInterval(interval)
        }
    }, [currentCampaignDetails])




    const getClientsHandler = () => {

        const params = {
            campaign_id: currentCampaignDetails.id
        }
        isLoading.show()
        dispatch(
            getClients({
                params,
                onSuccess: (response: any) => () => {
                    isLoading.hide()
                },
                onError: (error) => () => {
                    isLoading.hide()
                },
            })
        )
    }

 

    const getClientsHandler2 = () => {

   


        const params = {
            campaign_id: currentCampaignDetails.id
        }

     
        // alert("clicked")
        dispatch(
            getClients({
                params,
                onSuccess: (response: any) => () => {
                    showToast(response.message, "success");
                },
                onError: (error) => () => {
                },
            })
        )
    }

    // const onBulkUploadClients = () => {

    //     const params = {
    //         campaign_id: currentCampaignDetails.id,
    //         csv_file: clientsBulkUploadData
    //     }

    //     if (clientsBulkUploadData !== '') {

    //         dispatch(
    //             bulkUploadClientInfo({
    //                 params,
    //                 onSuccess: (response: any) => () => {
    //                     getClientsHandler()
    //                     setIsBulkUploadModalOpen(false)
    //                 },
    //                 onError: (error) => () => {
    //                     setIsBulkUploadModalOpen(false)
    //                 },
    //             })
    //         )
    //     }
    //     else {
    //         showToast('Please select file to upload', 'error')
    //     }

    // }

    const onBulkUploadClients = () => {
        console.log('bulkUploadClients', bulkUploadClients,);

        const params = {
            campaign_id: currentCampaignDetails.id,
            csv_file: clientsBulkUploadData
        }

        if (clientsBulkUploadData !== '') {

            dispatch(
                bulkUploadClients({
                    params,
                    onSuccess: (response: any) => () => {
                        setIsBulkUploadModalOpen(false)
                        getClientsHandler()
                        showToast(response.message, "success");

                    },
                    onError: (error) => () => {
                        showToast(error, "error")
                        setIsBulkUploadModalOpen(false)
                        setIsOpenModal(false)

                        console.log(error, "========hhhhhhhhhhhhhh");

                    },
                })
            )
        }
        else {
            showToast('Please select file to upload', 'error')
        }

    }


    const onTemplateClickHandler = () => {

        const params = {
        }
        dispatch(getClientTemplate({
            params,
            onSuccess: (success: any) => () => {
                urlDownloader(success?.details?.csv_file)
            },
            onError: (error: any) => () => {

            },
        }))
    }


    const onInitiateCampaignCall = (clientDetails) => {

   
        const params = {
            campaign_id: currentCampaignDetails.id,
            client_id: clientDetails.id
        }

        dispatch(initiateCampaignCall({
            params,
            onSuccess: (success: any) => () => {
                setIsOpenModal(!isOpenModal)
                dispatch(isOpenDirectCallModal(true))
                dispatch(getDirectCallDetails({
                    to_number: clientDetails?.client_info.mobile_number,
                    to_person: clientDetails?.client_info.first_name,

                }))
                dispatch(postInitiateInstantCallSuccess(success))
            },
            onError: (error: any) => () => {

            },
        }))
    }


    //redial

    const redialCampaignCallHandler = (data) => {
        const params = {
            id: data?.id
        }

        dispatch(redialCampaignCall({
            params,
            
            onSuccess: (response: any) => () => {
               
                setIsOpenModal(!isOpenModal)
                dispatch(getDirectCallDetails({
                    to_number: data?.client_info.mobile_number,
                    to_person: data?.client_info.first_name

                }))
                 dispatch(isOpenDirectCallModal(true))
                dispatch(postInitiateInstantCallSuccess(response))
            

            },
            onError: (error) => () => {
           
            },
        }))
    }

    








    const redialDirectCall = (data) => {
        const params = {
            id: data?.id
        }

        dispatch(fetchRedialDirectCall({
            params,
            onSuccess: (response: any) => () => {
                dispatch(getDirectCallDetails({
                    to_person: data?.name,
                    to_number: data?.phone_1

                }))
                dispatch(postInitiateInstantCallSuccess(response))
                dispatch(isOpenDirectCallModal(true))

            },
            onError: (error) => () => {
            },
        }))
    }


  
  

    const normalizedClientTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {


            return {
                Name: <div onClick={()=>goTo(ROUTES['Dashboard']['clientDetails'])}>{el?.client_info?.first_name}</div> ,
                age: <div onClick={()=>goTo(ROUTES['Dashboard']['clientDetails'])}>{el?.client_info?.age}</div> ,
                'Mobile number': <div onClick={()=>goTo(ROUTES['Dashboard']['clientDetails'])}>{el?.client_info?.mobile_number}</div> ,
                'personal info': <div onClick={()=>goTo(ROUTES['Dashboard']['clientDetails'])}>{convertToUpperCase(el?.about_personal)}</div> ,
                'professional info': <div onClick={()=>goTo(ROUTES['Dashboard']['clientDetails'])}>{convertToUpperCase(el?.about_professional)}</div> ,

                "": !el.call_is_complete ?
                    <div className="row justify-content-end mr-3">
                        < RSButton onClick={() => {
                            onInitiateCampaignCall(el)
                            setClientData(el)
                            // setIsOpenNewCall(true)
                            // goTo(ROUTES.Dashboard.clientDetails)

                        }} className="btn-icon  d-flex align-items-center justify-content-center custom-gradient border-0" size={'sm'} color="facebook" type="button" style={{ borderRadius: 25, width: 90, height: 25, backgroundColor: '#117c2b' }}>
                            <span className="btn-inner--icon mt--1">
                                <i className="bi bi-telephone fa-sm" />
                            </span>
                            <span className="btn-inner--text fw-light">call</span>
                        </RSButton >
                    </div>
                    : el.analytics_is_complete ?
                        <div className="text-right mr-3">
                            <Button
                                text={'View Analytics'}
                                size="sm"
                                onClick={() => {
                                    goTo(ROUTES.Dashboard.dashboard)
                                    dispatch(setReferenceId(el?.call_id))
                                    dispatch(settingSelectedCallDetails({
                                        fromPerson: el.from_person,
                                        toPerson: el.name,
                                        agenda: el.agenda,
                                        expectations: el.expectation
                                    }))
                                }}

                            />
                        </div> :
                        el.is_call_deferred ?
                            <div className="row justify-content-end mr-3">
                                < RSButton onClick={() => {
                                    // onInitiateCampaignCall(el)
                                    // redialDirectCall(el)
                                    redialCampaignCallHandler(el)
                                }} className="btn-icon  d-flex align-items-center justify-content-center custom-gradient border-0" size={'sm'} color="facebook" type="button" style={{ borderRadius: 25, width: 90, height: 25, backgroundColor: '#117c2b' }}>
                                    <span className="btn-inner--icon mt--1">
                                        <i className="bi bi-telephone fa-sm" />
                                    </span>
                                    <span className="btn-inner--text fw-light">Redial</span>
                                </RSButton >
                            </div>
                            :
                            <span style={{
                                fontSize: '12px'
                            }}>
                                {"GENERATING ANALYTICS"}
                            </span>
                ,
            }
        }
        )
    }

    const manageRoutingHandler = (item) => {
        goTo(ROUTES['Dashboard'].addClient)
    }


    useEffect(() => {
        if (notes) {
            dispatch(settingCallNotes(notes))

        }
    }, [notes])

    const notification = () => {

        const params = {}
        dispatch(fetchTestNotification({
            params,
            onSuccess: (success: any) => () => {
                console.log("11111111");


                const title = "Hii ";
                const options = {
                    body: 'this is test message',
                    // icon: icons.quantaTms,
                };
                // window.location.reload();
                console.log("2222222");


                new Notification(title, options).addEventListener('click', function () {
                    console.log("foreground message--------------->");
                    this.close()

                });
                console.log("3333333333");

            },
            onError: (error: any) => () => {

            },
        }))
    }


    return (
        <>
            {campaignsList && campaignsList.length > 0 && currentCampaignDetails ?
                <div className='m-3'>
                    <div className='text-right mb-3'>
                        <Button
                            size={'sm'}
                            text={'Create New Campaign'}
                            onClick={() => {
                                goTo(ROUTES['Dashboard'].addCampaign)
                            }}
                        />
                    </div>
                    <Card >
                        <div className='d-flex justify-content-between mx-3'>

                            <div className='row '>
                                <h3 className=''>{currentCampaignDetails && currentCampaignDetails?.name}</h3>
                            </div>

                            <div className='row '>
                            
                            <div className='mt--1 ml--4'>
                                {/* <Image/> */}
                                {/* <div className=''>
                                        <Image src={icons.CSVFileImage} height={'10px'} width={'10px'} />
                                        sankar
                                    </div> */}
                                   <CsvFile title={'CSV File Formed'} />
                                </div>
                                <div className='ml-1'>
                                <FileUploadExcel
                                    size={'md'}
                                    title={'Upload Clients details'}
                                    onSelect={(data) => {
                                        let eventPickers = [data]?.toString().replace(/^data:(.*,)?/, "")
                                        console.log('eventPickers=======>..', eventPickers);

                                        setClientsBulkUploadData(eventPickers)
                                        console.log('rrrrrrrr');


                                    }}
                                    onSubmitClick={() => {
                                        onBulkUploadClients()
                                    }}
                                    onTemplateClick={() => {
                                        onTemplateClickHandler()

                                    }}
                                    isOpen={isBulkUploadModalOpen}
                                    isUploadModalOpen={isBulkUploadModalOpen}
                                />
                                </div>
                                <div className='mt--1'>
                                    <Button
                                        size={'sm'}
                                        text={'Add Client'}
                                        onClick={() => {
                                            manageRoutingHandler(undefined)
                                        }}
                                    />
                                </div>

                            </div>

                        </div>


                        {isLoading.loader &&
                            <div className='d-flex justify-content-center'>
                                <Spinner />
                            </div>
                        }

                        {!isLoading.loader && (
                            <div className='overflow-auto scroll-hide mt-3'
                                style={{
                                    marginLeft: "-23px",
                                    marginRight: "-23px"
                                }}
                            >

                                {clientsList && clientsList.length > 0 ?
                                    <CommonTable

                                        isPagination
                                        tableDataSet={clientsList}
                                        displayDataSet={normalizedClientTableData(clientsList)}
                                        tableOnClick={(idx, index, item) => {
                                            console.log('item======>>', item);
                                            dispatch(clientsData(item))


                                            // goTo(ROUTES['Dashboard']['clientDetails']);

                                        }}

                                    />
                                    :
                                    <NoDataFound />
                                }

                            </div>
                        )}

                    </Card>
                </div>
                :
                <>
                    <div className='container-fluid'>

                    </div>
                </>
            }




            {isOpenDirectCall ?
                < Modal
                    fade={false}
                    size='lg'
                    isOpen={isOpenDirectCall}
                    className="modal-dialog-centered"
                >
                    <>

                        <div className="">
                            <DirectCall
                                onEndCall={() => {
                                    // setIsOpenModal(false)
                                    dispatch(isOpenDirectCallModal(false))
                                    // reset()
                                }}
                            />
                        </div>


                    </>
                </Modal >
                :
                <></>
            }
        </>
    )
}

export { ClientsList }