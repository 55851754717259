import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useDynamicHeight } from '@Hooks';
import { PersonalizedCard } from '@Modules';
import { NEXT_STEP_PERSONALIZED, removePercentege } from '@Utils'
import { NoDataFound } from '@Components';

function NextStepPersonalized() {
    const { nextStepPersonalized } = useSelector((state: any) => state.AppReducer);
    const { approaches } = nextStepPersonalized || {}


    console.log("nextStepPersonalized", nextStepPersonalized);


    const dynamicHeight: any = useDynamicHeight()

    const [nextStepPersonalisedData, setNextStepPersonalisedData] = useState<any>(nextStepPersonalized)

    // const [selectedData, setSelectedData] = useState<any>(approaches && [approaches[0]].length > 0 ? [approaches[0]] : [])


    useEffect(() => {
        const modifiedPerceptionData = nextStepPersonalized?.extractions?.map((el: any) => {

            return { ...el, isShow: false }
        })

        setNextStepPersonalisedData(modifiedPerceptionData)

    }, [])

    console.log("nextStepPersonalisedData", nextStepPersonalisedData);


    return (
        <>
            {nextStepPersonalisedData && nextStepPersonalisedData?.length > 0 ? (
                <div className='row'>

                    <div className='col-xl-12 col-md-12 col-sm-12 ' >
                        <div className='row'>
                            {
                                nextStepPersonalisedData && nextStepPersonalisedData.length > 0 && nextStepPersonalisedData?.filter((ea) => ea.approaches && ea?.approaches.length > 0).map((ite) => {
                                    let approaches = ite?.approaches
                                    if (approaches?.length > 4)
                                        approaches = approaches?.slice(0, 4)
                                    const findMaximumOut = approaches?.sort((a, b) => (removePercentege(a.increasedPercent)) - (removePercentege(b.increasedPercent)))

                                    return {
                                        ...ite,
                                        maximum: ite?.approaches && ite?.approaches?.length > 0 ? findMaximumOut[findMaximumOut.length - 1]?.increasedPercent : 0,
                                        approaches: findMaximumOut.reverse().map((el) => {
                                            return { ...el, isShow: true }
                                        })
                                    }
                                }).map((item) => {

                                    return (
                                        <div className='col-xl-12 col-md-12'>
                                            <PersonalizedCard perceptionTableData={[item]} overAllPercentage={nextStepPersonalized.conversionPercent} personsData={nextStepPersonalized?.personName} />
                                        </div>
                                    );
                                })

                            }

                        </div>
                    </div>

                </div>
            ) : <NoDataFound />}
        </>
    )
}


export { NextStepPersonalized }


