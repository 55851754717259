import { takeLatest, put, call, } from 'redux-saga/effects';
import * as Action from '../Store'
import * as Services from '@Services'

function* getViewSaga(action) {
    try {
        const response = yield call(Services.getViewApi, action.payload.params);
        if (response.success) {
            yield put(Action.getViewSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getViewFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getViewFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

function* getTraitSaga(action) {
    try {
        const response = yield call(Services.getTraitApi, action.payload.params);
        if (response.success) {
            yield put(Action.getTraitSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getTraitFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getTraitFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

function* getNextStepSaga(action) {
    try {
        const response = yield call(Services.getNextStepApi, action.payload.params);
        if (response.success) {
            yield put(Action.getNextStepSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getNextStepFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getNextStepFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

function* getBettermentAnalyticsSaga(action) {
    try {
        const response = yield call(Services.getBettermentAnalyticsApi, action.payload.params);
        if (response.success) {
            yield put(Action.getBettermentAnalyticsSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getBettermentAnalyticsFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getBettermentAnalyticsFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

/// add data

function* postDataSaga(action) {
    try {
        const response = yield call(Services.postDataApi, action.payload.params);
        if (response.success) {
            yield put(Action.postDataSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.postDataFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.postDataFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}


//-------------------------------------------------------------//
/// add designation

function* postDesignationSaga(action) {
    try {
        const response = yield call(Services.postDesignationApi, action.payload.params);
        if (response.success) {
            yield put(Action.postDesignationSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.postDesignationFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.postDesignationFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}


/// get designation

function* fetchDesignationSaga(action) {
    try {
        const response = yield call(Services.fetchDesignationsAPi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchDesignationsSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchDesignationsFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchDesignationsFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

/// add department

function* postDepartmentSaga(action) {
    try {
        const response = yield call(Services.postDepartmentApi, action.payload.params);
        if (response.success) {
            yield put(Action.postDepartmentSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.postDepartmentFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.postDepartmentFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

/// add data

function* fetchDepartmentsSaga(action) {
    try {
        const response = yield call(Services.fetchDepartmentsApi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchDepartmentsSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchDepartmentsFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchDepartmentsFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

function* fetchExpectationSaga(action) {
    try {
        const response = yield call(Services.fetchExpectationApi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchExpectationSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchExpectationFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchExpectationFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}


/// get twilio token

function* fetchTwilioTokenSaga(action) {
    try {
        const response = yield call(Services.fetchTwilioTokenApi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchTwilioTokenSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchTwilioTokenFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchTwilioTokenFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}






///post initiate instant call

function* postInitiateInstantCallSaga(action) {
    try {
        const response = yield call(Services.postInitiateInstantCallApi, action.payload.params);
        if (response.success) {
            yield put(Action.postInitiateInstantCallSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.postInitiateInstantCallFailures(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.postInitiateInstantCallFailures("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}

function* getInstantCallSaga(action) {
    try {
        const response = yield call(Services.getInstantCallsApi, action.payload.params);
        console.log(response,"responseresponseresponseiiiiiiii")
        if (response.success) {
            yield put(Action.getInstantCallSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getInstantCallFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getInstantCallFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}


///get test notification

function* fetchTestNotificationSaga(action) {
    try {
        const response = yield call(Services.getTestNotificationApi, action.payload.params);
        console.log("response", response);
        if (response.success) {
            console.log("dsssdsdd");
            yield put(Action.fetchTestNotificationSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchTestNotificationFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchTestNotificationFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}



///redial direct call

function* fetchRedialDirectCallSaga(action) {
    try {
        const response = yield call(Services.fetchRedialDirectCallApi, action.payload.params);
   
        if (response.success) {
          
            yield put(Action.fetchRedialDirectCallSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchRedialDirectCallFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchRedialDirectCallFailure("Invalid Request"));
        yield call(action.payload.onError(error));
    }
}




function* fetchOverAllPercentSaga(action) {
    try {
        const response = yield call(Services.fetchOverAllPercentApi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchOverAllPercentSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchOverAllPercentFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchOverAllPercentFailure(undefined));
        yield call(action.payload.onError(error));
    }
}



//Next step personalized

function* fetchNextStepPersonalizedSaga(action) {
    try {
        const response = yield call(Services.fetchNextStepPersonalizedApi, action.payload.params);
        if (response.success) {
            yield put(Action.fetchNextStepPersonalizedSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.fetchNextStepPersonalizedFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.fetchNextStepPersonalizedFailure(undefined));
        yield call(action.payload.onError(error));
    }
}
//add produvt saga

//Next step personalized

function* postProductSaga(action) {
    try {
        const response = yield call(Services.addProductApi, action.payload.params);
        
        if (response.success) {
            yield put(Action.postProductSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.postProductFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.postProductFailure(undefined));
        yield call(action.payload.onError(error));
    }
}


//Get persons list

function* getPersonsListSaga(action) {
    try {
        const response = yield call(Services.getPersonsListApi, action.payload.params);
        if (response.success) {
            yield put(Action.getPersonsListSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.getPersonsListFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {
        yield put(Action.getPersonsListFailure(undefined));
        yield call(action.payload.onError(error));
    }
}

//redial 

function* redialCampaignCallSaga(action) {

    try {
        const response = yield call(Services.redialCampaignCallApi, action.payload.params);
  
        if (response.success) {
            yield put(Action.redialCampaignCallSuccess(response));
            yield call(action.payload.onSuccess(response));
        } else {
            yield put(Action.redialCampaignCallFailure(response.error_message));
            yield call(action.payload.onError(response));
        }
    } catch (error) {

        yield put(Action.redialCampaignCallFailure(undefined));
        yield call(action.payload.onError(error));
    }
}





/* watcher */

function* AppSaga() {
    yield takeLatest(Action.GET_VIEW, getViewSaga)
    yield takeLatest(Action.GET_TRAIT, getTraitSaga)
    yield takeLatest(Action.GET_NEXTSTEP, getNextStepSaga)
    yield takeLatest(Action.GET_BETTERMENT_ANALYTICS, getBettermentAnalyticsSaga)
    yield takeLatest(Action.POST_DATA, postDataSaga)
    yield takeLatest(Action.POST_DESIGNATION, postDesignationSaga)
    yield takeLatest(Action.FETCH_DESIGNATIONS, fetchDesignationSaga)
    yield takeLatest(Action.POST_DEPARTMENT, postDepartmentSaga)
    yield takeLatest(Action.FETCH_DEPARTMENTS, fetchDepartmentsSaga)
    yield takeLatest(Action.FETCH_EXPECTATION, fetchExpectationSaga)
    yield takeLatest(Action.FETCH_TWILIO_TOKEN, fetchTwilioTokenSaga)
    yield takeLatest(Action.POST_INITIATE_INSTANT_CALL, postInitiateInstantCallSaga)
    yield takeLatest(Action.FETCH_OVERALL_PERCENT, fetchOverAllPercentSaga )
    yield takeLatest(Action.GET_INSTANT_CALL, getInstantCallSaga)
    yield takeLatest(Action.FETCH_TEST_NOTIFICATION, fetchTestNotificationSaga)
    yield takeLatest(Action.FETCH_REDIAL_DIRECT_CALL, fetchRedialDirectCallSaga)
    yield takeLatest(Action.FETCH_NEXT_STEP_PERSONALIZED, fetchNextStepPersonalizedSaga)
    yield takeLatest(Action.GET_PERSONS_LIST, getPersonsListSaga)
    yield takeLatest(Action.ADD_PRODUCT, postProductSaga)
    yield takeLatest(Action.REDIAL_CAMPAIGN_CALL, redialCampaignCallSaga)
    



}

export default AppSaga;
