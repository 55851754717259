import { USER_LOGIN_DETAILS, RESTORE_APP, USER_LOGOUT, HANDLING_API, FCM_TOKEN, RESET_APP } from '../ActionTypes';

import * as ActionTypes from '../ActionTypes'


export const resetAppReducer = () =>{
  return{
    type: RESET_APP,
  }
}

export const userLoginDetails = (params: any) => {
  return {
    type: USER_LOGIN_DETAILS,
    payload: params,
  };
};
export const restoreApp = () => {
  return {
    type: RESTORE_APP,
  };
};

export const userLogout = (params: any) => {
  return {
    type: USER_LOGOUT,
    payload: params,
  };
};

export const setIsSync = (params: any) => {
  return {
    type: HANDLING_API,
    payload: params,
  };
};

export const setFcmToken = (token: any) => {
  return {
    type: FCM_TOKEN,
    payload: token,
  };
};


export const getView = (params: any) => {
  return {
    type: ActionTypes.GET_VIEW,
    payload: params
  }
}
export const getViewSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_VIEW_SUCCESS,
    payload: response
  }
}

export const getViewFailure = (error: any) => {
  return {
    type: ActionTypes.GET_VIEW_FAILURE,
    payload: error
  }
}

export const getTrait = (params: any) => {
  return {
    type: ActionTypes.GET_TRAIT,
    payload: params
  }
}
export const getTraitSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_TRAIT_SUCCESS,
    payload: response
  }
}

export const getTraitFailure = (error: any) => {
  return {
    type: ActionTypes.GET_TRAIT_FAILURE,
    payload: error
  }
}

export const getNextStep = (params: any) => {
  return {
    type: ActionTypes.GET_NEXTSTEP,
    payload: params
  }
}
export const getNextStepSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_NEXTSTEP_SUCCESS,
    payload: response
  }
}

export const getNextStepFailure = (error: any) => {
  return {
    type: ActionTypes.GET_NEXTSTEP_FAILURE,
    payload: error
  }
}


export const getBettermentAnalytics = (params: any) => {
  return {
    type: ActionTypes.GET_BETTERMENT_ANALYTICS,
    payload: params
  }
}

export const getBettermentAnalyticsSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_BETTERMENT_ANALYTICS_SUCCESS,
    payload: response
  }
}

export const getBettermentAnalyticsFailures = (error: any) => {
  return {
    type: ActionTypes.GET_BETTERMENT_ANALYTICS_FAILURE,
    payload: error
  }
}

export const setIsLoading = (params: any) => {
  return {
    type: ActionTypes.IS_LOADING,
    payload: params
  }
}



//add data



export const postData = (params: any) => {
  return {
    type: ActionTypes.POST_DATA,
    payload: params
  }
}

export const postDataSuccess = (response: any) => {
  return {
    type: ActionTypes.POST_DATA_SUCCESS,
    payload: response
  }
}

export const postDataFailures = (error: any) => {
  return {
    type: ActionTypes.POST_DATA_FAILURE,
    payload: error
  }
}




//add designation
export const postDesignation = (params: any) => {
  return {
    type: ActionTypes.POST_DESIGNATION,
    payload: params
  }
}

export const postDesignationSuccess = (response: any) => {
  return {
    type: ActionTypes.POST_DESIGNATION_SUCCESS,
    payload: response
  }
}

export const postDesignationFailures = (error: any) => {
  return {
    type: ActionTypes.POST_DESIGNATION_FAILURE,
    payload: error
  }
}



//get designation
export const fetchDesignations = (params: any) => {
  return {
    type: ActionTypes.FETCH_DESIGNATIONS,
    payload: params
  }
}

export const fetchDesignationsSuccess = (response: any) => {
  return {
    type: ActionTypes.FETCH_DESIGNATIONS_SUCCESS,
    payload: response
  }
}

export const fetchDesignationsFailures = (error: any) => {
  return {
    type: ActionTypes.FETCH_DESIGNATIONS_FAILURE,
    payload: error
  }
}


//add designation
export const postDepartment = (params: any) => {
  return {
    type: ActionTypes.POST_DEPARTMENT,
    payload: params
  }
}

export const postDepartmentSuccess = (response: any) => {
  return {
    type: ActionTypes.POST_DEPARTMENT_SUCCESS,
    payload: response
  }
}

export const postDepartmentFailures = (error: any) => {
  return {
    type: ActionTypes.POST_DEPARTMENT_FAILURE,
    payload: error
  }
}



//add designation
export const fetchDepartments = (params: any) => {
  return {
    type: ActionTypes.FETCH_DEPARTMENTS,
    payload: params
  }
}

export const fetchDepartmentsSuccess = (response: any) => {
  return {
    type: ActionTypes.FETCH_DEPARTMENTS_SUCCESS,
    payload: response
  }
}

export const fetchDepartmentsFailures = (error: any) => {
  return {
    type: ActionTypes.FETCH_DEPARTMENTS_FAILURE,
    payload: error
  }
}

export const fetchExpectation = (params: any) => {
  return {
    type: ActionTypes.FETCH_EXPECTATION,
    payload: params
  }
}

export const fetchExpectationSuccess = (response: any) => {
  console.log("response expectation--------->",response)
  return {
    type: ActionTypes.FETCH_EXPECTATION_SUCCESS,
    payload: response
  }
}

export const fetchExpectationFailure = (error: any) => {
  return {
    type: ActionTypes.FETCH_EXPECTATION_FAILURE,
    payload: error
  }

}


//get designation
export const fetchTwilioToken = (params: any) => {
  return {
    type: ActionTypes.FETCH_TWILIO_TOKEN,
    payload: params
  }
}

export const fetchTwilioTokenSuccess = (response: any) => {
  return {
    type: ActionTypes.FETCH_TWILIO_TOKEN_SUCCESS,
    payload: response
  }
}

export const fetchTwilioTokenFailures = (error: any) => {
  return {
    type: ActionTypes.FETCH_TWILIO_TOKEN_FAILURE,
    payload: error
  }
}






//post initiate instant call
export const postInitiateInstantCall = (params: any) => {
  return {
    type: ActionTypes.POST_INITIATE_INSTANT_CALL,
    payload: params
  }
}

export const postInitiateInstantCallSuccess = (response: any) => {
  console.log("00000000000999999999999", response)
  return {
    type: ActionTypes.POST_INITIATE_INSTANT_CALL_SUCCESS,
    payload: response
  }
}

export const postInitiateInstantCallFailures = (error: any) => {
  return {
    type: ActionTypes.POST_INITIATE_INSTANT_CALL_FAILURE,
    payload: error
  }
}

export const setReferenceId = (params: any) => {
  return {
    type: ActionTypes.REFERENCE_ID,
    payload: params
  }
}


//post initiate instant call
export const getDirectCallDetails = (params: any) => {
  console.log("hjhjhjhjhjh", params)
  return {
    type: ActionTypes.GET_DIRECT_CALL_DATA,
    payload: params
  }
}



//post initiate instant call
export const getInstantCall = (params: any) => {
  return {
    type: ActionTypes.GET_INSTANT_CALL,
    payload: params
  }
}

export const getInstantCallSuccess = (response: any) => {
  return {
    type: ActionTypes.GET_INSTANT_CALL_SUCCESS,
    payload: response
  }
}

export const getInstantCallFailure = (error: any) => {
  return {
    type: ActionTypes.GET_INSTANT_CALL_FAILURE,
    payload: error
  }
}
//
export const isOpenDirectCallModal = (params: any) => {
  return {
    type: ActionTypes.IS_OPEN_DIRECT_CALL_MODAL,
    payload: params
  }
}

export const isDirectCallModal = (params: any) => {
  return {
    type: ActionTypes.IS_DIRECT_CALL_MODAL,
    payload: params
  }
}


//get notification
export const fetchTestNotification = (params: any) => {
  return {
    type: ActionTypes.FETCH_TEST_NOTIFICATION,
    payload: params
  }
}

export const fetchTestNotificationSuccess = (response: any) => {
  console.log("qqqqqq");

  return {
    type: ActionTypes.FETCH_TEST_NOTIFICATION_SUCCESS,
    payload: response
  }
}

export const fetchTestNotificationFailure = (error: any) => {
  return {
    type: ActionTypes.FETCH_TEST_NOTIFICATION_FAILURE,
    payload: error
  }
}



//redial direct call
export const fetchRedialDirectCall = (params: any) => {
  return {
    type: ActionTypes.FETCH_REDIAL_DIRECT_CALL,
    payload: params
  }
}

export const fetchRedialDirectCallSuccess = (response: any) => {
  console.log("qqqqqq");

  return {
    type: ActionTypes.FETCH_REDIAL_DIRECT_CALL_SUCCESS,
    payload: response
  }
}

export const fetchRedialDirectCallFailure = (error: any) => {
  return {
    type: ActionTypes.FETCH_REDIAL_DIRECT_CALL_FAILURE,
    payload: error
  }
}



//get OverAll Percent
export const fetchOverAllPercent = (params: any) => {
  return {
    type: ActionTypes.FETCH_OVERALL_PERCENT,
    payload: params
  }
}

export const fetchOverAllPercentSuccess = (response: any) => {

  return {
    type: ActionTypes.FETCH_OVERALL_PERCENT_SUCCESS,
    payload: response
  }
}

export const fetchOverAllPercentFailure = (error: any) => {
  return {
    type: ActionTypes.FETCH_OVERALL_PERCENT_FAILURE,
    payload: error
  }
}

//Get next step personalized 

export const fetchNextStepPersonalized = (params: any) => {
  return {
    type: ActionTypes.FETCH_NEXT_STEP_PERSONALIZED,
    payload: params
  }
}

export const fetchNextStepPersonalizedSuccess = (response: any) => {

  return {
    type: ActionTypes.FETCH_NEXT_STEP_PERSONALIZED_SUCCESS,
    payload: response
  }
}

export const fetchNextStepPersonalizedFailure = (error: any) => {
  return {
    type: ActionTypes.FETCH_NEXT_STEP_PERSONALIZED_FAILURE,
    payload: error
  }
}

//Get persons list

export const getPersonsList = (params: any) => {
  return {
    type: ActionTypes.GET_PERSONS_LIST,
    payload: params
  }
}

export const getPersonsListSuccess = (response: any) => {

  return {
    type: ActionTypes.GET_PERSONS_LIST_SUCCESS,
    payload: response
  }
}

export const getPersonsListFailure = (error: any) => {
  return {
    type: ActionTypes.GET_PERSONS_LIST_FAILURE,
    payload: error
  }
}


//Get persons list

export const postProduct = (params: any) => {
  return {
    type: ActionTypes.ADD_PRODUCT,
    payload: params
  }
}

export const postProductSuccess = (response: any) => {

  return {
    type: ActionTypes.ADD_PRODUCT_SUCCESS,
    payload: response
  }
}

export const postProductFailure = (error: any) => {
  return {
    type: ActionTypes.ADD_PRODUCT_FAILURE,
    payload: error
  }
}


export const redialCampaignCall = (params) => {
  console.log(params,"params0000000000000000")
  return {
    type: ActionTypes.REDIAL_CAMPAIGN_CALL,
    payload: params,
  };
};

export const redialCampaignCallSuccess = (response) => {
  console.log(response,"response=======sssssssssss")
  return {
    type: ActionTypes.REDIAL_CAMPAIGN_CALL_SUCCESS,
    payload: response,
  };
};

export const redialCampaignCallFailure = (error) => {
  console.log(error,"response=======rrrrrrrrrrrrrrrrr")
  return {
    type: ActionTypes.REDIAL_CAMPAIGN_CALL_FAILURE,
    payload: error,
  };
};