import { useState } from 'react';
import { FormGroup } from 'reactstrap'
import { InputHeading } from '@Components'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { PhoneNumberProps } from './interfaces';


const PhoneNumberInput = ({className,heading,id,inputHeight="47px",inputWidth="100",onKeyDown,placeholder,value,onChange}:PhoneNumberProps) => {
const customCountries =["in","us","to",'tv',  'vu',  'wf',  'hk',"ru", "ua"]

return (
   <FormGroup className={`${className} `}>
    <InputHeading heading={heading}/>
    <PhoneInput
    country={'us'}
    countryCodeEditable={false}
    value={value}
    enableSearch={true}
    onlyCountries={customCountries}
    buttonClass='btn-primary bg-white p-1'
    inputClass={`form-control  w-${inputWidth}`}
    inputStyle={{height:inputHeight}}
    buttonStyle={{boxShadow:"none"}}
    dropdownClass='text-black'
    onChange={onChange}
     onKeyDown={onKeyDown}
       />
  </FormGroup>
  );
};
export {PhoneNumberInput}




// import React, { useState } from 'react'
// import PhoneInput from 'react-phone-number-input'
// import 'react-phone-number-input/style.css'
// import { PhoneNumberProps } from './interfaces'


// function PhoneNumberInput({ value, onChange ,inputWidth="100",inputHeight="100"}: PhoneNumberProps) {

//   const [number, setNumber] = useState<any>('')
//   return (

//     <PhoneInput
//       international
//       defaultCountry="IN"
//       value={value}
//       className='custom'
//       onChange={onChange}
//       inputHeight='50'
//       inputWidth='100'
      
//     />

//   )
// }

// export { PhoneNumberInput }