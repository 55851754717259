import { useEffect, useState } from "react";
import { Button, Card, DropDown, FileUpload, Input, InputHeading, showToast, MultiSelectDropDown, Badge, AutoSearch, Modal, PhoneNumberInput } from "@Components";
import { useDropDown, useInput, useModal, useNavigation } from "@Hooks";
import { AUDIO_ATTACHMENT_RULE, DIRECT_CALL_RULES, PHONE_TO_PHONE_RULES, REGISTER_COMPANY_RULE, REGISTER_DESIGNATION, getDropDownSearchData, getValidateError, ifObjectExist, validate, } from "@Utils";
import { addDesignations, getAllBranches, getDesignations, getDesignationsSuccess, getDirectCallDetails, isOpenDirectCallModal, postData, postInitiateInstantCall, registerCompany, isDirectCallModal } from "@Redux";
import { useDispatch, useSelector } from "react-redux";
import { icons } from "@Assets";
import ReactAudioPlayer from "react-audio-player";



function AddData() {

    const DATA = [
        { key: 'sales conversion', value: 'Sales conversion', name: 'Sales conversion' },
        { key: 'reengagement', value: 'Re-engagement', name: 'Re-engagement' },
        { key: 'curiosity', value: 'Curiosity', name: 'Curiosity' }
    ];

    const [checkList, setCheckList] = useState<any>([

        { id: 1, value: 'Direct Call', check: true, type: 'DIRECT_CALL' },
        { id: 2, value: 'Phone to Phone', check: false, type: 'PHONE_TO_PHONE' },
        { id: 3, value: 'Audio Attachment', check: false, type: 'CALL_RECORDING' },
    ]
    )

    const [agentInfoType, setAgentInfoType] = useState([
        { id: 1, value: 'Item', check: true, type: 'Item' },
        { id: 2, value: 'Group', check: false, type: 'Group' },
    ])

    const [prospectInfoType, setProspectInfoType] = useState([

        { id: 1, value: 'Item', check: true, type: 'Item' },
        { id: 2, value: 'Group', check: false, type: 'Group' },
    ])

    const [agentType, setAgentType] = useState('Item')
    const [prospectType, setProspectType] = useState('Item')
    // const companyDetailsModal = useModal(false)
    // const designationModal = useModal(false)
    // const isOpenNewCall =useModal(false)

    const { instantCallData, isOpenDirectCall } = useSelector((state: any) => state.AppReducer);
    const { branchesData, designations } = useSelector((state: any) => state.AuthReducer);
    const dispatch = useDispatch();
    const [selectedData, setSelectedData] = useState<any>([])
    const [count, setCount] = useState<any>(0)
    const fromPerson = useInput('')
    const toPerson = useInput('')
    const [fromPersonNos, setFromPersonNos] = useState<any>('')
    const [toPersonNo, setToPersonNo] = useState<any>('')


    const agenda = useInput('')
    const [attachment, setAttachment] = useState('')
    const expectations = useInput('')
    const [callType, setCallType] = useState<any>("DIRECT_CALL")
    const agentInfo = useInput('')
    const [selectedAgentInfo, setSelectedAgentInfo] = useState<any>([])
    const prospectInfo = useInput('')
    const [selectedProspectInfo, setSelectedProspectInfo] = useState<any>([])
    const [selectCompanyDetails, setCompanyDetails] = useState<any>()
    const [selectDesignation, setDesignation] = useState<any>()
    const [noDataFoundCompany, setNoDataFoundCompany] = useState('')
    const [noDataFoundDesignation, setNoDataFoundDesignation] = useState('')


    const designation = useInput('')

    const cites = useInput('')
    const states = useInput('')
    const industries = useInput('')
    const employees = useInput('')
    const countries = useInput('')
    const companyAddress = useInput('')
    const companyName = useInput('')
    const PhoneNumber = useInput('')
    const prospectCompanyName = useInput('')
    const prospectDesignation = useInput('')



    const validationHandler = (value) => {
        switch (value) {
            case 1:
                return DIRECT_CALL_RULES;
            case 2:
                return PHONE_TO_PHONE_RULES;
            case 3:
                return AUDIO_ATTACHMENT_RULE;
        }
    }


    useEffect(() => {
        dispatch(isOpenDirectCallModal(false))
        dispatch(isDirectCallModal(false))
    }, [])

    // useEffect(() => {
    //     handleGetAllBranches()


    // }, [])

    // const handleGetAllBranches = () => {
    //     const params = {}
    //     dispatch(
    //         getAllBranches(
    //             {
    //                 params,
    //                 onSuccess: (response: any) => () => {


    //                     // console.log(response,"ttrrr")
    //                     // console.log(companyName.value,"=========cccc")
    //                     // console.log(response.details,"branchesData==")


    //                     if (companyName.value) {
    //                         let companyData: any = response.details.filter((option) => option.name === companyName.value)

    //                         setCompanyDetails({ value: companyData[0]?.name, label: companyData[0].name, id: companyData[0]?.id })

    //                     }

    //                 },
    //                 onError: (error: any) => () => {

    //                 }

    //             }
    //         )
    //     )

    // }

    // const handleRegisterCompany = () => {

    //     const params = {
    //         brand_name: companyName.value,
    //         communication_address: companyAddress.value,
    //         mobile_number: PhoneNumber.value,
    //         city: cites.value,
    //         state: states.value,
    //         country: countries.value,
    //         employee_size: employees.value,
    //         industry: industries.value
    //     }
    //     const validation = validate(REGISTER_COMPANY_RULE, params);
    //     if (ifObjectExist(validation)) {
    //         dispatch(
    //             registerCompany({
    //                 params,
    //                 onSuccess: () => () => {
    //                     companyDetailsModal.hide()


    //                     handleGetAllBranches()
    //                     setNoDataFoundCompany('')

    //                 },
    //                 onError: () => () => {

    //                 }

    //             })
    //         )
    //     }
    //     else {
    //         showToast(getValidateError(validation));
    //     }
    // }






    const submitApiHandler = () => {

        const params = {
            to_person: toPerson.value,
            from_person: fromPerson.value,
            to_number: toPersonNo,
            prospect_company_name: prospectCompanyName.value,
            prospect_designation: prospectDesignation.value,
            ...(fromPersonNos && { mask_number: fromPersonNos }),
            ...(attachment && { call_recording: attachment }),
            ...(callType && { type: callType }),
            ...(agenda.value && { agenda: agenda.value }),
            ...(selectedData && { expectations: selectedData }),
            ...(agentType === 'Item' && selectedAgentInfo.length > 0 ? { agent_information_to_share: selectedAgentInfo } : { agent_information_to_group: selectedAgentInfo, selectedAgentInfo }),
            ...(prospectType === 'Item' && selectedProspectInfo.length > 0 ? { prospect_information_to_share: selectedProspectInfo } : { prospect_information_to_group: selectedProspectInfo })

        }


        const validation = validate(count === 0 ? DIRECT_CALL_RULES : count === 1 ? PHONE_TO_PHONE_RULES : count === 2 ? AUDIO_ATTACHMENT_RULE : DIRECT_CALL_RULES, params);

        if (ifObjectExist(validation)) {

            dispatch(postInitiateInstantCall({
                params,
                onSuccess: (response: any) => () => {
                    console.log("response============", response)

                    dispatch(getDirectCallDetails(params))
                    // showToast(response.message, "success");

                    if (callType === "DIRECT_CALL") {
                        console.log("response============>>>>>>>>>>")
                        dispatch(isOpenDirectCallModal(true))
                        showToast(response.message, "success");
                    }
                    else if (callType === "PHONE_TO_PHONE") {
                        console.log("response============>>>>>>>>>>")
                        dispatch(isDirectCallModal(true))
                        showToast(response.message, "success");
                    }

                    else if (callType === "CALL_RECORDING") {
                        console.log("response============>>>>>>>>>>")
                        dispatch(isDirectCallModal(true))
                        showToast(response.message, "success");
                    }



                },
                onError: (error) => () => {
                },
            }))
        }
        else {
            showToast(getValidateError(validation));
        }


    }

    useEffect(() => {
        if (!isOpenDirectCall) {
            reset()
        }
    }, [isOpenDirectCall])

    const handleMultiSelectDropDownData = (type, item) => {
        let array: any = [...selectedData]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedData(array)
        }
        else if (type === 'remove') {
            let modified = array.filter((el, index) => el !== item)
            setSelectedData(modified)
        }
        expectations.set('')
    }

    const handleMultiSelectAgentInfo = (type, item) => {
        let array: any = [...selectedAgentInfo]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedAgentInfo(array)
        }
        else if (type === 'remove') {
            let modified = array.filter((el, index) => el !== item)
            setSelectedAgentInfo(modified)
        }
        agentInfo.set('')
    }

    const handleMultiSelectProspectInfo = (type, item) => {
        let array: any = [...selectedProspectInfo]

        if (type === 'add' && item !== '') {
            array.push(item)
            setSelectedProspectInfo(array)
        }
        else if (type === 'remove') {
            let modified = array.filter((el, index) => el !== item)
            setSelectedProspectInfo(modified)
        }
        prospectInfo.set('')
    }

    const handleCheckBoxData = (index) => {

        let modifiedData = checkList.map((el) => {
            if (el.id === index) {
                return { ...el, check: true }
            }
            else {
                return { ...el, check: false }
            }

        })

        setCheckList(modifiedData)
        setFromPersonNos('')
        setToPersonNo('')

    }

    const handleAgentCheckBoxData = (index) => {

        let modifiedData = agentInfoType.map((el) => {
            if (el.id === index) {
                return { ...el, check: true }
            }
            else {
                return { ...el, check: false }
            }

        })

        setAgentInfoType(modifiedData)

    }

    const handleProspectCheckBoxData = (index) => {

        let modifiedData = prospectInfoType.map((el) => {
            if (el.id === index) {
                return { ...el, check: true }
            }
            else {
                return { ...el, check: false }
            }

        })

        setProspectInfoType(modifiedData)
    }

    const reset = () => {
        // fromPerson.set('')
        setFromPersonNos('')
        toPerson.set('')
        setToPersonNo('')

        setCallType("DIRECT_CALL")
        agenda.set('')


        setSelectedData('')
        dispatch(getDirectCallDetails(undefined))
    }


    const handleCheckList = (index) => {
        console.log("index", index)
        switch (index) {
            case 0:
                return <>

                    <div className="row">
                        <div className="col-sm-12">
                            <PhoneNumberInput heading='Direct Call No' value={toPersonNo} onChange={setToPersonNo} />
                        </div>
                    </div>
                </>
            case 2:
                return <>
                    <InputHeading heading={'attachment'} />
                    {attachment ?
                        <div className="col-12">


                            <ReactAudioPlayer
                                style={{ zoom: '58%' }}
                                className="ml--4"
                                // src={getImageUrlJango(chatConversation?.recordingUrl)}
                                controls
                            />
                        </div> : <FileUpload variant={'icon-rounded'} icon={icons.mic} type="audio" className="mb-3" onFileUpload={(file) => {
                            console.log(file, "fffffff======>>>>")
                            setAttachment(file)
                        }} />
                    }

                </>
            case 1:
                return <>


                    <div className="d-flex  justify-content-between">
                        <PhoneNumberInput heading='Agent No' value={fromPersonNos} onChange={setFromPersonNos} className="col-6" />

                        <PhoneNumberInput heading='Prospect No' value={toPersonNo} onChange={setToPersonNo} className="col-6" />
                    </div>

                </>
        }
    }


    // const fromCompany = useInput('')
    // const fromDescription= useInput('')

    return (
        <>
            <div className="container-fluid p-4">
                <div className="mt--4 mb-2">
                    <h2>Start Direct Call</h2>
                </div>
                <div className="">
                    <div className="row">
                        <div className="col-sm-6">
                            <Input heading='Agent Name' value={fromPerson.value} onChange={fromPerson.onChange} />
                        </div>
                        <div className="col-sm-6">
                            <Input heading='Prospect Name' value={toPerson.value} onChange={toPerson.onChange} />
                        </div>
                    </div>

                    <div className="row">

                        <div className="col-sm-6">
                            <Input heading='Prospect company' value={prospectCompanyName.value} onChange={prospectCompanyName.onChange} />
                        </div>
                        <div className="col-sm-6">
                            <Input heading='Prospect Designation' value={prospectDesignation.value} onChange={prospectDesignation.onChange} />
                        </div>
                    </div>




                    <div className="row">
                        <div className="col-sm-6 ">
                            <Input heading='Target' value={agenda.value} onChange={agenda.onChange} />
                        </div>
                        {/* {Key metrics} */}
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col">
                                    <Input heading='Key Metrics' value={expectations.value} onChange={expectations.onChange} />
                                </div>
                                <div
                                    style={{
                                        marginTop: '35px'
                                    }}
                                >
                                    <Button
                                        text={'Add'}
                                        size="md"
                                        onClick={() => {
                                            handleMultiSelectDropDownData('add', expectations.value)
                                        }}
                                    />
                                </div>

                            </div>
                            <div className="row mt--2 mb-3 ml-0">
                                {
                                    selectedData && selectedData.map((el, index) => {
                                        return (
                                            <>
                                                <div>
                                                    <div className="ml-2 bg-primary text-white pl-2 pr-1  rounded">
                                                        {el}
                                                        <span className="ml-2"
                                                            onClick={() => {
                                                                handleMultiSelectDropDownData('remove', el)
                                                            }}
                                                        >
                                                            <i className="bi bi-x-circle-fill  text-white"
                                                                style={{ fontSize: '13px' }}
                                                            ></i>
                                                        </span>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        {/* {Agent info} */}
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col">
                                    <label className={'form-control-label'}>{'Agent Information To Share'}</label>

                                    {/* {checkbox} */}

                                    <div className="row">

                                        {agentInfoType.map((el, index) => {
                                            return (
                                                <>
                                                    <div className="form-check mb-3 ml-3">
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault2"
                                                            id="flexRadioDefault2"
                                                            onChange={(e) => {
                                                                handleAgentCheckBoxData(el.id)
                                                                setAgentType(el.value)
                                                                setSelectedAgentInfo([])
                                                            }}
                                                            checked={el.check}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            {el.value}
                                                        </label>

                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>

                                    {agentInfoType.map((el, index) => {
                                        if (el.check === true) {
                                            return (
                                                <h5 className="mt--3 text-muted">
                                                    {el.value === 'Item' && el.check === true ? `Ex:Company Name, Contact Number, etc...` : 'Ex:Company Portfolio,Service Details, etc...'}
                                                </h5>
                                            )
                                        }
                                    })}

                                    <div className="row">
                                        <div className="col">
                                            <Input value={agentInfo.value} onChange={agentInfo.onChange} />
                                        </div>
                                        <div>
                                            <Button
                                                text={'Add'}
                                                size="md"
                                                onClick={() => {
                                                    handleMultiSelectAgentInfo('add', agentInfo.value)
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>


                            </div>
                            <div className="row mt--2 mb-3 ml-0">
                                {
                                    selectedAgentInfo && selectedAgentInfo.map((el, index) => {
                                        return (
                                            <>
                                                <div className="mt-2">
                                                    <div className="ml-2 bg-primary text-white pl-2 pr-1  rounded">
                                                        {el}
                                                        <span className="ml-2"
                                                            onClick={() => {
                                                                handleMultiSelectAgentInfo('remove', el)
                                                            }}
                                                        >
                                                            <i className="bi bi-x-circle-fill  text-white"
                                                                style={{ fontSize: '13px' }}
                                                            ></i>
                                                        </span>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        {/* {Prospect info} */}
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col">
                                    <label className={'form-control-label'}>{'Prospect Information To Capture'}</label>

                                    {/* {checkbox} */}

                                    <div className="row">

                                        {prospectInfoType.map((el, index) => {
                                            return (
                                                <>
                                                    <div className="form-check mb-3 ml-3">
                                                        <input className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault3"
                                                            id="flexRadioDefault3"
                                                            onChange={(e) => {
                                                                setSelectedProspectInfo([])
                                                                setProspectType(el.value)
                                                                handleProspectCheckBoxData(el.id)
                                                            }}
                                                            checked={el.check}
                                                        />
                                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                            {el.value}
                                                        </label>
                                                    </div>
                                                </>
                                            )
                                        })
                                        }
                                    </div>

                                    {prospectInfoType.map((el, index) => {
                                        if (el.check === true) {
                                            return (

                                                <h5 className="mt--3 text-muted">
                                                    {el.value === 'Item' && el.check === true ? `Ex:First Name , E-Mail, Mobile Number, etc...` : 'Ex:Personal Information, Educational Details, etc...'}
                                                </h5>
                                            )
                                        }
                                    })}
                                    <div className="row">
                                        <div className="col">
                                            <Input value={prospectInfo.value} onChange={prospectInfo.onChange} />
                                        </div>
                                        <div>
                                            <Button
                                                text={'Add'}
                                                size="md"
                                                onClick={() => {
                                                    handleMultiSelectProspectInfo('add', prospectInfo.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="row mt--2 mb-3 ml-0">
                                {
                                    selectedProspectInfo && selectedProspectInfo.map((el, index) => {
                                        return (
                                            <>
                                                <div>
                                                    <div className="ml-2 mt-2 bg-primary text-white pl-2 pr-1  rounded">
                                                        {el}
                                                        <span className="ml-2"
                                                            onClick={() => {
                                                                handleMultiSelectProspectInfo('remove', el)
                                                            }}
                                                        >
                                                            <i className="bi bi-x-circle-fill  text-white"
                                                                style={{ fontSize: '13px' }}
                                                            ></i>
                                                        </span>
                                                    </div>

                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>

                    <div className="row">

                        {checkList.map((el, index) => {
                            return (
                                <>
                                    <div className="form-check mb-3 ml-3">
                                        <input className="form-check-input"
                                            type="radio"
                                            name="flexRadioDefault"
                                            id="flexRadioDefault1"
                                            value={'true'}
                                            onChange={(e) => {
                                                handleCheckBoxData(el.id)
                                                setCount(index)
                                                setCallType(el.type)
                                            }}
                                            checked={el.check}
                                        />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            {el.value}
                                        </label>
                                    </div>
                                </>
                            )
                        })
                        }
                    </div>

                    {handleCheckList(count)}


                    <div className="text-right">
                        <Button text={'Start'} onClick={() => {
                            console.log('rrrrrrrrrr')
                            submitApiHandler()

                        }} />
                    </div>
                </ div>
            </div >

            {/* <Modal isOpen={companyDetailsModal.visible} onClose={companyDetailsModal.hide} title={'Add Company Details'} size={'lg'}>
                <div className='m-3'>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"CompanyName"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'CompanyName'}
                                value={companyName.value}
                                onChange={companyName.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"CompanyAddress"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'CompanyAddress'}
                                value={companyAddress.value}
                                onChange={companyAddress.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"PhoneNumber"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'PhoneNumber'}
                                type={'number'}
                                value={PhoneNumber.value}
                                onChange={PhoneNumber.onChange}
                                maxLength={10}

                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"City"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'City'}
                                value={cites.value}
                                onChange={cites.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Country"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Country'}
                                value={countries.value}
                                onChange={countries.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"State"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'State'}
                                value={states.value}
                                onChange={states.onChange}
                            />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Industry"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Industry'}
                                value={industries.value}
                                onChange={industries.onChange}
                            />
                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-3 text-left'>
                            <InputHeading heading={"Employee Size"} />
                        </div>
                        <div className='col-7'>
                            <Input
                                placeholder={'Employee Size'}
                                value={employees.value}
                                onChange={employees.onChange}
                            />
                        </div>

                    </div>



                    <div className='row   justify-content-end'>

                        <Button text={'Submit'} onClick={() => {

                            handleRegisterCompany()


                        }} />

                    </div>
                </div>



            </Modal> */}
            {/* <Modal isOpen={designationModal.visible} onClose={designationModal.hide} title={'Add Designation'} >
                <div className='m-3'>
                    <div className='row'>
                        <div className='col-2 text-left'>
                            <InputHeading heading={"Designation"} />
                        </div>
                        <div className='col-8'>
                            <Input
                                placeholder={'Designation'}
                                value={designation.value}
                                onChange={designation.onChange}
                            />
                        </div>

                    </div>
                    <div className='row   justify-content-end'>

                        <Button text={'Submit'}
                            onClick={(el) => {

                                handleAddDesignation()
                            }} />

                    </div>

                </div>

            </Modal> */}


        </>

    );
}

export { AddData };



