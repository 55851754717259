import { InputHeading } from '@Components';
import moment, { isMoment, Moment } from 'moment';
import ReactDatetime from "react-datetime";
import { FormGroup } from 'reactstrap';
import { DateTimePickerProps } from './interfaces';


function DateTimePicker({ id, heading, placeholder, type = 'date', onChange, disableFuture = 'all', ...rest }: DateTimePickerProps) {

  const beforeCurrentDate = moment().add(1,'day');
  const AfterCurrentDate = moment().subtract(1, 'day')
  const disableDt = disableFuture === 'after'
    ? (current: any) => current.isAfter(AfterCurrentDate, 'day')
    : disableFuture === 'before'? (current: any) => current.isBefore(beforeCurrentDate, 'day'):(current: any) => current;

  return (
    <FormGroup>
      {heading && <InputHeading id={id} heading={heading} />}

      <ReactDatetime
        {...rest}
        inputProps={
          {
            placeholder: placeholder,
            onKeyDown: (e) => { e.preventDefault() },
            // readOnly: true,

          }
        }
        closeOnSelect={true}
        timeFormat={type !== 'date' && true}
        dateFormat={type === 'time' ? false : 'll'}
        onChange={
          (date: Moment | string) => {
            if (onChange)
              if (isMoment(date))
                onChange(type === 'time' ? date.format('LT') : type === 'both' ? date.format() : date.format('YYYY-MM-DD'))
              else
                onChange(date)
          }
        }
        isValidDate={disableDt}
      />
    </FormGroup >
  )
}

export { DateTimePicker };
export type { DateTimePickerProps };
