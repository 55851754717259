

import React, { useState } from 'react';
import { Button, Image, Modal, showToast } from '@Components';
import CsvDownloader from 'react-csv-downloader'
import { icons } from '@Assets';

interface UploadProps {
  onSelect?: (value) => void;
  isOpen?: boolean;
  size?: 'sm' | 'md' | 'lg';
  title?: string;
  onSubmitClick?: (data) => void
  isUploadModalOpen?: boolean;
  isDownloadTemplate?: boolean;
  onTemplateClick?: () => void;
  src?:any
}

function CsvFile({ onSelect, isOpen, size = 'sm', title, isUploadModalOpen = false, isDownloadTemplate = false, onTemplateClick ,src}: UploadProps) {
  const [isOpenModal, setIsOpenModal] = useState(isOpen);

  const columns = [{
    id: '1',
    displayName: 'code'
  },
  {
    id: '2',
    displayName: 'first_name'
  },
  {
    id: '3',
    displayName: 'last_name'
  },
  {
    id: '4',
    displayName: 'contact_number'
  },
  {
    id: '5',
    displayName: 'email'
  },
  {
    id: '6',
    displayName: 'age'
  },
  {
    id: '7',
    displayName: 'linkedIn_url'
  },
  {
    id: '8',
    displayName: 'company_name'
  },
  {
    id: '9',
    displayName: 'company_contact_number'
  },
  {
    id: '10',
    displayName: 'company_address'
  },
  {
    id: '11',
    displayName: 'company_city'
  },
  {
    id: '12',
    displayName: 'company_state'
  },
  {
    id: '13',
    displayName: 'company_country'
  },
  {
    id: '14',
    displayName: 'company_industry'
  },
  {
    id: '15',
    displayName: 'company_employee_size'
  },
  {
    id: '16',
    displayName: 'branch_code'
  },
  {
    id: '17',
    displayName: 'designation'
  },
  {
    id: '18',
    displayName: 'about_personal'
  },
  {
    id: '19',
    displayName: 'about_professsional'
  },
  ];

  const datas = [{

  }
  ];

  // function handleDownload(event: MouseEvent<HTMLButtonElement, MouseEvent>): void {
  //   throw new Error('Function not implemented.');
  // }

  return (
    <div>
      <span className="ni ni-folder-17 mt-1 mr-2 ni-lg pointer" onClick={() => setIsOpenModal(!isOpenModal)}></span>
      <Modal isOpen={isOpenModal} size={size} onClose={() => setIsOpenModal(!isOpenModal)} title={title}
      
      >
        <div className='text-center'>

      
          <Image src={icons.excel} width={'120px'}/>
          </div>
         
        <div className='text-right'>
        
        
          <CsvDownloader
            filename="Bulk Upload Clients Format"
            extension=".csv"
            columns={columns}
            datas={datas}
          // separator=";"
          // wrapColumnChar="'"

          >

            <Button
              className={'fa fa-download mt-1'}
              size={'sm'}
            >
            </Button>
          </CsvDownloader>
        </div>
      </Modal>
    </div>
  );
}

export { CsvFile };