import { Button, Card, CommonTable, NoDataFound, Spinner, StickyNote } from "@Components";
import { useState, useEffect } from "react";
import { fetchRedialDirectCall, getDirectCallDetails, getInstantCall, isOpenDirectCallModal, postInitiateInstantCallSuccess, setReferenceId, settingCallNotes, settingSelectedCallDetails } from "@Redux";
import { useDispatch, useSelector } from "react-redux";
import { useLoader, useNavigation } from "@Hooks";
import { ROUTES } from "@Routes";
import { RsButtonProps } from "@Components";
import { Badge, Modal, Progress, Button as RSButton } from 'reactstrap';
import { AddData } from "../AddData";
import { DirectCall } from "@Modules";
import Log from "@twilio/voice-sdk/es5/twilio/log";
import { convertToUpperCase } from "@Utils";
import axios from "axios";
import { SERVER } from "@Services";

function CallList() {

    const dispatch = useDispatch();
    const isLoading = useLoader(false)
    const { getInstantCallData, isOpenDirectCall, directCallData,isDirectCalls } = useSelector((state: any) => state.AppReducer);
    const { callNotes } = useSelector((state: any) => state.AuthReducer);
    const { goTo } = useNavigation();
    const [isOpenNewCall, setIsOpenNewCall] = useState<any>(false)
    const [instantCallData, setInstantCallData] = useState<any>([])

   
    useEffect(() => {
        
        getInstantCallApiHandler();
    }, [])





    useEffect(() => {
        if (isOpenDirectCall) {
            setIsOpenNewCall(false)
        }


    }, [isOpenDirectCall])

    useEffect(()=>{
        if(isDirectCalls){
            setIsOpenNewCall(false)

        }

    },[isDirectCalls])

    const getInstantCallApiHandler = () => {
        isLoading.show()
        const params = {}
        dispatch(
            getInstantCall({
                params,
                onSuccess: (response: any) => () => {
                    isLoading.hide()
                    setInstantCallData(response?.details)
                },
                onError: (error) => () => {
                    isLoading.hide()
                },
            })
        )
    }


    const normalizedEmployeesTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {

            return {
                'Agent': convertToUpperCase(el?.fromPerson),
                'Prospect': convertToUpperCase(el?.toPerson),
                'To Number': el?.toNumber,
                Target: convertToUpperCase(el?.agenda),
                'Key Metrics': el?.expectations && el?.expectations?.map((item: any) => {
                    return (
                        <>
                            <div>{convertToUpperCase(item)}</div>
                        </>
                    )
                }),
                "Conversion percentage": el.analytics_is_complete ?
                    <div>
                        <h5 className="">{`${el.conversion_percent}%`}</h5>
                        <Progress value={el.conversion_percent} max={100} />
                    </div>
                    : '-',
                '': el.analytics_is_complete ?
                    <div className="text-right mr-3">
                        <Button
                            text={'View Analytics'}
                            size="sm"
                            onClick={() => {
                                dispatch(settingSelectedCallDetails(el))
                                goTo(ROUTES.Dashboard.dashboard)
                                dispatch(setReferenceId(el?.id))
                            }}

                        />
                    </div> : el.is_call_deferred ?
                        <div className="row justify-content-end mr-3">
                            < RSButton onClick={() => {
                                // onInitiateCampaignCall(el)
                                redialDirectCall(el)
                            }} className="btn-icon  d-flex align-items-center justify-content-center custom-gradient border-0" size={'sm'} color="facebook" type="button" style={{ borderRadius: 25, width: 90, height: 25, backgroundColor: '#117c2b' }}>
                                <span className="btn-inner--icon mt--1">
                                    <i className="bi bi-telephone fa-sm" />
                                </span>
                                <span className="btn-inner--text fw-light">Redial</span>
                            </RSButton >
                        </div>
                        :
                        <span style={{
                            fontSize: '12px'
                        }}>
                            {"GENERATING ANALYTICS"}
                        </span>
                ,
            }
        }
        )
    }

   




    useEffect(() => {
        const interval = setInterval(() => {
            getInstantCallApiHandler2()
        }, 20000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    const getInstantCallApiHandler2 = () => {

        const params = {}
        dispatch(
            getInstantCall({
                params,
                onSuccess: (response: any) => () => {
                    setInstantCallData(response?.details)
                },
                onError: (error) => () => {
                },
            })
        )
    }


    const redialDirectCall = (data) => {
        const params = {
            id: data?.id
        }

        dispatch(fetchRedialDirectCall({
            params,
            onSuccess: (response: any) => () => {
                dispatch(getDirectCallDetails({
                    to_person: data?.toPerson,
                    to_number: data?.toNumber

                }))
                dispatch(postInitiateInstantCallSuccess(response))
                dispatch(isOpenDirectCallModal(true))

            },
            onError: (error) => () => {
            },
        }))
    }


    return (
        <>
            <div className='m-3'>
                <Card >
                    <div className='d-flex justify-content-between mb-2'>

                        <div>
                            <h3>{'History'}</h3>
                        </div>
                        <Button
                            text={'Start call'}
                            size={'sm'}
                            onClick={
                                () => {
                                    setIsOpenNewCall(true)
                                    
                                    // goTo(ROUTES.Dashboard["add-data"])
                                }
                            } />
                    </div>


                    {isLoading.loader &&
                        <div className='d-flex justify-content-center'>
                            <Spinner />
                        </div>
                    }

                    {!isLoading.loader && (
                        <div className='overflow-auto scroll-hide'
                            style={{
                                marginLeft: "-23px",
                                marginRight: "-23px"
                            }}
                        >

                            {instantCallData && instantCallData?.length > 0 ?
                                <CommonTable

                                    isPagination
                                    tableDataSet={instantCallData}
                                    displayDataSet={normalizedEmployeesTableData(instantCallData)}
                                    

                                />
                                :
                                <NoDataFound />
                            }

                        </div>
                    )}

                </Card>

            </div>
            < Modal
                fade={false}
                size='lg'
                isOpen={isOpenNewCall}
                className="modal-dialog-centered"
            >
                <>


                    <div className="">
                        <div className="text-right pr-4 pt-3">
                            <button
                                aria-label={"Close"}
                                className={"close"}
                                data-dismiss={"modal"}
                                type={"button"}
                                onClick={() => {
                                    setIsOpenNewCall(false)
                                }}
                            >
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <AddData />
                    </div>

                </>
            </Modal >




            {isOpenDirectCall ? < Modal
                fade={false}
                size='lg'
                isOpen={isOpenDirectCall}
                className=""
            >
                <>

                        <div className="">
                            <DirectCall
                                onEndCall={() => {
                                    // setIsOpenModal(false)
                                    dispatch(isOpenDirectCallModal(false))
                                    // reset()
                                }}
                            />
                        </div>
                       

                </>
            </Modal >
                :
                <></>
            }


        </>
    );
}
export { CallList }