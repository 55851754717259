import { Card, CommonTable, NoDataFound, FormTypography } from '@Components';
import React from 'react'
import { useSelector } from 'react-redux';
import { Progress } from 'reactstrap';

function CallDetails() {

    const { selectedCallDetails } = useSelector((state: any) => state.AuthReducer);
    const { analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);


    console.log("selectedCallDetails", selectedCallDetails);


    const normalizedCallDetailsTableData = (data: any) => {
        return data && data.length > 0 && data?.map((el: any) => {

            return {
                'Agent': el.fromPerson,
                'Prospect': el.toPerson,
                'Contact Number': el.toNumber,
                'Agenda': el.agenda,
                'Expectations': el?.expectations?.length > 1 ? el?.expectations?.join('\n') : el?.expectations,
                'Conversion percentage': <div>
                    <h5 className="">{`${el.conversion_percent}%`}</h5>
                    <Progress value={el.conversion_percent} max={100} />
                </div>,
                ...(Object.keys(el.agent_information_to_share).length > 0 && { 'Agent information Item': el?.agent_information_to_share?.information_keys?.length > 1 ? el?.agent_information_to_share?.information_keys?.join('\n') : el?.agent_information_to_share?.information_keys }),
                ...(Object.keys(el.prospect_information_to_share).length > 0 && { 'Prospect information Item': el?.prospect_information_to_share?.information_keys?.length > 1 ? el?.prospect_information_to_share?.information_keys?.join('\n') : el?.prospect_information_to_share?.information_keys }),
                ...(Object.keys(el.agent_information_to_group).length > 0 && { 'Agent information Group': el?.agent_information_to_group?.information_keys?.length > 1 ? el?.agent_information_to_group?.information_keys?.join('\n') : el?.agent_information_to_group?.information_keys }),
                ...(Object.keys(el.prospect_information_to_group).length > 0 && { 'Prospect information Group': el?.prospect_information_to_group?.information_keys?.length > 1 ? el?.prospect_information_to_group?.information_keys?.join('\n') : el?.agent_information_to_group?.information_keys })


            }
        }
        )
    }

    return (
        <div className='mt--5 col-sm-12'>
            {/* {analyticsOverAllPercentage && Object.keys(analyticsOverAllPercentage).length > 0 && (
                <Card>
                    <div className='overflow-auto scroll-hide'
                        style={{
                            marginLeft: "-23px",
                            marginRight: "-23px"
                        }}
                    >

                        {selectedCallDetails && selectedCallDetails?.length > 0 ?
                            <CommonTable

                                isPagination
                                tableDataSet={selectedCallDetails}
                                displayDataSet={normalizedCallDetailsTableData(selectedCallDetails)}

                            />
                            :
                            <NoDataFound />
                        }

                    </div>
                </Card>
            )} */}

            <Card>
                <div className='row'>
                    <div className='col-sm-6'>
                        <FormTypography title={'Agent'} subTitle={selectedCallDetails && selectedCallDetails?.fromPerson} />
                    </div>
                    <div className='col-sm-6'>
                        <FormTypography title={'Prospect'} subTitle={selectedCallDetails && selectedCallDetails?.toPerson} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-6'>
                        <FormTypography title={'Contact Number'} subTitle={selectedCallDetails && selectedCallDetails?.toNumber} />
                    </div>
                    <div className='col-sm-6'>
                        <FormTypography title={'Target'} subTitle={selectedCallDetails && selectedCallDetails?.agenda} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-sm-6'>
                        <FormTypography title={'Expectations'} subTitle={selectedCallDetails && selectedCallDetails?.expectations?.length > 1 ? selectedCallDetails?.expectations?.join('\n') : selectedCallDetails?.expectations} />
                    </div>
                    {/* <div className='col-sm-6'>
                        <FormTypography title={'Agenda'} subTitle={selectedCallDetails && selectedCallDetails?.agenda} />
                    </div> */}
                </div>
            </Card>
        </div>
    )
}

export { CallDetails }