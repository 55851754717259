import { DEFAULT_LANGUAGE } from '@Utils';
import { AuthSliceStateProp } from '../../interface';
import * as ActionTypes from '../ActionType'




const initialState: AuthSliceStateProp = {
    language: { id: '1', text: 'English', value: 'en' },
    validateUserBusinessResponse: undefined,
    userSelectedLanguage: undefined,
    userDetails: undefined,
    dashboardDetails: undefined,
    registeredMobileNumber: undefined,
    employeesList: [],
    employeeDetails: undefined,
    selectedEmployeeItem: undefined,
    clientsList: [],
    campaignsList: [],
    productsList: [],
    currentCampaignDetails: undefined,
    isCampaignAdd: false,
    agentTrait: undefined,
    chatConversation: undefined,
    prospectSharedDetails: undefined,
    agentSharedInfoGeneric: undefined,
    prospectSharedInfoItem: undefined,
    prospectSharedInfoGroup: undefined,
    agentSharedInfoItem: undefined,
    agentSharedInfoGroup: undefined,
    callNotes: undefined,
    selectedCallDetails: undefined,
    registerCompany: undefined,
    branchesData: undefined,
    companyBranchDetails: undefined,
    designations: undefined,
    postDesignation: undefined,
    bulkClientsUpload: undefined,
    clientsDataList:undefined,
}


const AuthReducer = (state: AuthSliceStateProp = initialState, action: any) => {
    switch (action.type) {

        case ActionTypes.RESET_AUTH:
            state = initialState;
            break;


        case ActionTypes.VALIDATE_USER_BUSINESS:
            state = { ...state, validateUserBusinessResponse: undefined };
            break;
        case ActionTypes.VALIDATE_USER_BUSINESS_SUCCESS:
            state = {
                ...state,
                validateUserBusinessResponse: action.payload,
                userSelectedLanguage: action.payload.ln,
            };
            break;
        case ActionTypes.VALIDATE_USER_BUSINESS_FAILURE:
            state = { ...state, validateUserBusinessResponse: action.payload };
            break;



        /**
* otp login
*/

        case ActionTypes.OTP_LOGIN:
            state = { ...state };
            break;
        case ActionTypes.OTP_LOGIN_SUCCESS:
            state = { ...state, userDetails: action.payload.details };
            break;
        case ActionTypes.OTP_LOGIN_FAILURE:
            state = { ...state, userDetails: action.payload };
            break;




        case ActionTypes.SET_REGISTER_MOBILE_NUMBER:
            state = { ...state, registeredMobileNumber: action.payload };
            break;


        /**
* Dashboard
*/
        case ActionTypes.GET_DASHBOARD:
            state = { ...state, dashboardDetails: undefined };
            break;
        case ActionTypes.GET_DASHBOARD_SUCCESS:
            state = { ...state, dashboardDetails: action.payload.details };
            break;
        case ActionTypes.GET_DASHBOARD_FAILURE:
            state = { ...state, dashboardDetails: action.payload };
            break;

        /**
         * Add employee
         */

        case ActionTypes.ADD_EMPLOYEE:
            state = {
                ...state,
            };
            break;
        case ActionTypes.ADD_EMPLOYEE_SUCCESS:

            state = {
                ...state,
            };
            break;
        case ActionTypes.ADD_EMPLOYEE_FAILURE:
            state = { ...state };
            break;

        /**
         * Add employee photo
         */

        case ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO:
            state = {
                ...state,
            };
            break;
        case ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.UPDATE_EMPLOYEE_PROFILE_PHOTO_FAILURE:
            state = { ...state };
            break;

        /**
         * Get employees
         */


        case ActionTypes.GET_EMPLOYEES:
            state = {
                ...state, employeesList: undefined
            };

            break;
        case ActionTypes.GET_EMPLOYEES_SUCCESS:
            state = {
                ...state,
                employeesList: action.payload.details.data,
            };
            break;
        case ActionTypes.GET_EMPLOYEES_FAILURE:
            state = { ...state, employeesList: undefined };
            break;


        //get employee details
        case ActionTypes.FETCH_EMPLOYEE_DETAILS:
            state = { ...state };
            break;
        case ActionTypes.FETCH_EMPLOYEE_DETAILS_SUCCESS:
            state = {
                ...state,
                employeeDetails: action.payload,
            };
            break;
        case ActionTypes.FETCH_EMPLOYEE_DETAILS_FAILURE:
            state = {
                ...state,
            };
            break;

        /**
 * Selected employee item
 */

        case ActionTypes.SETTING_SELECTED_EMPLOYEE_ITEM:
            state = { ...state, selectedEmployeeItem: action.payload };
            break;

        //Add client

        case ActionTypes.ADD_CLIENT:
            state = { ...state };
            break;
        case ActionTypes.ADD_CLIENT_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.ADD_CLIENT_FAILURE:
            state = {
                ...state,
            };
            break;

        //Get clients

        case ActionTypes.GET_CLIENTS:
            state = { ...state };
            break;
        case ActionTypes.GET_CLIENTS_SUCCESS:
            state = {
                ...state,
                clientsList: action.payload
            };
            break;
        case ActionTypes.GET_CLIENTS_FAILURE:
            state = {
                ...state,
            };
            break;


        //Add campaign

        case ActionTypes.ADD_CAMPAIGN:
            state = { ...state };
            break;
        case ActionTypes.ADD_CAMPAIGN_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.ADD_CAMPAIGN_FAILURE:
            state = {
                ...state,
            };
            break;

        //Get clients

        case ActionTypes.GET_CAMPAIGNS:
            state = { ...state, campaignsList: undefined };
            break;
        case ActionTypes.GET_CAMPAIGNS_SUCCESS:
            console.log("0000000003333333333", action.payload)
            state = {
                ...state,
                campaignsList: action.payload
            };
            break;
        case ActionTypes.GET_CAMPAIGNS_FAILURE:
            state = {
                ...state,
            };
            break;

        //get products

        case ActionTypes.GET_PRODUCTS:
            state = { ...state };
            break;
        case ActionTypes.GET_PRODUCTS_SUCCESS:
            state = {
                ...state,
                productsList: action.payload
            };
            break;
        case ActionTypes.GET_PRODUCTS_FAILURE:
            state = {
                ...state,
            };
            break;

        //bulk upload client info

        case ActionTypes.BULK_UPLOAD_CLIENT_INFO:
            state = { ...state };
            break;
        case ActionTypes.BULK_UPLOAD_CLIENT_INFO_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.BULK_UPLOAD_CLIENT_INFO_FAILURE:
            state = {
                ...state,
            };
            break;

        //get client template

        case ActionTypes.GET_CLIENT_TEMPLATE:
            state = { ...state };
            break;
        case ActionTypes.GET_CLIENT_TEMPLATE_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.GET_CLIENT_TEMPLATE_FAILURE:
            state = {
                ...state,
            };
            break;

        //setting current campaign details

        case ActionTypes.SETTING_CURRENT_CAMPAIGN_DETAILS:
            console.log("000000000000000000000000000000222>>", action.payload)
            state = { ...state, currentCampaignDetails: action.payload };
            break;

        //Initiate campaign call

        case ActionTypes.INITIATE_CAMPAIGN_CALL:
            state = { ...state };
            break;
        case ActionTypes.INITIATE_CAMPAIGN_CALL_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.INITIATE_CAMPAIGN_CALL_FAILURE:
            state = {
                ...state,
            };
            break;

        //Mark end call

        case ActionTypes.MARK_END_CALL:
            state = { ...state };
            break;
        case ActionTypes.MARK_END_CALL_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.MARK_END_CALL_FAILURE:
            state = {
                ...state,
            };
            break;

        //Is campaign added

        case ActionTypes.IS_CAMPAIGN_ADDED:
            console.log(" action.payload", action.payload);

            state = { ...state, isCampaignAdd: action.payload };
            break;



        //Mark end call

        case ActionTypes.FETCH_AGENT_TRAIT:
            state = { ...state, agentTrait: undefined };
            break;
        case ActionTypes.FETCH_AGENT_TRAIT_SUCCESS:
            state = {
                ...state, agentTrait: action.payload
            };
            break;
        case ActionTypes.FETCH_AGENT_TRAIT_FAILURE:
            state = {
                ...state, agentTrait: undefined
            };
            break;

        //get chat conversation

        case ActionTypes.GET_CHAT_CONVERSATION:
            state = { ...state, chatConversation: undefined };
            break;
        case ActionTypes.GET_CHAT_CONVERSATION_SUCCESS:

            console.log("reducerr", action.payload.details);

            state = {
                ...state, chatConversation: action.payload.details
            };
            break;
        case ActionTypes.GET_CHAT_CONVERSATION_FAILURE:
            state = {
                ...state, chatConversation: undefined
            };
            break;


        //Get prospect shared details

        case ActionTypes.GET_PROSPECT_SHARED_DETAILS:
            state = { ...state, prospectSharedDetails: undefined };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_DETAILS_SUCCESS:

            state = {
                ...state, prospectSharedDetails: action.payload.details
            };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_DETAILS_FAILURE:

            state = {
                ...state, prospectSharedDetails: undefined
            };
            break;





        //Get agent shared info generic

        case ActionTypes.GET_AGENT_SHARED_INFO_GENERIC:
            state = { ...state, agentSharedInfoGeneric: undefined };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_GENERIC_SUCCESS:

            state = {
                ...state, agentSharedInfoGeneric: action.payload.details
            };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_GENERIC_FAILURE:

            state = {
                ...state, agentSharedInfoGeneric: undefined
            };
            break;

        //Get prospect shared info group

        case ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP:
            state = { ...state, prospectSharedInfoGroup: undefined };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP_SUCCESS:

            state = {
                ...state, prospectSharedInfoGroup: action.payload.details
            };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_INFO_GROUP_FAILURE:

            state = {
                ...state, prospectSharedInfoGroup: undefined
            };
            break;

        //Get prospect shared info item

        case ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM:
            state = { ...state, prospectSharedInfoItem: undefined };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM_SUCCESS:

            state = {
                ...state, prospectSharedInfoItem: action.payload.details
            };
            break;
        case ActionTypes.GET_PROSPECT_SHARED_INFO_ITEM_FAILURE:

            state = {
                ...state, prospectSharedInfoItem: undefined
            };
            break;


        //Get agent shared info group

        case ActionTypes.GET_AGENT_SHARED_INFO_GROUP:
            state = { ...state, agentSharedInfoGroup: undefined };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_GROUP_SUCCESS:

            state = {
                ...state, agentSharedInfoGroup: action.payload.details
            };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_GROUP_FAILURE:

            state = {
                ...state, agentSharedInfoGroup: undefined
            };
            break;


        //Get agent shared info item

        case ActionTypes.GET_AGENT_SHARED_INFO_ITEM:
            state = { ...state, agentSharedInfoItem: undefined };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_ITEM_SUCCESS:

            state = {
                ...state, agentSharedInfoItem: action.payload.details
            };
            break;
        case ActionTypes.GET_AGENT_SHARED_INFO_ITEM_FAILURE:

            state = {
                ...state, agentSharedInfoItem: undefined
            };
            break;


        //Get registerCompany

        case ActionTypes.REGISTER_COMPANY:
            state = { ...state, registerCompany: undefined };
            break;
        case ActionTypes.REGISTER_COMPANY_SUCCESS:

            state = {
                ...state, registerCompany: action.payload.details
            };
            break;
        case ActionTypes.REGISTER_COMPANY_FAILURE:

            state = {
                ...state, registerCompany: undefined
            };
            break;


        //Get all branches

        case ActionTypes.GET_ALL_BRANCHES:
            state = { ...state, branchesData: undefined };
            break;
        case ActionTypes.GET_ALL_BRANCHES_SUCCESS:

            state = {
                ...state, branchesData: action.payload.details
            };
            break;
        case ActionTypes.GET_ALL_BRANCHES_FAILURE:

            state = {
                ...state, branchesData: undefined
            };
            break;

        //get company branch details

        case ActionTypes.GET_COMPANY_BRANCH_DETAILS:
            state = { ...state, companyBranchDetails: undefined };
            break;
        case ActionTypes.GET_COMPANY_BRANCH_DETAILS_SUCCESS:

            state = {
                ...state, companyBranchDetails: action.payload.details
            };
            break;
        case ActionTypes.GET_COMPANY_BRANCH_DETAILS_FAILURE:

            state = {
                ...state, companyBranchDetails: undefined
            };
            break;



        //get DESIGNATION

        case ActionTypes.GET_DESIGNATIONS:
            state = { ...state, designations: undefined };
            break;
        case ActionTypes.GET_DESIGNATIONS_SUCCESS:

            state = {
                ...state, designations: action.payload.details
            };
            break;
        case ActionTypes.GET_DESIGNATIONS_FAILURE:

            state = {
                ...state, designations: undefined
            };
            break;


        //get ADD DESIGNATION

        case ActionTypes.ADD_DESIGNATIONS:
            state = { ...state, postDesignation: undefined };
            break;
        case ActionTypes.ADD_DESIGNATIONS_SUCCESS:

            state = {
                ...state, postDesignation: action.payload.details
            };
            break;
        case ActionTypes.ADD_DESIGNATIONS_FAILURE:

            state = {
                ...state, postDesignation: undefined
            };
            break;


        //setting call notes

        case ActionTypes.CALL_NOTES:
            console.log("action<<<<<<", action.payload);

            state = { ...state, callNotes: action.payload };
            break;

        //setting selected call details

        case ActionTypes.SELECTED_CALL_DETAILS:
            console.log("<<<<<<<<reducer", action.payload);

            state = { ...state, selectedCallDetails: action.payload };
            break;

        //bulk Upload Clients

        case ActionTypes.BULK_UPLOAD_CLIENTS:
            state = { ...state };
            break;
        case ActionTypes.BULK_UPLOAD_CLIENTS_SUCCESS:
            state = {
                ...state,
            };
            break;
        case ActionTypes.BULK_UPLOAD_CLIENTS_FAILURE:
            state = {
                ...state,
            };
            break;



             //clientsData

        case ActionTypes.CLIENTS_DATA:
            state = { ...state,clientsDataList: action.payload };
            break;

        default:
            state = state;
            break;
    }
    return state;
};
export { AuthReducer };