export const RESET_APP = 'RESET_APP'

export const USER_LOGIN_DETAILS = 'USER_LOGIN_DETAILS';
export const RESTORE_APP = 'RESTORE_APP';
export const USER_LOGOUT = 'USER_LOGOUT';
export const HANDLING_API = 'HANDLING_API';
export const FCM_TOKEN = 'FCM_TOKEN';

export const IS_LOADING = 'IS_LOADING'


export const GET_VIEW = 'GET_VIEW';
export const GET_VIEW_SUCCESS = 'GET_VIEW_SUCCESS';
export const GET_VIEW_FAILURE = 'GET_VIEW_FAILURE';


export const GET_TRAIT = 'GET_TRAIT';
export const GET_TRAIT_SUCCESS = 'GET_TRAIT_SUCCESS';
export const GET_TRAIT_FAILURE = 'GET_TRAIT_FAILURE';


export const GET_NEXTSTEP = 'GET_NEXTSTEP';
export const GET_NEXTSTEP_SUCCESS = 'GET_NEXTSTEP_SUCCESS';
export const GET_NEXTSTEP_FAILURE = 'GET_NEXTSTEP_FAILURE';

export const GET_BETTERMENT_ANALYTICS = 'GET_BETTERMENT_ANALYTICS';
export const GET_BETTERMENT_ANALYTICS_SUCCESS = 'GET_BETTERMENT_ANALYTICS_SUCCESS';
export const GET_BETTERMENT_ANALYTICS_FAILURE = 'GET_BETTERMENT_ANALYTICS_FAILURE';



export const POST_DATA = 'POST_DATA';
export const POST_DATA_SUCCESS = 'POST_DATA_SUCCESS';
export const POST_DATA_FAILURE = 'POST_DATA_FAILURE';

// add designation
export const POST_DESIGNATION = 'POST_DESIGNATION';
export const POST_DESIGNATION_SUCCESS = 'POST_DESIGNATION_SUCCESS';
export const POST_DESIGNATION_FAILURE = 'POST_DESIGNATION_FAILURE';

//get designation
export const FETCH_DESIGNATIONS = 'FETCH_DESIGNATIONS';
export const FETCH_DESIGNATIONS_SUCCESS = 'FETCH_DESIGNATIONS_SUCCESS';
export const FETCH_DESIGNATIONS_FAILURE = 'FETCH_DESIGNATIONS_FAILURE';

// add department
export const POST_DEPARTMENT = 'POST_DEPARTMENT';
export const POST_DEPARTMENT_SUCCESS = 'POST_DEPARTMENT_SUCCESS';
export const POST_DEPARTMENT_FAILURE = 'POST_DEPARTMENT_FAILURE';

//get department
export const FETCH_DEPARTMENTS = 'FETCH_DEPARTMENTS';
export const FETCH_DEPARTMENTS_SUCCESS = 'FETCH_DEPARTMENTS_SUCCESS';
export const FETCH_DEPARTMENTS_FAILURE = 'FETCH_DEPARTMENTS_FAILURE';

//get expectation
export const FETCH_EXPECTATION = 'FETCH_EXPECTATION';
export const FETCH_EXPECTATION_SUCCESS = 'FETCH_EXPECTATION_SUCCESS';
export const FETCH_EXPECTATION_FAILURE = 'FETCH_EXPECTATION_FAILURE';


export const REFERENCE_ID = 'REFERENCE_ID'

//get twilio token
export const FETCH_TWILIO_TOKEN = 'FETCH_TWILIO_TOKEN';
export const FETCH_TWILIO_TOKEN_SUCCESS = 'FETCH_TWILIO_TOKEN_SUCCESS';
export const FETCH_TWILIO_TOKEN_FAILURE = 'FETCH_TWILIO_TOKEN_FAILURE';


//post initiate instant call
export const POST_INITIATE_INSTANT_CALL = 'POST_INITIATE_INSTANT_CALL';
export const POST_INITIATE_INSTANT_CALL_SUCCESS = 'POST_INITIATE_INSTANT_CALL_SUCCESS';
export const POST_INITIATE_INSTANT_CALL_FAILURE = 'POST_INITIATE_INSTANT_CALL_FAILURE';



export const GET_DIRECT_CALL_DATA = 'GET_DIRECT_CALL_DATA'

//get instant call

export const GET_INSTANT_CALL = 'GET_INSTANT_CALL';
export const GET_INSTANT_CALL_SUCCESS = 'GET_INSTANT_CALL_SUCCESS';
export const GET_INSTANT_CALL_FAILURE = 'GET_INSTANT_CALL_FAILURE';




export const IS_OPEN_DIRECT_CALL_MODAL = 'IS_OPEN_DIRECT_CALL_MODAL'
export const IS_DIRECT_CALL_MODAL='IS_DIRECT_CALL_MODAL'

//Fetch Overall Percent

export const FETCH_OVERALL_PERCENT = 'FETCH_OVERALL_PERCENT';
export const FETCH_OVERALL_PERCENT_SUCCESS = 'FETCH_OVERALL_PERCENT_SUCCESS';
export const FETCH_OVERALL_PERCENT_FAILURE = 'FETCH_OVERALL_PERCENT_FAILURE';




//test notification

export const FETCH_TEST_NOTIFICATION = 'FETCH_TEST_NOTIFICATION';
export const FETCH_TEST_NOTIFICATION_SUCCESS = 'FETCH_TEST_NOTIFICATION_SUCCESS';
export const FETCH_TEST_NOTIFICATION_FAILURE = 'FETCH_TEST_NOTIFICATION_FAILURE';


//redial direct call

export const FETCH_REDIAL_DIRECT_CALL = 'FETCH_REDIAL_DIRECT_CALL';
export const FETCH_REDIAL_DIRECT_CALL_SUCCESS = 'FETCH_REDIAL_DIRECT_CALL_SUCCESS';
export const FETCH_REDIAL_DIRECT_CALL_FAILURE = 'FETCH_REDIAL_DIRECT_CALL_FAILURE';


//Get next step personalized 

export const FETCH_NEXT_STEP_PERSONALIZED = 'FETCH_NEXT_STEP_PERSONALIZED';
export const FETCH_NEXT_STEP_PERSONALIZED_SUCCESS = 'FETCH_NEXT_STEP_PERSONALIZED_SUCCESS';
export const FETCH_NEXT_STEP_PERSONALIZED_FAILURE = 'FETCH_NEXT_STEP_PERSONALIZED_FAILURE';

//Get persons list

export const GET_PERSONS_LIST = 'GET_PERSONS_LIST';
export const GET_PERSONS_LIST_SUCCESS = 'GET_PERSONS_LIST_SUCCESS';
export const GET_PERSONS_LIST_FAILURE = 'GET_PERSONS_LIST_FAILURE';


export const ADD_PRODUCT='ADD_PRODUCT'
export const ADD_PRODUCT_SUCCESS='ADD_PRODUCT_SUCCESS'
export const ADD_PRODUCT_FAILURE='ADD_PRODUCT_FAILURE'



export const  REDIAL_CAMPAIGN_CALL ='REDIAL_CAMPAIGN_CALL'
export const  REDIAL_CAMPAIGN_CALL_SUCCESS ='REDIAL_CAMPAIGN_CALL_SUCCESS'
export const  REDIAL_CAMPAIGN_CALL_FAILURE ='REDIAL_CAMPAIGN_CALL_FAILURE'