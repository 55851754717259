import React from "react"
import { Card, NoDataFound, ProgressCircleChart, } from '@Components';
import { useSelector } from "react-redux";
import { OverallProgressCard, BettermentAnalyticsCard } from "@Modules";


function BettermentAnalytics() {
    const { bettermentDetails, analyticsOverAllPercentage } = useSelector((state: any) => state.AppReducer);
    const { communication, conversionPercent } = bettermentDetails || {}

    // console.log("bettermentDetails-------->",bettermentDetails)



    return (
        <>
            {communication && communication.length > 0 ? (

                <div className="row ">
                    <div className='col-xl-12 col-md-12 '>
                        <div className="row ">
                            {
                                communication && communication.length > 0 && communication.map((item) => {
                                    const { reason, sentences } = item
                                    return (
                                        <div className='col-xl-12 col-md-12 mb--3 '>
                                            <BettermentAnalyticsCard title={reason} subTitle={reason} sentence={sentences} />
                                        </div>
                                    );
                                })

                            }
                        </div>
                    </div>

                </div>
            ) : <NoDataFound />}
        </>

    )
}
export { BettermentAnalytics }