/**
 * sample api
 */

const AUTHENTICATION = '/authentication/'
const CHAT = '/callanalytics/'
const EMPLOYEE = '/employee/'
const AUTH = '/authentication/';
const COMPANY = '/company/'
const STUDENT = '/student/'
const CALLANALYTICS = '/callanalytics/'






export const VALIDATE_USER_BUSINESS = AUTH + 'validateUserBusiness';
export const OTP_LOGIN = AUTH + 'otpLogin';
export const URL_GET_DASHBOARD = AUTH + 'dashboard';


// Get Extract from whatsapp chat

export const FETCH_VIEW = CHAT + 'getPView'
export const FETCH_TRAIT = CHAT + 'getPTrait'
export const FETCH_NEXTSTEP = CHAT + 'getNextStep'
export const FETCH_BETTERMENT_ANALYTICS = CHAT + 'getBettermentAnalytics'
export const FETCH_EXPECTATION = CHAT + 'getExpectationExtraction'
export const FETCH_OVERALL_PERCENT = CHAT + 'getOverAllPercent'
export const FETCH_NEXT_STEP_PERSONALIZED_URL = CHAT + 'getReengagementAndOffer'

export const POST_DATA = CHAT + 'addData'



export const POST_DESIGNATION = EMPLOYEE + 'addDesignation'

export const FETCH_DESIGNATIONS = EMPLOYEE + 'getDesignations'

export const POST_DEPARTMENT = COMPANY + 'addDepartment'

export const FETCH_DEPARTMENTS = COMPANY + 'getDepartments'

//Add employee
export const ADD_EMPLOYEE = EMPLOYEE + 'addEmployee';

//Add employee photo
export const UPDATE_EMPLOYEE_PROFILE_PHOTO = EMPLOYEE + 'updateEmployeeProfilePhoto';

//Get employees
export const GET_EMPLOYEES = EMPLOYEE + 'getEmployees';

//get employee details
export const FETCH_EMPLOYEE_DETAILS_URL = EMPLOYEE + 'getEmployeeDetails';

//Add client

export const ADD_CLIENT_URL = STUDENT + 'addClient';

//Get clients

export const GET_CLIENTS_URL = STUDENT + 'getClients';

//Add Campaigns

export const ADD_CAMPAIGN_URL = STUDENT + 'addCampaign'

//Get campaigns

export const GET_CAMPAIGNS_URL = STUDENT + 'getCampaign'


// callanalytics/getTwilioToken


export const FETCH_TWILIO_TOKEN = CALLANALYTICS + 'getTwilioToken'

//get products

export const GET_PRODUCTS_URL = STUDENT + 'getProducts'

//bulk upload client info

export const BULK_UPLOAD_CLIENT_INFO_URL = STUDENT + 'bulkUploadClientInfo'

//Get client template

export const GET_CLIENT_TEMPLATE_URL = STUDENT + 'getClientTemp'


export const POST_INITIATE_INSTANT_CALL = CALLANALYTICS + 'initiateInstantCall'

// Get Instant Calls

export const GET_INSTANT_CALLS = CALLANALYTICS + 'getInstantCalls'


// notification

export const TEST_NOTIFICATION = STUDENT + 'testNotification'
//Mark end Calls

export const MARK_END_CALL_URL = CALLANALYTICS + 'markEndCall'



//redial direct call

export const FETCH_REDIAL_DIRECT_CALL = CALLANALYTICS + 'redialDirectCall'


export const INITIATE_CAMPAIGN_CALL_URL = CALLANALYTICS + 'initiateCampaignCall'

export const GET_PERSONS_LIST_URL = CHAT + 'getPersonsList'


export const GET_AGENT_TRAIT = CHAT + 'getAgentTrait'

export const GET_CHAT_CONVERSATION_URL = CHAT + 'getChatConversation'

export const GET_PROSPECT_SHARED_DETAILS_URL = CHAT + 'getProspectGroupGeneric'


export const GET_AGENT_SHARED_INFORMATION_GENERIC_URL = CHAT + 'getAgentGroupGeneric'

export const GET_PROSPECT_INFORMATION_GROUP_URL = CHAT + 'getProspectInformationGroup'

export const GET_PROSPECT_INFORMATION_ITEM_URL = CHAT + 'getProspectInformationItem'

export const GET_AGENT_INFORMATION_GROUP_URL = CHAT + 'getAgentInformationGroup'

export const GET_AGENT_INFORMATION_ITEM_URL = CHAT + 'getAgentInformationItem'

//I DO IT

export const REGISTER_COMPANY= AUTHENTICATION +'registerCompanyV1'
export const GET_ALL_BRANCHES= COMPANY +'getAllBranchesV1'
export const GET_COMPANY_BRANCH_DETAILS= COMPANY +'getCompanyBranchDetails'
export const GET_DESIGNATIONS= EMPLOYEE +'getDesignationsV1'
export const ADD_DESIGNATIONS= EMPLOYEE +'addDesignationV1'
export const ADD_PRODUCT =STUDENT+'addProduct'


//bulk upload client info

export const BULK_UPLOAD_CLIENTS_URL = STUDENT + 'bulkUploadClients'


export const  REDIAL_CAMPAIGN_CALLS = CALLANALYTICS +'redialCampaignCall'








